import metaGraphCurrencies from '@/static/metaGraphCurrencies'
import { metaGraphTimezones } from '@/static/metaGraphTimezones'
import api from '@/utils/api'
import { Box, Button, Select, TextInput } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import React, { useState } from 'react'

export default function MetaAdsCreationGuide({
    inviteID,
    close,
    refreshAssets
}: {
    inviteID: string,
    close: VoidFunction,
    refreshAssets: () => Promise<void>
}) {
    const [name, setName] = useState("")
    const [timezone, setTimezone] = useState<string | undefined | null>("")
    const [currency, setCurrency] = useState<string | undefined | null>("")
    const [loading, setLoading] = useState(false)

    return (
        <Box>
            <Box className='flex fdc' style={{ gap: 5 }}>
                <TextInput label="Account name" placeholder='My Ad Account' value={name} onChange={(e) => setName(e.target.value)} />
                <Select placeholder='Europe/Amsterdam' searchable label="Timezone" value={timezone} onChange={setTimezone} data={metaGraphTimezones.map((tz) => ({ label: `${tz.label.split("_").map((s) => `${s[0]}${s.substring(1, s.length).toLowerCase()}`).join(" ")}`, value: tz.value.toString() }))} />
                <Select searchable placeholder='EUR' label="Currency" data={metaGraphCurrencies} value={currency} onChange={setCurrency} />
            </Box>
            <Box className='flex' style={{ gap: 5 }} mt={10}>
                <Button fullWidth disabled={!name || !timezone || !currency} loading={loading} onClick={() => {
                    setLoading(true)
                    api.post(`/accounts/client/meta/ads/accounts/create`, {
                        name,
                        timezone: parseInt(timezone as string),
                        currency,
                        inviteID
                    }).then(async (res) => {
                        if (res.error) {
                            setLoading(false)
                            notifications.show({
                                color: "red",
                                title: "Error",
                                message: res.msg
                            })
                            return
                        }
                        await refreshAssets()
                        notifications.show({
                            title: "Ad account created",
                            message: "You can find your new ad account in the dropdown",
                            color: "green"
                        })
                        setLoading(false)
                        close()
                    })
                }}>Create Ad Account</Button>
                <Button fullWidth variant='subtle' disabled={loading} onClick={close}>Close</Button>
            </Box>
        </Box>
    )
}
