export const regions = [
    {
        "label": "ANDORRA",
        "value": "AD"
    },
    {
        "label": "UNITED ARAB EMIRATES",
        "value": "AE"
    },
    {
        "label": "AFGHANISTAN",
        "value": "AF"
    },
    {
        "label": "ANTIGUA AND BARBUDA",
        "value": "AG"
    },
    {
        "label": "ANGUILLA",
        "value": "AI"
    },
    {
        "label": "ALBANIA",
        "value": "AL"
    },
    {
        "label": "ARMENIA",
        "value": "AM"
    },
    {
        "label": "NETHERLANDS ANTILLES",
        "value": "AN"
    },
    {
        "label": "ANGOLA",
        "value": "AO"
    },
    {
        "label": "ANTARCTICA",
        "value": "AQ"
    },
    {
        "label": "ARGENTINA",
        "value": "AR"
    },
    {
        "label": "AMERICAN SAMOA",
        "value": "AS"
    },
    {
        "label": "AUSTRIA",
        "value": "AT"
    },
    {
        "label": "AUSTRALIA",
        "value": "AU"
    },
    {
        "label": "ARUBA",
        "value": "AW"
    },
    {
        "label": "AZERBAIJAN",
        "value": "AZ"
    },
    {
        "label": "BOSNIA AND HERZEGOVINA",
        "value": "BA"
    },
    {
        "label": "BARBADOS",
        "value": "BB"
    },
    {
        "label": "BANGLADESH",
        "value": "BD"
    },
    {
        "label": "BELGIUM",
        "value": "BE"
    },
    {
        "label": "BURKINA FASO",
        "value": "BF"
    },
    {
        "label": "BULGARIA",
        "value": "BG"
    },
    {
        "label": "BAHRAIN",
        "value": "BH"
    },
    {
        "label": "BURUNDI",
        "value": "BI"
    },
    {
        "label": "BENIN",
        "value": "BJ"
    },
    {
        "label": "SAINT BARTHELEMY",
        "value": "BL"
    },
    {
        "label": "BERMUDA",
        "value": "BM"
    },
    {
        "label": "BRUNEI DARUSSALAM",
        "value": "BN"
    },
    {
        "label": "BOLIVIA",
        "value": "BO"
    },
    {
        "label": "BRAZIL",
        "value": "BR"
    },
    {
        "label": "BAHAMAS",
        "value": "BS"
    },
    {
        "label": "BHUTAN",
        "value": "BT"
    },
    {
        "label": "BOTSWANA",
        "value": "BW"
    },
    {
        "label": "BELARUS",
        "value": "BY"
    },
    {
        "label": "BELIZE",
        "value": "BZ"
    },
    {
        "label": "CANADA",
        "value": "CA"
    },
    {
        "label": "COCOS (KEELING) ISLANDS",
        "value": "CC"
    },
    {
        "label": "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
        "value": "CD"
    },
    {
        "label": "CENTRAL AFRICAN REPUBLIC",
        "value": "CF"
    },
    {
        "label": "CONGO",
        "value": "CG"
    },
    {
        "label": "SWITZERLAND",
        "value": "CH"
    },
    {
        "label": "COTE D IVOIRE",
        "value": "CI"
    },
    {
        "label": "COOK ISLANDS",
        "value": "CK"
    },
    {
        "label": "CHILE",
        "value": "CL"
    },
    {
        "label": "CAMEROON",
        "value": "CM"
    },
    {
        "label": "CHINA",
        "value": "CN"
    },
    {
        "label": "COLOMBIA",
        "value": "CO"
    },
    {
        "label": "COSTA RICA",
        "value": "CR"
    },
    {
        "label": "CUBA",
        "value": "CU"
    },
    {
        "label": "CAPE VERDE",
        "value": "CV"
    },
    {
        "label": "CHRISTMAS ISLAND",
        "value": "CX"
    },
    {
        "label": "CYPRUS",
        "value": "CY"
    },
    {
        "label": "CZECH REPUBLIC",
        "value": "CZ"
    },
    {
        "label": "GERMANY",
        "value": "DE"
    },
    {
        "label": "DJIBOUTI",
        "value": "DJ"
    },
    {
        "label": "DENMARK",
        "value": "DK"
    },
    {
        "label": "DOMINICA",
        "value": "DM"
    },
    {
        "label": "DOMINICAN REPUBLIC",
        "value": "DO"
    },
    {
        "label": "ALGERIA",
        "value": "DZ"
    },
    {
        "label": "ECUADOR",
        "value": "EC"
    },
    {
        "label": "ESTONIA",
        "value": "EE"
    },
    {
        "label": "EGYPT",
        "value": "EG"
    },
    {
        "label": "ERITREA",
        "value": "ER"
    },
    {
        "label": "SPAIN",
        "value": "ES"
    },
    {
        "label": "ETHIOPIA",
        "value": "ET"
    },
    {
        "label": "FINLAND",
        "value": "FI"
    },
    {
        "label": "FIJI",
        "value": "FJ"
    },
    {
        "label": "FALKLAND ISLANDS (MALVINAS)",
        "value": "FK"
    },
    {
        "label": "MICRONESIA, FEDERATED STATES OF",
        "value": "FM"
    },
    {
        "label": "FAROE ISLANDS",
        "value": "FO"
    },
    {
        "label": "FRANCE",
        "value": "FR"
    },
    {
        "label": "GABON",
        "value": "GA"
    },
    {
        "label": "UNITED KINGDOM",
        "value": "GB"
    },
    {
        "label": "GRENADA",
        "value": "GD"
    },
    {
        "label": "GEORGIA",
        "value": "GE"
    },
    {
        "label": "GHANA",
        "value": "GH"
    },
    {
        "label": "GIBRALTAR",
        "value": "GI"
    },
    {
        "label": "GREENLAND",
        "value": "GL"
    },
    {
        "label": "GAMBIA",
        "value": "GM"
    },
    {
        "label": "GUINEA",
        "value": "GN"
    },
    {
        "label": "EQUATORIAL GUINEA",
        "value": "GQ"
    },
    {
        "label": "GREECE",
        "value": "GR"
    },
    {
        "label": "GUATEMALA",
        "value": "GT"
    },
    {
        "label": "GUAM",
        "value": "GU"
    },
    {
        "label": "GUINEA-BISSAU",
        "value": "GW"
    },
    {
        "label": "GUYANA",
        "value": "GY"
    },
    {
        "label": "HONG KONG",
        "value": "HK"
    },
    {
        "label": "HONDURAS",
        "value": "HN"
    },
    {
        "label": "CROATIA",
        "value": "HR"
    },
    {
        "label": "HAITI",
        "value": "HT"
    },
    {
        "label": "HUNGARY",
        "value": "HU"
    },
    {
        "label": "INDONESIA",
        "value": "ID"
    },
    {
        "label": "IRELAND",
        "value": "IE"
    },
    {
        "label": "ISRAEL",
        "value": "IL"
    },
    {
        "label": "ISLE OF MAN",
        "value": "IM"
    },
    {
        "label": "INDIA",
        "value": "IN"
    },
    {
        "label": "IRAQ",
        "value": "IQ"
    },
    {
        "label": "IRAN, ISLAMIC REPUBLIC OF",
        "value": "IR"
    },
    {
        "label": "ICELAND",
        "value": "IS"
    },
    {
        "label": "ITALY",
        "value": "IT"
    },
    {
        "label": "JAMAICA",
        "value": "JM"
    },
    {
        "label": "JORDAN",
        "value": "JO"
    },
    {
        "label": "JAPAN",
        "value": "JP"
    },
    {
        "label": "KENYA",
        "value": "KE"
    },
    {
        "label": "KYRGYZSTAN",
        "value": "KG"
    },
    {
        "label": "CAMBODIA",
        "value": "KH"
    },
    {
        "label": "KIRIBATI",
        "value": "KI"
    },
    {
        "label": "COMOROS",
        "value": "KM"
    },
    {
        "label": "SAINT KITTS AND NEVIS",
        "value": "KN"
    },
    {
        "label": "KOREA DEMOCRATIC PEOPLES REPUBLIC OF",
        "value": "KP"
    },
    {
        "label": "KOREA REPUBLIC OF",
        "value": "KR"
    },
    {
        "label": "KUWAIT",
        "value": "KW"
    },
    {
        "label": "CAYMAN ISLANDS",
        "value": "KY"
    },
    {
        "label": "KAZAKSTAN",
        "value": "KZ"
    },
    {
        "label": "LAO PEOPLES DEMOCRATIC REPUBLIC",
        "value": "LA"
    },
    {
        "label": "LEBANON",
        "value": "LB"
    },
    {
        "label": "SAINT LUCIA",
        "value": "LC"
    },
    {
        "label": "LIECHTENSTEIN",
        "value": "LI"
    },
    {
        "label": "SRI LANKA",
        "value": "LK"
    },
    {
        "label": "LIBERIA",
        "value": "LR"
    },
    {
        "label": "LESOTHO",
        "value": "LS"
    },
    {
        "label": "LITHUANIA",
        "value": "LT"
    },
    {
        "label": "LUXEMBOURG",
        "value": "LU"
    },
    {
        "label": "LATVIA",
        "value": "LV"
    },
    {
        "label": "LIBYAN ARAB JAMAHIRIYA",
        "value": "LY"
    },
    {
        "label": "MOROCCO",
        "value": "MA"
    },
    {
        "label": "MONACO",
        "value": "MC"
    },
    {
        "label": "MOLDOVA, REPUBLIC OF",
        "value": "MD"
    },
    {
        "label": "MONTENEGRO",
        "value": "ME"
    },
    {
        "label": "SAINT MARTIN",
        "value": "MF"
    },
    {
        "label": "MADAGASCAR",
        "value": "MG"
    },
    {
        "label": "MARSHALL ISLANDS",
        "value": "MH"
    },
    {
        "label": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
        "value": "MK"
    },
    {
        "label": "MALI",
        "value": "ML"
    },
    {
        "label": "MYANMAR",
        "value": "MM"
    },
    {
        "label": "MONGOLIA",
        "value": "MN"
    },
    {
        "label": "MACAU",
        "value": "MO"
    },
    {
        "label": "NORTHERN MARIANA ISLANDS",
        "value": "MP"
    },
    {
        "label": "MAURITANIA",
        "value": "MR"
    },
    {
        "label": "MONTSERRAT",
        "value": "MS"
    },
    {
        "label": "MALTA",
        "value": "MT"
    },
    {
        "label": "MAURITIUS",
        "value": "MU"
    },
    {
        "label": "MALDIVES",
        "value": "MV"
    },
    {
        "label": "MALAWI",
        "value": "MW"
    },
    {
        "label": "MEXICO",
        "value": "MX"
    },
    {
        "label": "MALAYSIA",
        "value": "MY"
    },
    {
        "label": "MOZAMBIQUE",
        "value": "MZ"
    },
    {
        "label": "NAMIBIA",
        "value": "NA"
    },
    {
        "label": "NEW CALEDONIA",
        "value": "NC"
    },
    {
        "label": "NIGER",
        "value": "NE"
    },
    {
        "label": "NIGERIA",
        "value": "NG"
    },
    {
        "label": "NICARAGUA",
        "value": "NI"
    },
    {
        "label": "NETHERLANDS",
        "value": "NL"
    },
    {
        "label": "NORWAY",
        "value": "NO"
    },
    {
        "label": "NEPAL",
        "value": "NP"
    },
    {
        "label": "NAURU",
        "value": "NR"
    },
    {
        "label": "NIUE",
        "value": "NU"
    },
    {
        "label": "NEW ZEALAND",
        "value": "NZ"
    },
    {
        "label": "OMAN",
        "value": "OM"
    },
    {
        "label": "PANAMA",
        "value": "PA"
    },
    {
        "label": "PERU",
        "value": "PE"
    },
    {
        "label": "FRENCH POLYNESIA",
        "value": "PF"
    },
    {
        "label": "PAPUA NEW GUINEA",
        "value": "PG"
    },
    {
        "label": "PHILIPPINES",
        "value": "PH"
    },
    {
        "label": "PAKISTAN",
        "value": "PK"
    },
    {
        "label": "POLAND",
        "value": "PL"
    },
    {
        "label": "SAINT PIERRE AND MIQUELON",
        "value": "PM"
    },
    {
        "label": "PITCAIRN",
        "value": "PN"
    },
    {
        "label": "PUERTO RICO",
        "value": "PR"
    },
    {
        "label": "PORTUGAL",
        "value": "PT"
    },
    {
        "label": "PALAU",
        "value": "PW"
    },
    {
        "label": "PARAGUAY",
        "value": "PY"
    },
    {
        "label": "QATAR",
        "value": "QA"
    },
    {
        "label": "ROMANIA",
        "value": "RO"
    },
    {
        "label": "SERBIA",
        "value": "RS"
    },
    {
        "label": "RUSSIAN FEDERATION",
        "value": "RU"
    },
    {
        "label": "RWANDA",
        "value": "RW"
    },
    {
        "label": "SAUDI ARABIA",
        "value": "SA"
    },
    {
        "label": "SOLOMON ISLANDS",
        "value": "SB"
    },
    {
        "label": "SEYCHELLES",
        "value": "SC"
    },
    {
        "label": "SUDAN",
        "value": "SD"
    },
    {
        "label": "SWEDEN",
        "value": "SE"
    },
    {
        "label": "SINGAPORE",
        "value": "SG"
    },
    {
        "label": "SAINT HELENA",
        "value": "SH"
    },
    {
        "label": "SLOVENIA",
        "value": "SI"
    },
    {
        "label": "SLOVAKIA",
        "value": "SK"
    },
    {
        "label": "SIERRA LEONE",
        "value": "SL"
    },
    {
        "label": "SAN MARINO",
        "value": "SM"
    },
    {
        "label": "SENEGAL",
        "value": "SN"
    },
    {
        "label": "SOMALIA",
        "value": "SO"
    },
    {
        "label": "SURINAME",
        "value": "SR"
    },
    {
        "label": "SAO TOME AND PRINCIPE",
        "value": "ST"
    },
    {
        "label": "EL SALVADOR",
        "value": "SV"
    },
    {
        "label": "SYRIAN ARAB REPUBLIC",
        "value": "SY"
    },
    {
        "label": "SWAZILAND",
        "value": "SZ"
    },
    {
        "label": "TURKS AND CAICOS ISLANDS",
        "value": "TC"
    },
    {
        "label": "CHAD",
        "value": "TD"
    },
    {
        "label": "TOGO",
        "value": "TG"
    },
    {
        "label": "THAILAND",
        "value": "TH"
    },
    {
        "label": "TAJIKISTAN",
        "value": "TJ"
    },
    {
        "label": "TOKELAU",
        "value": "TK"
    },
    {
        "label": "TIMOR-LESTE",
        "value": "TL"
    },
    {
        "label": "TURKMENISTAN",
        "value": "TM"
    },
    {
        "label": "TUNISIA",
        "value": "TN"
    },
    {
        "label": "TONGA",
        "value": "TO"
    },
    {
        "label": "TURKEY",
        "value": "TR"
    },
    {
        "label": "TRINIDAD AND TOBAGO",
        "value": "TT"
    },
    {
        "label": "TUVALU",
        "value": "TV"
    },
    {
        "label": "TAIWAN, PROVINCE OF CHINA",
        "value": "TW"
    },
    {
        "label": "TANZANIA, UNITED REPUBLIC OF",
        "value": "TZ"
    },
    {
        "label": "UKRAINE",
        "value": "UA"
    },
    {
        "label": "UGANDA",
        "value": "UG"
    },
    {
        "label": "UNITED STATES",
        "value": "US"
    },
    {
        "label": "URUGUAY",
        "value": "UY"
    },
    {
        "label": "UZBEKISTAN",
        "value": "UZ"
    },
    {
        "label": "HOLY SEE (VATICAN CITY STATE)",
        "value": "VA"
    },
    {
        "label": "SAINT VINCENT AND THE GRENADINES",
        "value": "VC"
    },
    {
        "label": "VENEZUELA",
        "value": "VE"
    },
    {
        "label": "VIRGIN ISLANDS, BRITISH",
        "value": "VG"
    },
    {
        "label": "VIRGIN ISLANDS, U.S.",
        "value": "VI"
    },
    {
        "label": "VIET NAM",
        "value": "VN"
    },
    {
        "label": "VANUATU",
        "value": "VU"
    },
    {
        "label": "WALLIS AND FUTUNA",
        "value": "WF"
    },
    {
        "label": "SAMOA",
        "value": "WS"
    },
    {
        "label": "KOSOVO",
        "value": "XK"
    },
    {
        "label": "YEMEN",
        "value": "YE"
    },
    {
        "label": "MAYOTTE",
        "value": "YT"
    },
    {
        "label": "SOUTH AFRICA",
        "value": "ZA"
    },
    {
        "label": "ZAMBIA",
        "value": "ZM"
    },
    {
        "label": "ZIMBABWE",
        "value": "ZW"
    }
]