import { Box, Image } from '@mantine/core'
import React from 'react'

export default function WallOfLove() {
    return (
        <Box style={{ overflow: "hidden" }} className='flex aic jcc'>
            <Image miw={900} src={`/images/landing_page/senja/wall-of-love.png`} style={{ width: "100%", objectFit: "contain" }} />
        </Box>
        // <div style={{ width: "100%" }}>
        //     <div className="senja-embed" data-id="1f1d4a17-60c0-4f54-b580-86cc5ade8062" data-mode="shadow" data-lazyload="false" style={{ display: "block" }}></div>
        // </div>
        // <script src="https://widget.senja.io/widget/1f1d4a17-60c0-4f54-b580-86cc5ade8062/platform.js" type="text/javascript" async></script>
    )
}
