import React, { useContext, useEffect, useState } from 'react'
import { ClientLanguage, IntakeCardDetailsAnswer, IntakeFieldAnswer, IntakeForm, IntakeMultiSelectAnswer, IntakeQuestion, StaticLink, StaticLinkPublicData } from '../../../../types/global'
import ThemeProvider from '@/providers/Theme.provider';
import { InviteBodyContainer, InviteContainer } from '@/pages/i/[id]';
import api from '@/utils/api';
import { Box, Button, Text, TextInput } from '@mantine/core';
import { ThemeContext } from '@/utils/context/Theme.context';
import Error from '../Error';
import { useRouter } from 'next/router';
import { ClientLanguageSelector } from '../dashboard/ClientCreateModal';
import { IntakeFormQA } from './IntakeFormQA';
import Footer from './Footer';
import lang from "@/../public/translations/static.json"
import cardValidator from 'card-validator';

const MergedIntakeFormElements = ({ questions, answers, setAnswers }: { questions: IntakeQuestion[], answers: Record<string, IntakeFieldAnswer>, setAnswers: React.Dispatch<React.SetStateAction<Record<string, IntakeFieldAnswer>>> }) => {
    return (
        <>
            {questions.sort((a, b) => (a.order || 0) - (b.order || 0)).map((q, i) => (
                <IntakeFormQA
                    disableSpacing
                    disableNumbers
                    size='md'
                    question={q}
                    answer={answers[q.id!]}
                    i={i}
                    setAnswer={(a) => {
                        setAnswers(prev => ({
                            ...prev,
                            [q.id!]: a
                        }))
                        // const newAnswers = [...answers]
                        // newAnswers[i] = a
                        // setAnswers(newAnswers)
                    }}
                />
            ))}
        </>
    )
}

const OnboardingForm = ({ staticLinkData, metadata }: { staticLinkData: StaticLinkPublicData, metadata: object }) => {
    const router = useRouter()
    const theme = useContext(ThemeContext)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    // const [language, setLanguage] = useState<ClientLanguage>("English")
    const [intakeFormAnswers, setIntakeFormAnswers] = useState<Record<string, IntakeFieldAnswer>>({})
    const l = staticLinkData.language || "English"

    useEffect(() => {
        if (staticLinkData.intakeForm?.requested && !intakeFormAnswers.length) {
            setIntakeFormAnswers(staticLinkData.intakeForm.questions.reduce((prev, current) => ({ ...prev, [current.id!]: current.type === "Multi-select" ? [] : "" }), {}))
        }
    }, [staticLinkData])

    return (
        <>
            <Text ta="center" mt={0} fw={700}><Text component={"span"} fz={25} fw={700}><span className='wave'>👋</span> {theme.displayName || staticLinkData.agency.name} {lang.title[l]} </Text> <br /> <Text fw={500} component={"span"} fz="md">{lang.subtitle[l]}</Text></Text>
            <Box mt={20} w={"100%"} px={20} maw={500} style={{ gap: 12 }} className='flex fdc'>
                <TextInput size='md' value={name} onChange={(e) => setName(e.target.value)} label={lang.form.name[l]} placeholder='Joe Smith' required w={"100%"} />
                <TextInput size='md' value={email} onChange={(e) => setEmail(e.target.value)} type="email" label={lang.form.email[l]} placeholder='joe@example.com' required w={"100%"} />
                <TextInput size='md' value={company} onChange={(e) => setCompany(e.target.value)} label={lang.form.company[l]} placeholder='Acme' w={"100%"} />
                {staticLinkData.intakeForm?.requested
                    ? <MergedIntakeFormElements answers={intakeFormAnswers} setAnswers={setIntakeFormAnswers} questions={staticLinkData.intakeForm.questions} />
                    : null
                }
                {/* <Box>
                    <Text fz={"md"} fw={500}>Language</Text>
                    <Text fz="sm" c="dimmed">Select the language you're most comfortable with</Text>
                    <ClientLanguageSelector disableTitle size='md' language={language} setLanguage={setLanguage} />
                </Box> */}
                <Button size='md' disabled={!name || !email || (staticLinkData.intakeForm?.requested
                    ? !!staticLinkData.intakeForm.questions.filter((q, i) => {
                        if (!q.required) return false;
                        switch (q.type) {
                            case 'Input': return !intakeFormAnswers[q.id!]
                            case 'Textbox': return !intakeFormAnswers[q.id!]
                            case 'Option': return !intakeFormAnswers[q.id!]
                            case 'Multi-select': return !(intakeFormAnswers[q.id!] as IntakeMultiSelectAnswer)?.length
                            case 'Card Details': {
                                const answer = intakeFormAnswers[q.id!] as IntakeCardDetailsAnswer
                                return !answer.cvv || !answer.expires || (!answer.number || !cardValidator.number(answer.number).isPotentiallyValid)
                            }
                        }
                    }).length
                    : false
                )} loading={loading} mt={8} fullWidth onClick={() => {
                    setLoading(true)
                    setError("")
                    api.post<string>("/invite/public/static/create-session", {
                        staticLinkID: staticLinkData.id,
                        clientData: {
                            name,
                            email,
                            company,
                            language: l
                        },
                        metadata,
                        intakeFormAnswers,
                        nocache: !!router.query.nocache
                    }).then((res) => {
                        if (res.error) {
                            setLoading(false)
                            return setError(res.msg)
                        }
                        const staticLinkRefs = JSON.parse(window.localStorage.getItem("static_link_sessions") || "{}")
                        staticLinkRefs[staticLinkData.slug] = res.data
                        window.localStorage.setItem("static_link_sessions", JSON.stringify(staticLinkRefs))
                        const newURL = new URL(window.location.href)
                        newURL.searchParams.append("inviteID", res.data)
                        window.location.replace(newURL)
                    })
                }}>{lang.continueButton[l]}</Button>
                <Error>{error}</Error>
            </Box>
        </>
    )
}

export default function StaticSessionForm({ slug }: { slug: string }) {
    const router = useRouter()
    const [metadata, setMetadata] = useState<object>({})
    const [staticLinkData, setStaticLinkData] = useState<StaticLinkPublicData | undefined>()
    const [error, setError] = useState("")

    useEffect(() => {
        try {
            const rawMetadata = router.query.metadata
            console.log("Raw metadata", rawMetadata)
            if (typeof rawMetadata !== "string") return;
            const parsedMetadata = JSON.parse(rawMetadata)
            console.log("Prased MEtadata", parsedMetadata)

            if (typeof parsedMetadata === "object" && Object.keys(parsedMetadata).length < 10) {
                return setMetadata(parsedMetadata)
            }
        } catch {
            void 0
        }
    }, [router])

    useEffect(() => {
        api.get<StaticLinkPublicData>("/invite/public/details/static", {
            slug
        }).then((res) => {
            if (res.error) {
                return setError(res.msg)
            }
            setStaticLinkData(res.data)
        })
    }, [])

    if (!staticLinkData) return null;
    return (
        <ThemeProvider agencyID={staticLinkData.agency.id}>
            <InviteContainer>
                {() => (
                    <>
                        <InviteBodyContainer>
                            <OnboardingForm metadata={metadata} staticLinkData={staticLinkData} />
                        </InviteBodyContainer>
                        <Footer overwriteEmail={staticLinkData.agency.email} overwriteLang={staticLinkData.language} />
                    </>
                )}
            </InviteContainer>
        </ThemeProvider>
    )
}
