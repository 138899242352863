import { PlatformsContext } from '@/utils/context/Platforms.context'
import { Accordion, ActionIcon, alpha, Avatar, Badge, Box, Button, Checkbox, Divider, FileInput, Image, MantineColor, Paper, Select, Skeleton, Slider, Text, TextInput, ThemeIcon, Transition } from '@mantine/core'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ArrowRight, ArrowsLeft, ArrowsRight, Check, ChevronsDown, ChevronsLeft, ChevronsRight, ChevronsUp, Eye, Globe, InfoSquare, Key, LayoutSidebarLeftExpand, LayoutSidebarRightExpand, Lock, Paint, Photo, Plus, QuestionMark, Trash } from 'tabler-icons-react'
import { AccountTypes, AnyService, ClientLanguage, IntakeQuestion, IntakeQuestionType, Invite, InviteCreds, Platform, PublicClient, RequestedAccounts, RequestedService, Theme, ThemeColors } from '../../../../types/global'
import { Account, InviteBodyContainer } from '@/pages/i/[id]'
import ThemeProvider from '@/providers/Theme.provider'
import InviteHeader from '../invite/InviteHeader'
import AccountsContainer from '../invite/AccountsContainer'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { InviteContext } from '@/utils/context/Invite.context'
import ManualGrantsProvider from '@/providers/ManualGrants.provider'
import { useInviteGrantStatus } from '@/utils/helpers/useGranted'
import { ThankYouScreen } from '../invite/ThankYouScreen'
import Footer from '../invite/Footer'
import Link from 'next/link'
import Logo from '../invite/Logo'
import { Form } from '@/pages/i/[id]/intake'
import { ClientLanguageSelector } from '../dashboard/ClientCreateModal'
import { useScreen } from '@/hooks/useScreen'

type ActiveDemoSettings = {
    services: AnyService[],
    intakeQuestions: IntakeQuestion[]
    language: ClientLanguage,
    theme: Theme,
    supportEmail?: string,
    logo?: File
}

type UpdateSetting = <T extends keyof ActiveDemoSettings>(key: T, settings: ActiveDemoSettings[T]) => void

const EditorControl = ({ icon, title, subtitle, color }: { icon: React.ReactElement, title: string, color: MantineColor, subtitle?: string }) => {
    return (
        <Accordion.Control h={70} icon={
            <ThemeIcon variant='light' color={color} size={35} radius={10}>
                {React.cloneElement(icon, { size: 20 })}
            </ThemeIcon>
        }>
            <Box>
                <Text lh={1} fw={500}>
                    {title}
                </Text>
                <Text mt={5} lh={1} fz="xs" c="dimmed">{subtitle}</Text>
            </Box>

        </Accordion.Control>
    )
}

const Editor = ({ settings, updateSetting, setFocuedTab, focusedTab, platform: specificPlatform }: {
    settings: ActiveDemoSettings,
    updateSetting: UpdateSetting,
    setFocuedTab: (t: string) => void,
    focusedTab: string,
    platform?: AccountTypes
}) => {
    const platforms = useContext(PlatformsContext)
    const topServices: AccountTypes[] = ["Google", "Meta"].reverse() as AccountTypes[]
    const manualPlatformExceptions: AccountTypes[] = ["Shopify", "LinkedIn"]
    const colors = ["dark", "gray", "red", "pink", "grape", "violet", "indigo", "blue", "cyan", "green", "lime", "yellow", "orange"] as Array<ThemeColors>

    const getPlatformFilteredServices = (platform: Platform) => {
        return specificPlatform || manualPlatformExceptions.includes(platform.platform) ? platform.services : platform.services.filter((s) => !s.manual || s.requiresAuth || s.requiresAPIConfirmation)
    }

    return (
        <Box p="md" style={{ flex: 1, overflowY: "scroll" }} className='verticalOverflow'>
            <Text fw={700} fz={25}>Your invite</Text>
            <Text lh={1} mt={3} c="dimmed">Customize your invite to fit your agency's needs</Text>

            <Accordion value={focusedTab} onChange={(e) => setFocuedTab(e!)} variant='filled' mt={20} styles={{
                control: {
                    fontWeight: "600"
                },
                content: { padding: 0 }
            }}>
                <Accordion.Item value='Access'>
                    <EditorControl title='Access' subtitle='Choose which accounts you want access to' icon={<Key />} color='blue' />
                    <Accordion.Panel>
                        <Box style={{ position: "relative" }}>
                            <Box px={16} pt={10} pb={50} mah={300} className='flex fdc verticalOverflow' pr={10} style={{ overflowY: "auto", overflowX: "visible", position: "relative", gap: 25 }}>
                                {platforms.filter(p => specificPlatform ? p.platform === specificPlatform : true).sort((a, b) => topServices.indexOf(b.platform) - topServices.indexOf(a.platform))?.map((platform, i) => {
                                    const allServicse = platform.services
                                    const filteredServices = getPlatformFilteredServices(platform)

                                    if (!filteredServices.length) return null;
                                    return (
                                        <>
                                            <Box id={`${platform}-service-container`}>
                                                <Box className='flex aic' style={{ gap: 10 }}>
                                                    <Paper p={5} style={{ borderRadius: 5, background: "#fff" }}>
                                                        <Image src={`/images/logos/${platform.platform.replaceAll(" ", "_").toLowerCase()}.png`} style={{ width: 20, height: 20, objectFit: "contain" }} />
                                                    </Paper>
                                                    <Text fw={600}>{platform.platform} accounts</Text>

                                                </Box>
                                                <Box mt={10} style={{ flexWrap: "wrap", gap: 10, }} className='flex'>
                                                    {filteredServices.map((service, i) => {
                                                        const selected = settings.services.includes(service.name)
                                                        return (
                                                            <Paper onClick={() => {
                                                                selected ? updateSetting("services", [...settings.services.filter((s) => s !== service.name)]) : updateSetting("services", [...settings.services, service.name])
                                                            }} radius={100} shadow='xs' p="xs" py={5} key={i} className='flex aic focus-bounce cp' style={{ gap: 10, maxWidth: "calc((100% / 3) - 10px)", minWidth: "fit-content" }}>
                                                                {/* <Checkbox radius={100} /> */}
                                                                <Box bg={selected ? "dark.6" : "gray.1"} w={20} h={20} style={{ borderRadius: 100 }} className='flex aic jcc'>
                                                                    <Check strokeWidth={4} size={14} color={selected ? "#fff" : "var(--mantine-color-gray-4)"} />
                                                                </Box>
                                                                <Box className='flex aic' style={{ gap: 5 }}>
                                                                    {/* <Paper radius={100} shadow='xs' className='flex aic jcc' p={5} bg={"#fff"}> */}
                                                                    <Image src={`/images/logos/${service.name.replaceAll(" ", "_").toLowerCase()}.png`} style={{ width: 16, height: 16, objectFit: "contain" }} />

                                                                    {/* </Paper> */}
                                                                    <Text lineClamp={1} lh={1} fz="sm" fw={500}>{service.name.replace(platform.platform, "")}</Text>

                                                                </Box>
                                                            </Paper>
                                                        )
                                                    })}
                                                    {allServicse.length !== filteredServices.length
                                                        ? <Paper bg="gray.0" radius={100} shadow='xs' p="xs" py={5} key={i} className='flex aic focus-bounce cp' style={{ gap: 10, maxWidth: "calc((100% / 3) - 10px)", minWidth: "fit-content" }}>
                                                            <Text lineClamp={1} lh={1} fz="sm" fw={500}>+{allServicse.length - filteredServices.length} more</Text>
                                                        </Paper>
                                                        : null
                                                    }
                                                </Box>
                                            </Box>
                                        </>
                                    )
                                })}
                                <Box className='flex aic jcsb'>
                                    <Box className='flex fdc' style={{ gap: 0 }}>
                                        <Text fw={600}>And more...</Text>
                                        <Text fz={"xs"} c="dimmed">We support over 20 popular services</Text>
                                        <Avatar.Group mt={6}>
                                            {platforms.filter((platform) => !getPlatformFilteredServices(platform).length).map((platform) => (
                                                <Avatar bg={`linear-gradient(90deg, ${alpha("var(--mantine-color-blue-3)", .1)} 0%, #fff 100%)`} radius={5} src={`/images/logos/${platform.platform.replaceAll(" ", "_").toLowerCase()}.png`} styles={{ image: { objectFit: "contain" } }} p={6} style={{ boxShadow: "0px 0px 8px rgba(0,0,0,.05)", border: "none", backdropFilter: "blur(20px)" }}>

                                                </Avatar>
                                            ))}
                                        </Avatar.Group>
                                    </Box>
                                    <Button component={Link} href={`/integrations`} rightSection={<ArrowRight size={18} />} variant='default'>View all</Button>
                                </Box>
                            </Box>
                            <Box style={{ position: "absolute", bottom: 0, width: "100%", height: 30, background: "linear-gradient(0deg, rgb(248, 249, 250) 0%, rgba(255,255,255,0) 100%)" }}></Box>
                        </Box>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value='Branding'>
                    <EditorControl title='Branding' subtitle='Adjust your invite page to fit your brand' icon={<Paint />} color='red' />
                    <Accordion.Panel>
                        <Box px={16} pb={20}>
                            <Box className='flex fdc'>
                                <Box className='flex fdc' style={{ gap: 5 }}>
                                    <Text fw={600} lh={1}>Colour</Text>
                                    <Text lh={1.2} fz="xs" c="dimmed">Choose a primary colour for your invite page.</Text>
                                    {/* This colour will be applied to buttons, inputs, and accents. */}
                                </Box>
                                <Box maw={250} mt={10} className='flex aic' style={{ gap: 5, flexWrap: "wrap" }}>
                                    {colors.map((c) => (
                                        <Box onClick={() => updateSetting("theme", { ...settings.theme, color: c })} style={{ width: 25, height: 25, borderRadius: 5, }} bg={c} className='flex aic jcc cp'>
                                            {c === settings.theme.color
                                                ? <Check color='white' size={14} />
                                                : null
                                            }
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box mt={20} className='flex fdc'>
                                <Box className='flex fdc' style={{ gap: 5 }}>
                                    <Text fw={600} lh={1}>Your Agency</Text>
                                    <Text lh={1.2} fz="xs" c="dimmed">Enter your agency's details</Text>
                                    {/* This colour will be applied to buttons, inputs, and accents. */}
                                </Box>
                                <Box maw={250} mt={5} className='flex aic' style={{ gap: 5, flexWrap: "wrap" }}>
                                    <TextInput onChange={(e) => updateSetting("theme", { ...settings.theme, displayName: e.target.value })} w="100%" label="Name" placeholder='AgencyAccess' />
                                    <TextInput value={settings.supportEmail} onChange={(e) => updateSetting("supportEmail", e.target.value)} w="100%" label="Support Email" placeholder='support@agencyaccess.co' />
                                    <FileInput clearable accept='image/png,image/jpeg' leftSection={<Photo size={18} />} w={"100%"} label="Logo" placeholder="Upload your logo" value={settings.logo} onChange={(e) => updateSetting("logo", e || undefined)} />
                                    <Box mt={3} pb={15} w={"100%"}>
                                        <Box className='flex aic jcsb'>
                                            <Text fz="sm" fw={500}>Logo Size</Text>
                                            <Text fz="sm" fw={500} c="dimmed">{settings.theme.logoWidthPercentage}%</Text>
                                        </Box>
                                        <Slider mt={4} marks={[
                                            { value: 20, label: '20%' },
                                            { value: 50, label: '50%' },
                                            { value: 80, label: '80%' },
                                            // @ts-ignore
                                        ]} styles={{ markLabel: { fontSize: 11 } }} label={null} value={parseInt(settings.theme.logoWidthPercentage) || 100} onChange={e => updateSetting("theme", { ...settings.theme, logoWidthPercentage: e })} />
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='Intake Form'>
                    <EditorControl title='Intake Form' subtitle='Collect infomation from your clients' icon={<QuestionMark />} color='yellow' />
                    <Accordion.Panel h={focusedTab === "Intake Form" ? "fit-content" : 0}>
                        <Box px={16} pb={20}>
                            <Box className='flex fdc' style={{ gap: 5 }}>
                                <Text fw={600} lh={1}>Questions</Text>
                                <Text lh={1.2} fz="xs" c="dimmed">Enter a set of form questions</Text>
                                {/* This colour will be applied to buttons, inputs, and accents. */}
                            </Box>
                            <Box mt={10} className='flex fdc' style={{ gap: 8 }}>
                                {settings.intakeQuestions.map((q, i) => (
                                    <Box key={i}>
                                        <Text fw={600} fz="sm">Question {i + 1}</Text>
                                        <Box mt={3} className='flex aic'>
                                            <TextInput className='demoIntakeFormQuestionInput' value={q.title} onChange={(e) => updateSetting("intakeQuestions", settings.intakeQuestions.map((question, index) => index === i ? { ...q, title: e.target.value } : { ...question }))} styles={{ input: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }} placeholder='Question title' />
                                            <Select className='demoIntakeFormQuestionInput'
                                                placeholder='Question type'
                                                styles={{ input: { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 } }}
                                                data={["Input", "Textbox", { label: "Select", value: "select", disabled: true }, { label: "Multi-Select", value: "multi-select", disabled: true }] as IntakeQuestionType[]}
                                                value={q.type}
                                                onChange={(e) => {
                                                    // @ts-ignore
                                                    updateSetting("intakeQuestions", settings.intakeQuestions.map((question, index) => index === i ? { ...q, type: e } : { ...question }))
                                                }}
                                            />
                                            {i !== 0
                                                ? <ActionIcon ml={10} variant='subtle' color='gray' onClick={() => {
                                                    updateSetting("intakeQuestions", settings.intakeQuestions.filter((_, index) => i !== index))
                                                }}>
                                                    <Trash size={18} />
                                                </ActionIcon>
                                                : null
                                            }

                                        </Box>
                                    </Box>
                                ))}
                                {/* @ts-ignore */}
                                <Button w="fit-content" onClick={() => updateSetting("intakeQuestions", [...settings.intakeQuestions, {}])} mt={3} color='blue' leftSection={<Plus size={15} />} variant='subtle' size='compact-xs'>Add Question</Button>
                            </Box>
                        </Box>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='Language'>
                    <EditorControl title='Language' subtitle='Localise your link' icon={<Globe />} color='green' />
                    <Accordion.Panel>
                        <Box px={16} pb={20}>
                            <ClientLanguageSelector language={settings.language} setLanguage={(l) => updateSetting("language", l)} />
                            <Paper shadow='xs' p="xs" radius={8} mt={10} className='flex aic' style={{ gap: 10 }}>
                                <InfoSquare size={18} />
                                <Text lh={1} fz="sm" fw={500}>More coming soon</Text>
                            </Paper>
                        </Box>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Box>
    )
}

export const Preview = ({ settings, mode, disableLogo }: { settings: ActiveDemoSettings, mode: "invite" | "intake", disableLogo?: boolean }) => {
    const CLIENT = {
        lang: settings.language,
        name: "Client",
        id: "xxx",
        type: "Client"
    } as PublicClient

    const AGENCY = {
        name: "Agency",
        email: settings.supportEmail || "example@agencyaccess.co",
        id: "xxx"
    }
    const platforms = useContext(PlatformsContext)

    const buildRequestedAccounts = useCallback(() => {
        const requestedAccounts = settings.services.reduce((prev, service) => {
            const platform = platforms.find((p) => p.services.find((s) => s.name === service)) as Platform
            if (!platform) return prev;
            if (prev[platform.platform]) {
                return {
                    ...prev,
                    [platform.platform]: {
                        ...prev[platform.platform],
                        [service]: {
                            requested: true,
                            accessLevel: platforms.find((p) => p.platform === platform.platform)?.services.find((s) => s.name === service)?.accessLevels[0]?.value,
                            requestedAccountLinks: []
                        } as RequestedService
                    }
                }
            }
            return {
                ...prev,
                [platform.platform]: {
                    [service]: {
                        requested: true,
                        accessLevel: platforms.find((p) => p.platform === platform.platform)?.services.find((s) => s.name === service)?.accessLevels[0]?.value,
                        requestedAccountLinks: []
                    } as RequestedService
                }
            }
        }, {} as RequestedAccounts)
        console.log("Built", requestedAccounts, "using", settings.services)
        return requestedAccounts
    }, [settings, platforms])
    // const [REQUESTED_ACCOUNTS, setRequestedAccounts] = useState({} as RequestedAccounts)
    const [INVITE, setInvite] = useState<Invite>({
        requestedAccounts: buildRequestedAccounts() as RequestedAccounts,
        DEMO: true,
        agency: AGENCY,
        clientID: "xxx",
        created: Date.now(),
        id: "xxx",
        cc: [],
        creds: {} as InviteCreds,
        lastReminderTimestamp: 0,
        DEMO_update: (updates) => setInvite((prev) => ({ ...prev, ...updates })),
    })
    const granted = useInviteGrantStatus("xxx", INVITE)

    useEffect(() => {
        // @ts-ignore
        setInvite({
            ...INVITE,
            agency: {
                ...AGENCY,
                // @ts-ignore
                email: settings.supportEmail
            },
            requestedAccounts: buildRequestedAccounts()

        })
    }, [settings, platforms])

    useEffect(() => {
        console.log("Eval invite", INVITE, settings)
    }, [INVITE])

    // useEffect(() => {
    //     setInvite((i) => ({ ...i, requestedAccounts: REQUESTED_ACCOUNTS }))
    // }, [REQUESTED_ACCOUNTS])

    return (
        <Box className=' aic fdc verticalOverflow' px={20} w="100%" pb={20} style={{ overflowY: "auto", position: "relative" }} >
            <ThemeProvider theme={{ ...settings.theme, logo: settings.logo ? URL.createObjectURL(settings.logo) : undefined }}>
                <ManualGrantsProvider>
                    <InviteContext.Provider value={{
                        ...INVITE
                    }}>
                        <InviteClientContext.Provider value={{
                            ...CLIENT
                        }} >
                            {!disableLogo
                                ? <Box pt={20}>
                                    <Logo />
                                </Box>
                                : null
                            }

                            {granted
                                ? <ThankYouScreen invite={INVITE} />
                                : <InviteBodyContainer>
                                    {mode === "intake"
                                        ? <Form form={{ enabled: true, questions: settings.intakeQuestions }} />
                                        : <>
                                            <InviteHeader
                                                client={CLIENT}
                                                lang={settings.language}
                                                theme={settings.theme}
                                                invite={{
                                                    ...INVITE
                                                }}
                                            />
                                            <AccountsContainer granted={false} requestedAccounts={INVITE.requestedAccounts}>
                                                {(account, setFocusedAccount) => {
                                                    if (!account) return null
                                                    return <Account account={account} id='xxx' setFocusedPlatform={setFocusedAccount} />
                                                }}
                                            </AccountsContainer>
                                        </>
                                    }
                                </InviteBodyContainer>
                            }

                            {settings.supportEmail
                                ? <Box className='flex aic jcc fdc'>
                                    <Footer overwriteEmail={settings.supportEmail} />
                                </Box>
                                : null
                            }


                        </InviteClientContext.Provider>

                    </InviteContext.Provider>
                </ManualGrantsProvider>
            </ThemeProvider>

        </Box>
    )
}

type ToggledSection = "Editor" | "Preview"
export default function ActiveDemo({ defaultServices, platform }: { defaultServices?: AnyService[], platform?: AccountTypes }) {
    const { useToggleSections } = useScreen({
        useToggleSections: "865px"
    })
    const [toggledSection, setToggledSection] = useState<ToggledSection>("Editor")
    const [focusedTab, setFocusedTab] = useState<string>("Access")
    const [settings, setSettings] = useState<ActiveDemoSettings>({
        services: defaultServices || ["Google Ads", "Google Analytics"],
        theme: {
            theme: "light",
            color: "blue",
            logoWidthPercentage: "100",
        },
        language: "English",
        intakeQuestions: [
            { title: "Billing email", options: [], type: "Input", order: 0 }
        ]
    })

    const updateSetting: UpdateSetting = (key, value) => {
        setSettings((prev) => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (!defaultServices?.length) return;
        updateSetting("services", defaultServices)
    }, [defaultServices])

    return (
        <Paper className='flex fdc' p={0} shadow='xs' style={{ width: "90%", maxWidth: 1000, aspectRatio: "16 / 9", borderRadius: 10, background: "#fff", position: "relative", bottom: 50, margin: "0 auto", overflow: "hidden" }}>
            <Box px={20} py={10} className='flex aic jcc'>
                <Box style={{ flex: 1, gap: 6 }} className='flex'>
                    <Box bg={"red"} style={{ width: 14, height: 14, borderRadius: 20 }} />
                    <Box bg={"orange"} style={{ width: 14, height: 14, borderRadius: 20 }} />
                    <Box bg={"green"} style={{ width: 14, height: 14, borderRadius: 20 }} />
                </Box>
                <Box px="sm" bg={"gray.1"} style={{ borderRadius: 5, gap: 10 }} className='flex aic jcc'>
                    <Lock size={14} color='var(--mantine-color-gray-6)' />
                    <Text c="gray.6" fz="sm">your-agency.agencyaccess.co</Text>
                </Box>
                <Box style={{ flex: 1, justifyContent: "flex-end" }} className='flex'>
                    <Badge style={{ borderWidth: 0 }} color='red' c="#fff" bg={"#212121"} variant='dot'>Live Demo</Badge>
                </Box>
            </Box>
            <Divider opacity={.5} />
            <Box style={{ flex: 1, overflow: "hidden", flexDirection: useToggleSections && toggledSection !== "Editor" ? "row-reverse" : "row", position: 'relative' }} className='flex'>
                {!useToggleSections || toggledSection === "Editor"
                    ? <Box style={{ flex: 1 }} className='flex'>
                        <Editor platform={platform} focusedTab={focusedTab} setFocuedTab={setFocusedTab} settings={settings} updateSetting={updateSetting} />
                    </Box>
                    : null
                }

                {!useToggleSections || toggledSection === "Preview"
                    ? <Box className='flex' style={{ flex: 1, position: "relative" }} bg={"gray.0"} id='activeDemoContainer'>
                        <Preview mode={focusedTab === "Intake Form" ? "intake" : "invite"} settings={settings} />
                    </Box>
                    : null
                }

                {useToggleSections
                    ? <Paper radius={0} className='flex aic fdc' py={15} h="100%" bg="#fff" w={40} style={{ position: "relative", overflow: "hidden", gap: 8 }} onClick={() => setToggledSection(toggledSection === "Editor" ? "Preview" : "Editor")}>
                        {/* <Box style={{ position: "absolute", transform: `${toggledSection === "Preview" ? "" : ""}`, top: 20, overflow: "visible" }} w={40}> */}
                        {/* <Box w={300} className='flex aic' style={{ gap: 8, textOrientation: "mixed", writingMode: "vertical-lr" }}> */}
                        <Text style={{ textOrientation: "mixed", writingMode: "vertical-lr", transform: toggledSection === "Preview" ? "rotate(180deg)" : "" }} fw={600} c="rgba(0,0,0,.8)">{toggledSection === "Preview" ? "Edit Request" : "Preview Form"}</Text>
                        {toggledSection === "Editor"
                            ? <ChevronsLeft size={20} color='rgba(0,0,0,.8)' />
                            : <ChevronsRight size={20} color='rgba(0,0,0,.8)' />
                        }
                        {/* <Eye size={20} color='var(--mantine-color-dimmed)' /> */}
                        {/* <LayoutSidebarLeftExpand size={20} color='var(--mantine-color-dimmed)' /> */}
                        {/* </Box> */}
                        {/* </Box> */}
                    </Paper>
                    : null
                }

            </Box>
        </Paper>
    )
}
