/*
    PLATFORM WIDE GUIDES
    Platform wide guides are custom components that replace the default grant workflow. They get access to the invite and update the grant status on their own.
*/

import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AccountTypes, AnyService, BaseServiceConfig, Invite, RequestedServices, ServiceConfig } from '../../../../types/global'
import { GrantsStatus } from './GrantsContainer'
import { ActionIcon, Box, Button, Divider, Image, Loader, Modal, MultiSelect, Paper, Select, Text, alpha, useMantineTheme } from '@mantine/core'
import MetaPartnerManager from '../oauth/MetaPartnerManager'
import { circleNumberIcons } from '@/static/numberIcons'
import Link from 'next/link'
import { ArrowLeft, ArrowRight, Badge, CircleCheck, ExternalLink, IdBadge, InfoCircle, Settings } from 'tabler-icons-react'
import CopyBox from '../CopyBox'
import api from '@/utils/api'
import { roleValueToLabel } from '@/static/roles'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import { GrantedChip, Selector } from './ServiceConfigSelector'
import { useElementSize } from '@mantine/hooks'
import { InstructionalImageRenderer } from './ManualInstructions'
import { useManualGrantStatus } from './ManualAccessGrantsListener'
import { InviteContext } from '@/utils/context/Invite.context'
import { useGrantRequest } from '@/utils/helpers/useGrantRequest'
import { ManualGrantsContext } from '@/utils/context/ManualGrants.context'
import Error, { ErrorList } from '../Error'
import { useGrantStatus } from '@/hooks/useGrantStatus'
import { ThemeContext } from '@/utils/context/Theme.context'
import { markServiceGranted } from '@/utils/helpers/markAsGranted'
import { InlinePageLink } from '../InlineLink'
import { includesAutomaticGrants, includesManualGrants } from '@/utils/helpers/getRequestedServicesMetadata'
import lang from '../../../public/translations/meta.json';
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { notifications } from '@mantine/notifications'
import { BusinessPortfolioForm } from '../oauth/MetaBusinessSelector'
import businessManagerLang from "@/../public/translations/metaBusinessSuiteSelector.json"
type PlatformWideGuideProps = {
    invite: Invite,
}

export const SwitchBusinessManagerAccount = ({ open, setOpen }: { open: boolean, setOpen: (o: boolean) => void }) => {
    const invite = useContext(InviteContext)
    const { lang: l } = useContext(InviteClientContext)
    const [mode, setMode] = useState<"existing" | "new">("existing")
    const [business, setBusiness] = useState<string | null | undefined>(invite.creds.Meta.business?.id)
    const [businesses, setBusinesses] = useState<undefined | Array<{ id: string, account_id: string }>>(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setMode("existing")
        setBusiness(invite.creds.Meta.business?.id)
    }, [invite])

    useEffect(() => {
        api.post<{ id: string, account_id: string }[]>("/accounts/client/meta/business/accounts", {
            inviteID: invite.id
        }).then((res) => {
            if (res.error) return setBusinesses([]);
            setBusinesses(res.data)
        })
    }, [])

    const updateBusinessManager = useCallback((id: string) => new Promise<void>((resolve, reject) => {
        setLoading(true)
        api.post(`/accounts/client/meta/set-business`, {
            selectedBusinessID: id, inviteID: invite.id
        }).then((res) => {
            setLoading(false)
            if (res.error) {
                notifications.show({
                    color: "red",
                    title: "Error",
                    message: res.msg
                })
                return reject()
            }
            resolve()
        })
    }), [])

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Box className='flex aic' style={{ gap: 10 }}>
                <Image src={`/images/logos/meta.png`} style={{ width: 28, height: 28, objectFit: "contain", borderRadius: 5, padding: 3, border: "0px solid rgba(0,0,0,.1)" }} />
                <Text fw={500}>{mode === "existing" ? businessManagerLang.title.verb.switch[l] : businessManagerLang.title.verb.create[l]} {businessManagerLang.title.sentence[l]}</Text>
            </Box>}
            styles={{
                header: {
                    minHeight: 0
                }
            }}
        >
            <Divider mt={5} opacity={.3} />
            {mode === "existing"
                ? <>
                    <Select
                        mt={6}
                        label={businessManagerLang.select[l]}

                        placeholder={!businesses ? (businessManagerLang.loading[l]) : (!businesses.length ? (businessManagerLang.noneFound[l]) : (businessManagerLang.select[l]))}
                        data={businesses ? businesses.map((b) => ({ label: b.account_id, value: b.id })) : []}
                        value={business}
                        onChange={setBusiness}
                    />
                    <Button fullWidth mt={8} loading={loading} disabled={!business} onClick={() => updateBusinessManager(business!).then(() => setOpen(false))}>{businessManagerLang.title.verb.switch[l]}</Button>
                    <Text className='cp' mt={3} ml={5} fz="xs" fw={500} c="dimmed">{businessManagerLang.or[l]} <Text onClick={() => setMode("new")} fw={600} span c="blue" fz="xs">{businessManagerLang.prompts.create[l]}</Text></Text>
                </>
                : <>
                    <BusinessPortfolioForm l={l} loading={loading} disableCopy accountEmail={invite.creds.Meta.email!} close={async (id) => {
                        if (!id) return;
                        try {
                            await updateBusinessManager(id)
                            setOpen(false)
                        } catch {
                            void 0
                        }
                    }} inviteID={invite.id} />
                    <Text className='cp' mt={3} ml={5} fz="xs" fw={500} c="dimmed">{businessManagerLang.or[l]} <Text onClick={() => setMode("existing")} fw={600} span c="blue" fz="xs">{businessManagerLang.prompts.existing[l]}</Text></Text>

                </>
            }
        </Modal>
    )
}

const ServiceGrantStatus = ({
    serviceConfig,
    requestedServices,
    accounts,
    service
}: { platform: AccountTypes, serviceConfig: BaseServiceConfig, service: AnyService, requestedServices: RequestedServices, accounts: Record<AnyService, Array<{ account_id: string, id: string, page?: string }>> }) => {
    const platforms = useContext(PlatformsContext)
    const invite = useContext(InviteContext)
    const theme = useMantineTheme()
    const { lang: l } = useContext(InviteClientContext)
    const inviteTheme = useContext(ThemeContext)

    const manualGrantResources = useMemo(() => {
        console.log("reevaluating resources")
        const resources = serviceConfig[service].map((r) => {
            if (service === "Instagram") {
                return {
                    instagramAccountID: r,
                    pageID: accounts?.Instagram?.find(a => a.id === r)?.page as string
                }
            };
            return r
        })
        return resources
    }, [serviceConfig])

    const { grantStatus, isGranted, } = useManualGrantStatus({
        accountType: "Meta",
        inviteID: invite.id,
        resources: manualGrantResources,
        service,
        readOnlyGranted: false
    })

    useEffect(() => {
        if (!isGranted) return;
        notifications.show({
            title: `${service} Granted`,
            message: `Access to ${service} was granted successfully`,
            icon: <Image width={22} height={22} src={`/images/logos/${service.replaceAll(" ", "_").toLowerCase()}.png`} />,
            color: inviteTheme.color
        })
        // markServiceGranted(invite.id, platform, service)
    }, [isGranted])

    return (<Box>
        <Box className='flex aic' style={{ gap: 10 }}>
            <Box bg={"#fff"} className='flex aic jcc' style={{ borderRadius: 100, width: 25, height: 25, overflow: "hidden" }}>
                <Image width={18} height={18} alt={`${service} logo`} style={{ borderRadius: 0, objectFit: "contain", backgroundColor: "#fff", padding: 0 }} src={`/images/logos/${service?.toLowerCase().replaceAll(" ", "_")}.png`} />
            </Box>
            <div>
                <Text fw={400} fz={"sm"}>{service === "Meta Pixels" ? "Datasets" : service}</Text>
                {/* <Text  fz={"xs"} c="dimmed">{roleValueToLabel(service, requestedServices[service as AnyService].accessLevel, platforms)}</Text> */}
            </div>
        </Box>
        <Box ml={20}>
            {serviceConfig[service].map((a, i) => {
                const isGranted = grantStatus[a]
                return (
                    <Box key={i} className='flex aic jcsb'>
                        <Box className='flex' style={{ gap: 10 }}>
                            <Box style={{ borderLeft: "2px solid #ccc", borderBottomLeftRadius: 5, borderBottom: "2px solid #ccc", width: 15, height: 20 }}></Box>
                            <Box mt={7}>
                                <Text fz="sm">{accounts[service as AnyService].find((acc) => acc.id === a)?.account_id}</Text>
                                <Box className='flex aic' style={{ gap: 4 }}>
                                    <IdBadge size={15} color='var(--mantine-color-dimmed)' />
                                    <Text fz="xs" fw={600} c="dimmed">{roleValueToLabel(service, requestedServices[service as AnyService].accessLevel, platforms)}</Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='flex aic' py={3} px={10} style={{ borderRadius: 10, gap: 6 }} bg={isGranted ? alpha(theme.colors["green"][6], .1) : alpha(theme.colors["orange"][6], .1)}>
                            <Box bg={isGranted ? "green" : "orange"} style={{ width: 6, height: 6, borderRadius: 20 }} />
                            <Text fz="xs" c={isGranted ? "green" : "orange"} fw={600}>{isGranted ? (lang.share.grantStatusChip.granted[l]) : (lang.share.grantStatusChip.waiting[l])}</Text>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    </Box>
    )
}

const GrantStatuses = ({ serviceConfig, requestedServices, accounts }: {
    serviceConfig: BaseServiceConfig,
    requestedServices: RequestedServices,
    accounts: Record<AnyService, Array<{ account_id: string, id: string }>>
}) => {
    const manualGrants = useContext(ManualGrantsContext)
    const theme = useContext(ThemeContext)
    return (
        <>
            <Box className='flex fdc' bg={theme.theme === "dark" ? "rgb(26, 32, 44)" : "gray.0"} style={{ borderRadius: 10, gap: 10 }} p="xs" mt={10}>
                {Object.keys(serviceConfig).map((s) => {
                    if (!manualGrants.includes(s as AnyService)) return null;
                    return <ServiceGrantStatus
                        platform="Meta"
                        accounts={accounts}
                        requestedServices={requestedServices}
                        service={s as AnyService}
                        serviceConfig={serviceConfig}
                    />
                })}
            </Box>
            {/* <Button mt={10} fullWidth loading={loading} leftSection={<CircleCheck size={18} />} color={theme.color} onClick={async () => {
                setLoading(true)
                await markServiceGranted(invite.id, "Meta", "Meta Ads").finally(() => {
                    setLoading(false)
                })
            }}>{"Mark as Granted"}</Button> */}
        </>
    )
}

const serviceConfigAPIRoutes = {
    "Meta Ads": "/accounts/client/meta/ads/accounts",
    "Facebook Pages": "/accounts/client/meta/pages/accounts",
    "Instagram": "/accounts/client/meta/instagram/pages",
    "Facebook Product Catalog": "/accounts/client/meta/catalog/catalogs",
    "Meta Pixels": "/accounts/client/meta/datasets/pixels",
} as Record<AnyService, string>

const ServicesRenderer = ({ services,
    loading,
    grantStatus,
    serviceConfig,
    business,
    setAccounts,
    inviteID,
    accounts,
    setServiceConfig
}: {
    services: RequestedServices,
    business: { name: string, id: string },
    loading: boolean,
    grantStatus: GrantsStatus,
    serviceConfig: BaseServiceConfig,
    setAccounts: (a: Array<{ account_id: string, id: string }>, s: AnyService) => void,
    inviteID: string,
    accounts: Record<AnyService, Array<{ account_id: string, id: string }>>,
    setServiceConfig: (s: BaseServiceConfig) => void
}) => {
    const platforms = useContext(PlatformsContext)
    return (
        <>
            {Object.keys(services).sort((a, b) => a.length - b.length).map((s, i) => <Box key={s} className='flex aic jcsb'>
                <Box style={{ gap: 7 }} className='flex'>
                    <Box bg={"#fff"} className='flex aic jcc' style={{ borderRadius: 100, width: 25, height: 25, overflow: "hidden" }}>
                        {loading && (!grantStatus || !grantStatus[s as AnyService]?.granted) && serviceConfig[s]
                            ? <Loader size={18} />
                            : <Image width={18} height={18} alt={`${s} logo`} style={{ borderRadius: 0, objectFit: "contain", backgroundColor: "#fff", padding: 0 }} src={`/images/logos/${s?.toLowerCase().replaceAll(" ", "_")}.png`} />
                        }
                    </Box>
                    <div>
                        <Text fw={400} fz={"sm"}>{s}</Text>
                        <Text fz={"xs"} c="dimmed">{roleValueToLabel(s, services[s as AnyService].accessLevel, platforms)}</Text>
                    </div>
                </Box>
                {!grantStatus || !grantStatus[s as AnyService]?.granted
                    ? <Selector
                        grantStatus={grantStatus}
                        apiRoute={serviceConfigAPIRoutes[s as AnyService]}
                        businessName={business.name}
                        inviteID={inviteID}
                        service={s as AnyService}
                        serviceConfig={serviceConfig}
                        // @ts-ignore
                        setServiceConfigs={setServiceConfig}
                        setAccounts={(a) => setAccounts(a, s as AnyService)}
                        __facebookPagesForInstagramValidation={(s as AnyService) === "Instagram" ? accounts["Facebook Pages"] : undefined}
                    />
                    : <GrantedChip skipped={services[s as AnyService].skipped} />
                }

            </Box>)}
        </>
    )
}

const AssetsSelector = ({ requestedServices, inviteID, serviceConfig, grantStatus, setServiceConfig, business, setAccounts, next, accounts }: { requestedServices: RequestedServices, grantStatus: GrantsStatus, next: () => void, setAccounts: (a: Array<{ account_id: string, id: string }>, s: AnyService) => void, accounts: Record<AnyService, Array<{ account_id: string, id: string }>>, inviteID: string, business: { name: string, id: string }, serviceConfig: BaseServiceConfig, setServiceConfig: (s: BaseServiceConfig) => void }) => {
    const platforms = useContext(PlatformsContext)
    const invite = useContext(InviteContext)
    const manualGrants = useContext(ManualGrantsContext)
    const theme = useContext(ThemeContext)
    const { grantsStatus } = useGrantStatus(invite, "Meta", false)
    const { lang: l } = useContext(InviteClientContext)
    // Pages + Catalog are automatic grants but the rest are manual. We'll deal with the automatic grants first then manual services on the next page
    const {
        error,
        errorList,
        loading,
        sendGrantRequest,
        setError,
        setErrorList
    } = useGrantRequest()

    const allAutomaticGranted = useCallback(() => {
        const remainingAutoServices = Object.keys(grantStatus).filter(s => !manualGrants.includes(s as AnyService) && !grantStatus[s as AnyService]?.granted)
        return !remainingAutoServices.length
    }, [grantStatus, manualGrants])

    useEffect(() => {
        if (allAutomaticGranted() && Object.keys(requestedServices).filter((a) => manualGrants.includes(a as AnyService)).length && !error && !errorList?.length && !Object.keys(requestedServices).filter((s) => !serviceConfig[s]?.length && !grantsStatus[s as AnyService]?.granted).length) {
            next()
        }
    }, [grantsStatus])

    return (
        <>
            {includesAutomaticGrants(requestedServices, manualGrants)
                ? <Box bg={theme.theme === "dark" ? "rgb(26, 32, 44)" : "gray.0"} p="sm" style={{ borderRadius: 10, gap: 5 }} mt={10} className='flex fdc'>
                    {includesManualGrants(requestedServices, manualGrants)
                        ? <div style={{ marginBottom: 10 }}>
                            <Text fz={"md"} fw={500}>{lang.assetSelection.automaticHeader.title[l]}</Text>
                            <Text fz={"xs"} c="dimmed">{lang.assetSelection.automaticHeader.subtitle[l]}</Text>
                        </div>
                        : null
                    }
                    <ServicesRenderer
                        // @ts-ignore
                        services={Object.keys(requestedServices).filter((s) => includesManualGrants(requestedServices, manualGrants) ? !manualGrants.includes(s as AnyService) : true).reduce((prev, current) => {
                            return {
                                ...prev,
                                [current]: requestedServices[current as AnyService]
                            }
                        }, {})}
                        accounts={accounts}
                        business={business}
                        grantStatus={grantStatus}
                        inviteID={inviteID}
                        loading={loading}
                        serviceConfig={serviceConfig}
                        setAccounts={setAccounts}
                        setServiceConfig={setServiceConfig}
                    />
                </Box>
                : null
            }

            {includesManualGrants(requestedServices, manualGrants)
                ? <Box className='flex fdc' style={{ gap: 5 }} mt={10}>
                    {includesAutomaticGrants(requestedServices, manualGrants)
                        ? <div style={{ marginBottom: 10 }}>
                            <Text fz={"md"} fw={500}>{lang.assetSelection.manualHeader.title[l]}</Text>
                            <Text fz={"xs"} c="dimmed">{lang.assetSelection.manualHeader.subtitle[l]}</Text>
                        </div>
                        : null
                    }
                    <ServicesRenderer
                        // @ts-ignore
                        services={Object.keys(requestedServices).filter((s) => manualGrants.includes(s as AnyService)).reduce((prev, current) => {
                            return {
                                ...prev,
                                [current]: requestedServices[current as AnyService]
                            }
                        }, {})}
                        accounts={accounts}
                        business={business}
                        grantStatus={grantStatus}
                        inviteID={inviteID}
                        loading={loading}
                        serviceConfig={serviceConfig}
                        setAccounts={setAccounts}
                        setServiceConfig={setServiceConfig}
                    />
                </Box>
                : null
            }
            <Button color={theme.color || "dark"} loading={loading} mt={10} fullWidth disabled={!Object.keys(serviceConfig).length || !Object.keys(serviceConfig).map((s) => serviceConfig[s].length).find((s) => s !== 0)} onClick={() => {
                setError("")
                setErrorList([])
                let newErrorList: string[] = []
                Object.keys(requestedServices).filter((s) => {
                    if (!serviceConfig[s]?.length && !grantStatus[s as AnyService]?.granted) {
                        newErrorList.push(`Please select an account for ${s}`)
                        return true
                    }
                    return false
                }).length
                if (newErrorList.length) return setErrorList(newErrorList);
                if (allAutomaticGranted()) {
                    return next()
                }
                sendGrantRequest(invite, serviceConfig, "Meta").then(() => {
                    if (allAutomaticGranted() && Object.keys(requestedServices).filter((a) => manualGrants.includes(a as AnyService)).length && !error && !errorList?.length && !Object.keys(requestedServices).filter((s) => !serviceConfig[s]?.length && !grantsStatus[s as AnyService]?.granted).length) {
                        next()
                    }
                })
            }}>{lang.grantButton[l]}</Button>

            {error
                ? <Error mt={10}>{error}</Error>
                : <ErrorList mt={10} errors={errorList} />
            }
        </>
    )
}

const Step = ({ children, i, title, activeStep, setActiveStep, setHeight, buttonDisabled }: PropsWithChildren & { i: number, buttonDisabled?: boolean, title: string, activeStep: number, setHeight: (h: number) => void, setActiveStep: (s: number) => void }) => {
    const { height, ref } = useElementSize()
    const theme = useContext(ThemeContext)
    const { lang: l } = useContext(InviteClientContext)
    useEffect(() => {
        if (activeStep === i) {
            setHeight(height)
        }
    }, [activeStep, height])

    return (
        <Box w={"100%"} ref={ref} className='flex fdc' style={{ gap: 5, position: "absolute", transition: "all .3s", transform: `translateX(${activeStep === i ? 0 : (i > activeStep ? 1000 : -1000)}px)` }}>
            <Box className='flex aic' style={{ gap: 10 }}>
                {!!activeStep
                    ? <ActionIcon variant="light" color="gray" size={"md"} radius={100} onClick={() => setActiveStep(activeStep - 1)}>
                        <ArrowLeft size={18} />
                    </ActionIcon>
                    : null
                }

                {/* {React.cloneElement(circleNumberIcons[i], { size: 20 })} */}
                <Text fw={600} lh={1}>{title}</Text>
            </Box>
            <Box>
                {children}
            </Box>
            {!buttonDisabled
                ? <Button color={theme.color || "dark"} mt={10} fullWidth onClick={() => setActiveStep(activeStep + 1)}>{lang.nextButton[l]}</Button>
                : null
            }

        </Box>
    )
}

const Instruction = ({ children, index, extend }: PropsWithChildren & { index: number, extend?: boolean }) => {
    return (
        <Box style={{ gap: 10 }} className='flex'>
            <Box className='flex aic fdc'>
                <Box mih={25} w={25} className='flex aic jcc' h={25} style={{ borderRadius: 100, border: "2px dashed var(--mantine-color-gray-4)" }} bg={"gray.2"}>
                    <Text lh={1} fw={600} c="dimmed">{index}</Text>
                </Box>
                {extend
                    ? <Divider h={"calc(100% + 20px)"} style={{ borderWidth: 2 }} orientation='vertical' mx={"auto"} />
                    : null
                }
            </Box>
            {children}
        </Box>
    )
}

export const MetaPlatformWideGuide = ({ invite }: PlatformWideGuideProps) => {
    const [step, setStep] = useState(0)
    const { grantsStatus, setGrantsStatus } = useGrantStatus(invite, "Meta", false)
    // const [grantsStatus, setGrantsStatus] = useState<GrantsStatus | undefined>(undefined)
    const [agencyBusinessID, setAgencyBusinessID] = useState<string | undefined>(undefined)
    const [serviceConfig, setServiceConfig] = useState<BaseServiceConfig>({})
    // @ts-ignore
    const [accounts, setAccounts] = useState<Record<AnyService, Array<{ account_id: string, id: string }>>>({})
    const [height, setHeight] = useState(0)

    const manualGrants = useContext(ManualGrantsContext)

    const { lang: l } = useContext(InviteClientContext)

    console.log(serviceConfig)
    useEffect(() => {
        api.get<string>("/invite/public/details/meta/partner-id", {
            inviteID: invite.id,
            service: "Meta Ads" as AnyService
        }).then((res) => {
            setAgencyBusinessID(res.data)
        })
    }, [])

    console.log("Grant status current:", grantsStatus)

    const anyAutomaticServicesGranted = Object.keys(grantsStatus).filter((s) => !manualGrants.includes(s as AnyService) && grantsStatus[s as AnyService]?.granted).length

    if (!invite.creds?.Meta || !invite.creds.Meta.business?.id) {
        return <Box pt={30} pb={20} className='flex aic jcc fdc' style={{ gap: 0 }}>
            <InfoCircle color='var(--mantine-color-dimmed)' size={20} />
            <Text c={"dimmed"} fw={500} fz={"md"}>{lang.signIn[l]}</Text>
        </Box>
    }

    return (
        <>
            <Divider mt={12} opacity={.5} />
            <Box mt={20}>
                <div className='flex fdc' style={{ position: "relative", height: height, transition: "all .3s" }}>
                    <Step setActiveStep={setStep} setHeight={setHeight} buttonDisabled activeStep={step} i={0} title={lang.assetSelection.header.title[l]}>
                        <Text fz={"sm"}>{lang.assetSelection.header.subtitle[l]}</Text>
                        <AssetsSelector
                            grantStatus={grantsStatus}
                            next={() => setStep(s => s + 1)}
                            inviteID={invite.id}
                            // @ts-ignore
                            business={invite.creds.Meta.business}
                            serviceConfig={serviceConfig}
                            setServiceConfig={(s: BaseServiceConfig) => setServiceConfig((prev) => ({ ...prev, ...s }))}
                            requestedServices={invite.requestedAccounts["Meta"]}
                            accounts={accounts}
                            setAccounts={(a, s) => setAccounts(prev => ({ ...prev, [s]: a }))}
                        />
                    </Step>
                    <Step setActiveStep={setStep} setHeight={setHeight} activeStep={step} i={1} title={lang.navigation.header[l]}>
                        <InstructionalImageRenderer path="/images/invite/guides/meta/2.gif" />
                        <Box mt={20}>
                            <Instruction extend index={1}>
                                {/* @ts-ignore */}
                                <Text fz="sm" mb={20}>{lang.navigation.step1[l]} <InlinePageLink href={`https://business.facebook.com/settings/partners/?business_id=${invite.creds.Meta.business.id as string}`} newTab>Meta Business Suite</InlinePageLink></Text>
                            </Instruction>
                            <Instruction extend index={2}>
                                <Text fz="sm" mb={20}>{lang.navigation.step2[l]}</Text>
                            </Instruction>
                            <Instruction index={3}>
                                <Text fz="sm" mb={0}>{lang.navigation.step3[l]}</Text>
                            </Instruction>
                        </Box>
                        {/* @ts-ignore */}
                        {/* <Link target='_blank' href={`https://business.facebook.com/settings/partners/?business_id=${invite.creds.Meta.business.id as string}`}>
                            <Button onClick={() => setStep((s) => s + 1)} fullWidth mt={10} variant="outline" rightSection={<ExternalLink size={20} />}>Go to Business Suite</Button>
                        </Link> */}
                    </Step>
                    <Step buttonDisabled setActiveStep={setStep} setHeight={setHeight} activeStep={step} i={2} title={anyAutomaticServicesGranted ? lang.share.header[l] : lang.addPartner.header[l]}>
                        {/* If any automatic services have been granted we'll show the guide for exisitng partners */}
                        {anyAutomaticServicesGranted
                            ? <InstructionalImageRenderer path="/images/invite/guides/meta/4.gif" />
                            : <InstructionalImageRenderer path="/images/invite/guides/meta/3.gif" />
                        }
                        {/* <InstructionalImageRenderer path="/images/invite/guides/meta/3.gif" /> */}
                        <Box mt={20}>
                            {anyAutomaticServicesGranted
                                ? <>
                                    <Instruction extend index={1}>
                                        <Text mb={15} fz="sm">{lang.share.step1[l]}</Text>
                                    </Instruction>
                                </>
                                : <>
                                    <Instruction extend index={1}>
                                        <Box mb={20}>
                                            <Text mt={0} fz="sm">{lang.addPartner.extraStep.navigateToBox[l]}</Text>
                                            <Text mt={10} fz="sm">{lang.addPartner.extraStep.typeParterID[l]}</Text>
                                            <CopyBox mt={5} value={agencyBusinessID || ""} />

                                        </Box>

                                    </Instruction>
                                </>
                            }

                        </Box>
                        <Instruction index={2}>
                            <Box w={"100%"}>
                                <Text fz="sm">{lang.share.step2[l]} <br /><strong>{lang.share.selectAssetsPropt[l]}</strong></Text>
                                {step === 2
                                    ? <GrantStatuses
                                        accounts={accounts}
                                        requestedServices={invite.requestedAccounts["Meta"]}
                                        serviceConfig={serviceConfig}
                                    />
                                    : null
                                }

                            </Box>
                        </Instruction>
                        {/* <InstructionalImageRenderer path='/images/invite/guides/meta/1.png' /> */}
                    </Step>
                    {/* <Step setActiveStep={setStep} setHeight={setHeight} activeStep={step} i={3} title='Select Assets'>
                        <Text fz="sm">You should now see a list of assets you can grant access to. <br /><strong>Select the following assets:</strong></Text>
                        {step === 3
                            ? <GrantStatuses
                                accounts={accounts}
                                requestedServices={invite.requestedAccounts["Meta"]}
                                serviceConfig={serviceConfig}
                            />
                            : null
                        }

                    </Step> */}
                </div>
            </Box>
        </>
    )
}
