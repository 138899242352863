import { useGrantStatus } from "@/hooks/useGrantStatus"
import { roleValueToLabel } from "@/static/roles"
import api from "@/utils/api"
import { InviteContext } from "@/utils/context/Invite.context"
import { ManualGrantsContext } from "@/utils/context/ManualGrants.context"
import { PlatformsContext } from "@/utils/context/Platforms.context"
import { ThemeContext } from "@/utils/context/Theme.context"
import { includesAutomaticGrants, includesManualGrants } from "@/utils/helpers/getRequestedServicesMetadata"
import { Badge, Box, Button, Collapse, Divider, HoverCard, Loader, Portal, Skeleton, Text, Tooltip, Image as MImage, ActionIcon, Paper, Modal } from "@mantine/core"
import { useHover, useMediaQuery } from "@mantine/hooks"
import Image from "next/image"
import { useContext, useEffect, useMemo, useState } from "react"
import { AlertCircle, Bulb, InfoCircle, InfoSquare, Lock, Video, X } from "tabler-icons-react"
import { AccountTypes, AnyService, BaseServiceConfig, GoogleServices, MetaServices, PinterestServices, RequestedServices, ServiceConfig, ShopifyServices, StaticKnowledgebaseError, TwitterServices } from "../../../../types/global"
import Error, { ErrorList } from "../Error"
import { InlinePageLink } from "../InlineLink"
import ManualAccessGrantsListener from "./ManualAccessGrantsListener"
import { AmazonAdsInstructions, ChannableDashboardInstructions, CookiebotInstructions, GoogleBusinessProfileInstructions, GoogleSearchConsoleInstructions, HubSpotPartnerInstructions, InstagramInstructions, KlaviyoAccountInstructions, LightspeedCSeriesInstructions, LinkedInAdsInstructions, LinkedInCompanyPageInstructions, MailchimpManualInstructions, ManualAccessInstructionsRendererProps, MetaAdsInstructions, MetaPixelsManualInstructions, MicrosoftAdsInstructions, MicrosoftClarityInstructions, PinterestAdsInstructions, ProfitMetricsPartnerInstructions, ShopifyCollaboratorInstructions, SnapchatAdsInstructions, SnapchatBusinessInstructions, TwitterAdsInstructions, TwitterDelagateAccountInstructions, WordpressSiteInstructions, YouTubeStudioInstructions } from "./ManualInstructions"
import PlatformAuthButton from "./PlatformAuthButton"
import { GrantedChip, Selector } from "./ServiceConfigSelector"
import lang from '@/../public/translations/invite.json'
import { InviteClientContext } from "@/utils/context/InviteClient.context"
import { useGrantRequest } from "@/utils/helpers/useGrantRequest"

export type GrantsStatus = {
    [key in AnyService]?: {
        granted: boolean
    }
}


const ServiceGrant = ({ isManual, isGranted, loading, serviceConfigs, service, account, id, isLogged, apiRoutes, isPreview, updateServiceConfig, updateGrantsStatus }: {
    isManual: boolean,
    isGranted: boolean,
    loading: boolean,
    serviceConfigs: BaseServiceConfig,
    service: AnyService,
    account: AccountTypes,
    id: string,
    isLogged: boolean,
    apiRoutes: { [key in string]: string },
    updateServiceConfig: (newServiceConfig: any) => void,
    updateGrantsStatus: (newGrants: GrantsStatus) => void,
    isPreview: boolean
}) => {
    const platforms = useContext(PlatformsContext)
    const platformService = platforms.find((p) => p.platform === account)?.services.find((s) => s.name === service)
    const invite = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const [tutExpanded, setTutExpanded] = useState((platformService?.requiresAuth && !isLogged) ? false : (isGranted || isPreview ? false : true))
    const vertical = useMediaQuery("(max-width: 460px)")
    const { lang: l } = useContext(InviteClientContext)
    const { ref } = useHover()
    useEffect(() => {
        if (isGranted) {
            setTutExpanded(false)
        }
    }, [isGranted])

    let assetSelector = null
    if (apiRoutes && !isPreview) {
        assetSelector = <Selector
            key={service}
            service={service}
            apiRoute={apiRoutes[service]}
            serviceConfig={serviceConfigs}
            setServiceConfigs={updateServiceConfig}
            inviteID={id}
            businessName={invite.creds ? (invite.creds[account]?.business?.name || "") : ""}
        />
    }
    const manualAccessProps = {
        isLogged: isLogged,
        serviceConfigs: serviceConfigs as ServiceConfig<AnyService>,
        updateGrantsStatus,
        updateServiceConfig,
        isGranted,
        assetSelector: isLogged && !isGranted ? assetSelector : null
    } as ManualAccessInstructionsRendererProps

    return (
        <Box ref={ref} style={{ borderRadius: 10 }}>
            {/* {isManual && !isPreview && service === "Google Search Console"
                ? <Collapse in={isGranted ? false : (!!(serviceConfigs[service]?.length))}>
                    <ManualAccessGrantsListener setParentGranted={(val) => {
                        if (!updateGrantsStatus) return
                        updateGrantsStatus({
                            [service]: {
                                granted: val
                            }
                        })
                    }} readOnlyGranted={isGranted} service={service as AnyService} accountType={account} resources={serviceConfigs[service]} inviteID={id} />
                </Collapse>
                : null
            } */}
            <div key={service} className={`flex ${vertical ? "" : ""} jcsb`} style={{ width: "100%", flexDirection: vertical ? "column" : "row", padding: isManual ? 0 : 0, paddingBottom: isGranted && isManual ? 12 : 0 }}>
                <Box h={"fit-content"} className='flex' style={{ gap: 7 }}>
                    {loading && !isGranted
                        ? <div className="flex aic jcc" style={{ width: 25, height: 25 }}>
                            <Loader size={"xs"} type="oval" color={theme.theme === "dark" ? "#fff" : undefined} />
                        </div>
                        : <Box bg={"#fff"} className='flex aic jcc' style={{ borderRadius: 100, width: 25, height: 25, overflow: "hidden" }}>
                            {/* <Image width={25} height={25} alt={`${service} logo`} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#f1f1f1", padding: 2 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} /> */}
                            <Image width={18} height={18} alt={`${service} logo`} style={{ borderRadius: 0, objectFit: "contain", backgroundColor: "#fff", padding: 0 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} />
                        </Box>
                    }
                    <div>
                        {!isPreview && invite.requestedAccounts[account][service].optional
                            ? <Box w="fit-content" bg={"orange.1"} style={{ borderRadius: 4, gap: 3 }} className="flex aic" px={4} py={2}>
                                <InfoSquare strokeWidth={2.5} size={12} color="var(--mantine-color-orange-6)" />
                                <Text lh={0.8} fz={10} c="orange.6" fw={700}>Optional</Text>
                            </Box>
                            : null
                        }
                        <Text fw={400} fz={"sm"}>{service}</Text>
                        {service === "Google Ads" && !isPreview && !invite.requestedAccounts[account][service].accessLevel // This indicates the agency has requested MCC access to the users account
                            ? <HoverCard position="left" withArrow>
                                <HoverCard.Target>
                                    <Box mt={1.5} className="flex aic" style={{ gap: 5 }}>
                                        <InfoCircle size={12} color={"var(--mantine-color-dimmed)"} />
                                        <Text lh={1} fz="xs" c="dimmed">Manager Access</Text>
                                    </Box>
                                </HoverCard.Target>
                                <HoverCard.Dropdown maw={300}>
                                    <Text fz="sm">Your account will be added to {invite.agency.name}'s Google Ads MCC account. <InlinePageLink newTab href="https://ads.google.com/intl/en_uk/home/tools/manager-accounts/">Learn More</InlinePageLink></Text>
                                </HoverCard.Dropdown>
                            </HoverCard>
                            : <Text fz={"xs"} c="dimmed">{!isPreview ? roleValueToLabel(service, invite.requestedAccounts[account][service].accessLevel, platforms) : ""}</Text>
                        }

                    </div>
                </Box>
                {
                    isGranted && !isPreview
                        ? <GrantedChip skipped={invite.requestedAccounts[account][service].skipped} />
                        : null
                }
                {
                    isLogged && !isGranted && !isManual // For manual services, we'll show the selector within the guide
                        // @ts-ignore
                        ? <>{assetSelector}</>
                        : null
                }
            </div>
            {isManual && !isGranted && <Divider variant={"dashed"} my={12} />}
            {isManual && !isGranted && <Box style={{ position: "relative" }}>
                <Box className="flex aic" style={{ gap: 10 }} px={12}>
                    <Bulb size={20} />
                    <Box >
                        <Text fz="sm" fw={600}>{lang.manualGuideHeader.title[l]}</Text>
                        <Text c="dimmed" lh={1} fz="xs">{lang.manualGuideHeader.subtitle[l]}</Text>
                    </Box>
                    <Tooltip disabled={!isPreview} withArrow label="Guides are dynamic, so can't be displayed in preview mode">
                        <Button disabled={isPreview || (platformService?.requiresAuth && !isLogged)} style={{ marginLeft: "auto" }} size={"compact-sm"} variant={"subtle"} onClick={() => setTutExpanded(!tutExpanded)} color={theme.color === "dark" && theme.theme === "dark" ? "#fff" : (theme.color || "dark")}>{tutExpanded ? (lang.manualGuideHeader.hideButton.shown[l]) : (lang.manualGuideHeader.hideButton.hidden[l])}</Button>
                    </Tooltip>
                </Box>
                {platformService?.requiresAuth && !isLogged
                    ? <Box className="flex jcc aic" w="100%" h={"100%"} bg={"radial-gradient(circle, rgba(255,255,255,.9) 70%, rgba(33,33,33,0) 100%)"} style={{ position: "absolute", top: 0, gap: 8 }}>
                        <Lock size={14} color="var(--mantine-color-dimmed)" />
                        <Text fz="sm" c="dimmed">{lang.manualNoAuth.signIn[l]} {account} {lang.manualNoAuth.follow[l]}</Text>
                    </Box>
                    : null
                }
            </Box>}

            <Collapse in={(platformService?.requiresAuth && !isLogged) ? false : (!!isManual)}>
                <div style={{ padding: 0, paddingTop: 0 }}>
                    <Collapse in={tutExpanded} mt={12}>
                        {!isPreview
                            ? < Box style={{ borderRadius: 10 }} bg={isManual && !isGranted ? (theme.theme === "dark" ? "#1A202C" : "gray.0") : "none"} pt={0} p={isManual && !isGranted ? 12 : 0}>
                                {service as GoogleServices === "Google Search Console"
                                    ? <GoogleSearchConsoleInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton requestedAccounts={invite.requestedAccounts} account="Google" />}
                                    />
                                    : null
                                }
                                {service as GoogleServices === "YouTube Studio"
                                    ? <YouTubeStudioInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton requestedAccounts={invite.requestedAccounts} account="Google" />}
                                    />
                                    : null
                                }
                                {service as ShopifyServices === "Shopify Store Collaborator"
                                    ? <ShopifyCollaboratorInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service as TwitterServices === "Twitter Delegate Account"
                                    ? <TwitterDelagateAccountInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service as TwitterServices === "Twitter Ads"
                                    ? <TwitterAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service as MetaServices === "Instagram"
                                    ? <InstagramInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton requestedAccounts={invite.requestedAccounts} account="Meta" />}
                                    />
                                    : null
                                }
                                {service as PinterestServices === "Pinterest Ads"
                                    ? <PinterestAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Meta Ads"
                                    ? <MetaAdsInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton requestedAccounts={invite.requestedAccounts} account="Meta" />}
                                    />
                                    : null
                                }
                                {service === "Amazon Ads"
                                    ? <AmazonAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Snapchat Ads"
                                    ? <SnapchatAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Snapchat Business"
                                    ? <SnapchatBusinessInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "HubSpot Partner"
                                    ? <HubSpotPartnerInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "ProfitMetrics Partner"
                                    ? <ProfitMetricsPartnerInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "LinkedIn Company Page"
                                    ? <LinkedInCompanyPageInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "LinkedIn Ads"
                                    ? <LinkedInAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Klaviyo Account"
                                    ? <KlaviyoAccountInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Google Business Profile"
                                    ? <GoogleBusinessProfileInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Meta Pixels"
                                    ? <MetaPixelsManualInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Mailchimp Dashboard"
                                    ? <MailchimpManualInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "WordPress Site"
                                    ? <WordpressSiteInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Lightspeed C-Series"
                                    ? <LightspeedCSeriesInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Microsoft Clarity"
                                    ? <MicrosoftClarityInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Microsoft Ads"
                                    ? <MicrosoftAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Channable Dashboard"
                                    ? <ChannableDashboardInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Cookiebot"
                                    ? <CookiebotInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                            </Box>

                            : null
                        }
                    </Collapse>
                </div>
            </Collapse>

        </Box >
    )
}

const AccountsRenderer = ({ services, type, account, grantsStatus, updateServiceConfig, isLogged, isPreview, id, loading, serviceConfigs, apiRoutes, updateGrantsStatus }: {
    services: Record<AnyService, any>,
    type: "manual" | "auto",
    account: AccountTypes,
    id: string,
    isLogged: boolean,
    apiRoutes: { [key in string]: string },
    updateServiceConfig: (newServiceConfig: any) => void,
    updateGrantsStatus: (newGrants: GrantsStatus) => void,
    grantsStatus: GrantsStatus,
    loading: boolean,
    serviceConfigs: BaseServiceConfig,
    isPreview: boolean
}) => {
    console.log(services)
    const platforms = useContext(PlatformsContext)
    return (
        <>
            {Object.keys(services).sort().map((service: unknown, i) => {
                const isManual = !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual
                const isGranted = !!grantsStatus[service as AnyService]?.granted
                if (type === "auto" && isManual) return null
                if (type === "manual" && !isManual) return null

                // Snapchat Ads includes access to Snapchat Business, so we won't render Snapchat Business if Ads is included in the request 
                if ((service as AnyService) === "Snapchat Business" && Object.keys(services).includes("Snapchat Ads")) return null

                return (
                    <ServiceGrant
                        updateServiceConfig={updateServiceConfig}
                        account={account}
                        // details={details}
                        isGranted={isGranted}
                        isManual={isManual}
                        isLogged={isLogged}
                        id={id}
                        loading={loading}
                        service={service as AnyService}
                        serviceConfigs={serviceConfigs}
                        apiRoutes={apiRoutes}
                        updateGrantsStatus={updateGrantsStatus}
                        isPreview={isPreview}
                    />
                )
            })}
        </>
    )
}

const KnowledgebaseErrorsPopupGuide = ({ errors, clearErrors }: { errors: StaticKnowledgebaseError[], clearErrors: (retry: boolean) => void }) => {
    const [open, setOpen] = useState(false)
    const [videoGuideOpen, setVideoGuideOpen] = useState(false)
    const error = useMemo(() => errors[0], [errors])
    const theme = useContext(ThemeContext)
    const { lang: l } = useContext(InviteClientContext)
    useEffect(() => {
        const el = window.document.getElementById("root_invite_content")!
        const containerEl = window.document.getElementById("root_invite_continer")!
        if (!el || !containerEl) return;
        if (!open) {
            el.classList.remove("rootInviteContentSetback")
            containerEl.classList.remove("dimTopBorder")
        } else {
            el.classList.add("rootInviteContentSetback")
            containerEl.classList.add("dimTopBorder")
        }
    }, [open])

    useEffect(() => {
        if (!errors.length) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [errors])

    const close = (retry?: boolean) => {
        setOpen(false)
        setTimeout(() => {
            clearErrors(!!retry)
        }, 300);
    }

    return (
        <>
            <Modal
                opened={videoGuideOpen}
                onClose={() => setVideoGuideOpen(false)}
                styles={{
                    body: {
                        padding: 0,
                        paddingTop: 8
                    }
                }}
                centered
                size={"xl"}
                title={<Box>
                    <Text fw={500}>{lang.knowledgebase.videoModal.title[l]}</Text>
                    <Text fz="sm" c="dimmed">{lang.knowledgebase.videoModal.subtitle[l]}</Text>
                </Box>}
            >
                <video controls style={{ width: "100%", display: "block" }} src={`/images/popupGuides/${error?.videoGuide}`}></video>
                {/* <Button variant="default" fullWidth style={{ borderRadius: 0 }}>Dismiss</Button> */}
            </Modal>
            <Box style={{
                position: "absolute",
                width: "100%", height: "100%", bottom: 0, left: 0,
                zIndex: 200,
                transform: `translateY(${open ? 0 : 100}%)`,
                transition: "all .3s",

            }} className="flex fdc">
                <Box style={{ flex: 1, position: "relative", backdropFilter: "blur(2px)", opacity: open ? 1 : 0, transition: "all .15s" }} onClick={() => close()} />
                <Box style={{
                    width: "100%", height: "85%", bottom: 0, left: 0, background: "#fff", zIndex: 200,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    borderBottomLeftRadius: 32,
                    borderBottomRightRadius: 32,
                    boxShadow: "0px 0px 5px rgba(0,0,0,.1)",
                    borderTop: `3px solid var(--mantine-color-${"red"}-6)`,
                }} p="lg" className="flex fdc">
                    {error
                        ? <Box className="flex fdc" style={{ flex: 1, overflow: "hidden" }}>
                            <Box className="flex aic jcsb">
                                <Box>
                                    <Badge radius={5} variant="light" color="red">1 issue</Badge>
                                    <Box mt={10} className='flex aic' style={{ gap: 10 }}>
                                        <MImage src={`/images/logos/${error.service.replaceAll(" ", "_").toLowerCase()}.png`} style={{ width: 20, height: 20 }} />
                                        <Box>
                                            <Text lh={1} fw={600} fz="xl">{error.service}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                <ActionIcon onClick={() => close()} ml={"auto"} variant="subtle" color="gray"><X size={20} /></ActionIcon>
                            </Box>
                            <Paper mt={15} style={{ borderLeft: `3px solid var(--mantine-color-red-6)` }} py={8} px="sm" shadow="xs">
                                <Box className="flex aic" style={{ gap: 8 }}>
                                    <AlertCircle size={16} />
                                    <Text fw={500} fz="sm">{error.message}</Text>

                                </Box>
                            </Paper>
                            {/* <Divider opacity={.3} mt={15} mb={10} /> */}
                            <Box style={{ flex: 1, gap: 0, overflowY: "auto" }} pr={5} pt={10} pb={15} className="flex fdc verticalOverflow">
                                {error.videoGuide
                                    ? <Button onClick={() => setVideoGuideOpen(true)} mb={10} w="fit-content" leftSection={<Video size={18} />} color="blue" size="compact-xs" variant="subtle" radius={5}>{lang.knowledgebase.videoModal.button[l]}</Button>
                                    : null
                                }

                                {/* <Box>
                                    <Divider label="or" labelPosition="center" />
                                </Box> */}
                                <Box className="flex fdc" style={{ gap: 20 }}>
                                    {error.resolutionSteps.map((step, i) => {
                                        return (
                                            <Box className='flex' style={{ gap: 10 }} >
                                                {error.resolutionSteps.length > 1
                                                    ? <Box bg={"gray.1"} style={{ borderRadius: 5, width: 25, minWidth: 25, height: 25, fontWeight: "600", fontSize: 13 }} className="flex aic jcc fdc">
                                                        {i + 1}
                                                    </Box>
                                                    : null
                                                }

                                                <Box>
                                                    <Text fw={600}>{step.title[l]}</Text>
                                                    <Text mt={4} style={{ lineHeight: 1.2 }} fz="sm" dangerouslySetInnerHTML={{ __html: step.content[l] }}></Text>
                                                    {/* {step.video
                                                        ? <video loop style={{ maxWidth: 275, borderRadius: 10, boxShadow: "0px 0px 5px rgba(0,0,0,.1)" }} autoPlay muted src={`/images/popupGuides/${step.video}`} />
                                                        : null
                                                    } */}
                                                </Box>

                                            </Box>
                                        )
                                    })}

                                </Box>
                                <Box mt={15}>
                                    {error.canBeResolved
                                        ? <Button onClick={() => {
                                            close(true)
                                        }} fullWidth mt={0} color={theme.color}>{lang.knowledgebase.buttons.tryAgain[l]}</Button>
                                        : null
                                    }

                                    <Button onClick={() => close()} fullWidth mt={5} variant="default" color={theme.color}>{lang.knowledgebase.buttons.dismiss[l]}</Button>
                                </Box>
                                {/* <Badge size="sm" color={theme.color} variant="light" radius={5} style={{ borderLeft: `3px solid var(--mantine-color-${theme.color}-6)` }}>Solution</Badge> */}
                            </Box>
                        </Box>
                        : null
                    }

                    {/* <Text fw={600} fz={22}>Fix {error.service}</Text> */}
                    {/* <Text></Text>
                    <Text c="dimmed" pt={40} ta={"center"}>Resolution guide will go here</Text> */}
                </Box>
            </Box>
        </>
    )
}

export const ServiceGrantsContainer = ({ services, account, isLogged, id, grantURI, apiRoutes, setGranted, isPreview }: {
    services: RequestedServices,
    account: AccountTypes,
    isLogged: boolean,
    id: string,
    grantURI: string,
    apiRoutes: { [key in string]: string },
    setGranted: (granted: boolean) => void,
    isPreview: boolean
}) => {
    const invite = useContext(InviteContext)
    const { lang: l } = useContext(InviteClientContext)
    const [serviceConfigs, setServiceConfigs] = useState<BaseServiceConfig>({})
    // const [loading, setLoading] = useState(false)
    const { grantsStatus, setGrantsStatus } = useGrantStatus(invite, account, isPreview)
    // const [error, setError] = useState("")
    // const [errorList, setErrorList] = useState<Array<string>>([])
    const manualGrants = useContext(ManualGrantsContext)

    const theme = useContext(ThemeContext)
    const [testOpen, setTestOpen] = useState(false)
    const {
        error,
        errorList,
        knowledgebaseErrors,
        loading,
        sendGrantRequest,
        setError,
        setErrorList,
        setKnowledgebaseErrors,
        setLoading
    } = useGrantRequest()

    const platforms = useContext(PlatformsContext)

    useEffect(() => {
        setError("")
        setErrorList([])
    }, [account])

    const updateServiceConfig = (newConfig: BaseServiceConfig) => {
        setServiceConfigs((prev) => ({ ...prev, ...newConfig }))
    }

    const isFullyGranted = () => {
        return grantsStatus === undefined ? false : !Object.keys(grantsStatus).filter((service) => !grantsStatus[service as MetaServices | GoogleServices]?.granted).length
    }

    const isAutomaticFullyGranted = useMemo(() => {
        return grantsStatus === undefined ? false : !(Object.keys(grantsStatus).filter((service) => !manualGrants.includes(service as MetaServices | GoogleServices)).filter((service) => !grantsStatus[service as MetaServices | GoogleServices]?.granted).length)
    }, [grantsStatus, manualGrants])

    useEffect(() => {
        setGranted(isFullyGranted())
    }, [grantsStatus])

    console.log("Inc man grants:", includesManualGrants(services, manualGrants), services, manualGrants)

    if (!grantsStatus) {
        if (!services) return null;
        console.log("PASSED SERVICES", Object.keys(services).length)
        return <div className='flex fdc' style={{ gap: 10, marginTop: 15 }}>
            {new Array(Object.keys(services).length).fill(0).map(() => {
                return <Skeleton style={{ borderRadius: 10, width: "100%", height: 64 }} />
            })}
        </div>
    }

    return (
        <>
            <Portal target={"#root_invite_continer"}>
                {/*  @ts-ignore */}
                <KnowledgebaseErrorsPopupGuide clearErrors={(retry: boolean) => {
                    setKnowledgebaseErrors([])
                    if (retry) {
                        sendGrantRequest(invite, serviceConfigs, account)
                    }
                }} errors={knowledgebaseErrors} />
            </Portal>
            <div className='flex fdc' style={{ gap: 15, marginTop: 15 }}>
                {/* @ts-ignore */}
                {/* Automatic Grants */}
                {includesAutomaticGrants(services, manualGrants)
                    ? <Box>
                        {includesManualGrants(services, manualGrants)
                            ? <div style={{ marginBottom: 10 }}>
                                <Text fz={"md"} fw={500}>{lang.automaticGuidesHeader.title[l]}</Text>
                                <Text fz={"xs"} c="dimmed">{lang.automaticGuidesHeader.subtitle[l]}</Text>
                            </div>
                            : null
                        }
                        <Box bg={theme.theme === "dark" ? "#1A202C" : "gray.0"} style={{ borderRadius: 10, gap: 15, overflow: "hidden" }} className="flex fdc" p="sm">
                            <AccountsRenderer
                                isPreview={isPreview}
                                updateGrantsStatus={(newGrants) => {
                                    setGrantsStatus((prev) => ({
                                        ...prev,
                                        ...newGrants
                                    }))
                                }}
                                updateServiceConfig={updateServiceConfig}
                                account={account}
                                // details={details}
                                isLogged={isLogged}
                                id={id}
                                loading={loading}
                                serviceConfigs={serviceConfigs}
                                apiRoutes={apiRoutes}
                                grantsStatus={grantsStatus}
                                services={services}
                                type="auto"
                            />
                            {isPreview || (isLogged && !isAutomaticFullyGranted)
                                // @ts-ignore
                                ? <Button fullWidth color={theme.color || "dark"} loading={loading} disabled={isPreview ||
                                    // !!(Object.keys(details.requestedAccounts[account]).filter((acc: string) => {
                                    // if (details.requestedAccounts[account][acc].granted || !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === acc)?.manual) {
                                    //     return false
                                    // }
                                    // return !(!!serviceConfigs[acc])
                                    // }).length)
                                    false
                                } onClick={async () => {
                                    // setTestOpen(true)
                                    if (invite.DEMO && invite.DEMO_update) {
                                        let newReqAccounts = { ...invite.requestedAccounts }
                                        for (let i = 0; i < Object.keys(invite.requestedAccounts[account]).length; i++) {
                                            const service = Object.keys(invite.requestedAccounts[account])[i] as AnyService
                                            newReqAccounts = {
                                                ...newReqAccounts,
                                                [account]: {
                                                    ...newReqAccounts[account],
                                                    [service]: {
                                                        ...newReqAccounts[account][service],
                                                        granted: true
                                                    }
                                                }
                                            }
                                            invite.DEMO_update({
                                                ...invite,
                                                requestedAccounts: {
                                                    ...newReqAccounts
                                                }
                                            })
                                            await new Promise<void>((res) => {
                                                setTimeout(() => {
                                                    res()
                                                }, 500);
                                            })
                                        }
                                        Object.keys(invite.requestedAccounts[account]).map((service) => {
                                        })
                                        return
                                    }

                                    sendGrantRequest(invite, serviceConfigs, account)
                                    // setLoading(true)
                                    // setError("")
                                    // setErrorList([])

                                    // const newServiceCongigs: BaseServiceConfig = {};

                                    // (Object.keys(serviceConfigs) as Array<AnyService>).map((service: AnyService) => {
                                    //     if (invite.requestedAccounts[account][service]?.granted || !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual) {
                                    //         return
                                    //     }
                                    //     newServiceCongigs[service] = serviceConfigs[service]
                                    // });

                                    // api.post(grantURI, {
                                    //     serviceConfig: newServiceCongigs,
                                    //     inviteID: id
                                    // }).then((res) => {
                                    //     if (res.error) {
                                    //         setLoading(false)
                                    //         return setError(res.msg)
                                    //     }

                                    //     const responseData = res.data as {
                                    //         grantsStatus: GrantsStatus,
                                    //         errors: Array<string>
                                    //     }
                                    //     setLoading(false)
                                    //     if (responseData.errors?.length) {
                                    //         setErrorList(responseData.errors)
                                    //     }
                                    // })
                                }}>{lang.grantButton[l]}</Button>
                                : null
                            }
                        </Box>
                    </Box>
                    : null
                }

                {error
                    ? <Error>{error}</Error>
                    : <ErrorList errors={errorList} />
                }
                {/* Manual Grants */}
                {includesManualGrants(services, manualGrants)
                    ? <>
                        <div style={{ marginTop: includesAutomaticGrants(services, manualGrants) ? 10 : 0 }}>
                            <Text fz={"md"} fw={500}>Manual</Text>
                            <Text fz={"xs"} c="dimmed">These services must be granted access to manually</Text>
                        </div>
                        <AccountsRenderer
                            isPreview={isPreview}
                            updateServiceConfig={updateServiceConfig}
                            account={account}
                            // details={details}
                            isLogged={isLogged}
                            id={id}
                            loading={false}
                            serviceConfigs={serviceConfigs}
                            apiRoutes={apiRoutes}
                            grantsStatus={grantsStatus}
                            services={services}
                            updateGrantsStatus={(newGrants) => {
                                setGrantsStatus((prev) => ({
                                    ...prev,
                                    ...newGrants
                                }))
                            }}
                            type="manual"
                        />
                    </>
                    : null
                }
            </div>
        </>
    )
}