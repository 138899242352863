import AccountsContainer from '@/components/invite/AccountsContainer'
import { ServiceGrantsContainer } from '@/components/invite/GrantsContainer'
import PlatformAuthButton from '@/components/invite/PlatformAuthButton'
import { MetaPlatformWideGuide, SwitchBusinessManagerAccount } from '@/components/invite/PlatformWideGuides'
import { ThankYouScreen } from '@/components/invite/ThankYouScreen'
import { Logo } from '@/components/Logo'
import { useGrantStatus } from '@/hooks/useGrantStatus'
import { InviteContext } from '@/utils/context/Invite.context'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import { ThemeContext } from '@/utils/context/Theme.context'
import parseCustomInviteHeader from '@/utils/helpers/parseCustomInviteHeader'
import { getGrantedStatus, useInviteGrantStatus } from '@/utils/helpers/useGranted'
import { alpha, Box, Button, Collapse, Image as MantineImage, Paper, Text, Tooltip, useMantineColorScheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { Edit, InfoCircle, Key } from 'tabler-icons-react'
import { AccountTypes, AnyService, Client, ClientLanguage, RequestedAccounts, Theme } from '../../../../../types/global'
import lang from '@/../public/translations/invite.json'
import Footer from '@/components/invite/Footer'
import InviteHeader from '@/components/invite/InviteHeader'
import AgencyLogo from '@/components/invite/Logo'
const serviceConfigAPIRoutes = {
    "Meta": {
        "Meta Ads": "/accounts/client/meta/ads/accounts",
        "Facebook Pages": "/accounts/client/meta/pages/accounts",
        "Instagram": "/accounts/client/meta/business/accounts",
        "Meta Pixels": "/accounts/client/meta/datasets/pixels",
        "Facebook Product Catalog": "/accounts/client/meta/catalog/catalogs"
    },
    "Google": {
        "Google Ads": "/accounts/client/google/ads/customers",
        "Google Ads MCC": "/accounts/client/google/ads/customers",
        "Google Analytics": "/accounts/client/google/analytics/accounts",
        "Google Tag Manager": "/accounts/client/google/tags-manager/accounts",
        "Google Merchant Center": "/accounts/client/google/merchant/accounts",
        "Google Business Profile": "/accounts/client/google/business-profile/accounts",
        "Google Search Console": "/accounts/client/google/search-console/resources",
        "Google DV360": "/accounts/client/google/dv360"
    }
} as Record<AccountTypes, Record<AnyService, string>>

const grantURIs = {
    "Meta": "/grant/facebook",
    "Google": "/grant/google"
} as { [key in AccountTypes]: string }

const MetaBusinessSwitchManager = () => {
    const [open, setOpen] = useState(false)
    const { lang: l } = useContext(InviteClientContext)
    return (
        <>
            <SwitchBusinessManagerAccount open={open} setOpen={setOpen} />
            <Text lh={1} fz="xs" c="dimmed">{lang.metaBusinessSwitcher.question[l]} <Text onClick={() => setOpen(true)} className='cp' lh={1} span fz="xs" c="blue" style={{ fontWeight: 600, position: "relative" }}>{lang.metaBusinessSwitcher.prompt[l]}</Text></Text>
        </>
    )
}

export const Account = ({ account, id, preview, setFocusedPlatform }: { account: AccountTypes, id: string, preview?: RequestedAccounts | boolean, setFocusedPlatform: (p: AccountTypes) => void }) => {
    const invite = useContext(InviteContext)
    const platforms = useContext(PlatformsContext)
    const { lang: l } = useContext(InviteClientContext)
    const [granted, setGranted] = useState(false)
    const vertical = useMediaQuery("(max-width: 460px)")
    const { grantsStatus } = useGrantStatus(invite, account, !!preview)
    // Some services are manual and don't require OAuth authentication. We'll hide the login button if all services are auth-less
    const [requiresAuth, setRequiresAuth] = useState<undefined | boolean>(undefined)

    useEffect(() => {
        if (!platforms || preview || !invite.requestedAccounts[account]) return;
        setRequiresAuth(!!Object.keys(invite.requestedAccounts[account]).filter((reqService) => {
            const accountPlatforms = platforms.find((p) => p.platform === account)
            const serviceRequiresAuth = accountPlatforms?.services.find((s) => s.name === reqService && s.requiresAuth)
            return serviceRequiresAuth
        }).length)
    }, [platforms, invite, preview, account, account])

    useEffect(() => {
        if (preview || !invite.requestedAccounts[account]) return
        setGranted(getGrantedStatus(invite, account))
    }, [invite, account])

    if (!preview && !invite?.requestedAccounts[account]) return null

    if (!Object.keys(grantsStatus).filter((g) => !grantsStatus[g as AnyService]?.granted).length && !preview) {
        const nextUngratned = Object.keys(invite.requestedAccounts).filter(a => Object.keys(invite.requestedAccounts[a as AccountTypes]).filter((s) => !invite.requestedAccounts[a as AccountTypes][s as AnyService].granted).length)[0] as AccountTypes | undefined
        return (
            <Box py={20} className='flex aic fdc' w="100%">
                <Paper w={50} h={50} bg={"gray.0"} style={{ borderRadius: 100 }} shadow='sm' className='flex aic jcc'>
                    <Image alt={`${account} logo`} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} width={33} height={33} style={{ width: 33, height: 33, objectFit: "contain" }} />
                </Paper>
                <Text mt={5} fw={600} fz="lg">{lang.serviceGranted.title[l]}</Text>
                <Text ta="center" maw={300} fz="sm" c="dimmed">{lang.serviceGranted.subtitle[l]} <Text span fz="sm" c="dimmed" fw={500}>{Object.keys(grantsStatus).join(", ")}</Text></Text>
                {nextUngratned
                    ? <Button onClick={() => setFocusedPlatform(nextUngratned)} variant="default" mt={10} leftSection={<Image width={18} height={18} style={{ objectFit: "contain" }} alt={`${nextUngratned} logo`} src={`/images/logos/${nextUngratned.toLowerCase().replaceAll(" ", "_")}.png`} />}>{lang.serviceGranted.continueButton[l]} {nextUngratned}</Button>
                    : null
                }

            </Box>
        )
    }

    return (
        <Box p={0}>
            <div className={`flex ${!vertical ? "aic" : ""} jcsb`} style={{ gap: 15, flexDirection: vertical ? "column" : "row" }}>
                <div className='flex aic' style={{ gap: 15 }}>
                    <Image alt={account} width={30} height={30} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#fff", padding: 5 }} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} />
                    <div>
                        <Text fz={"md"} fw={500}>{account} {lang.accounts[l]}</Text>
                        {!preview && account === "Meta" && invite.creds[account]?.business && !invite.DEMO
                            ? <MetaBusinessSwitchManager />
                            : null
                        }

                    </div>
                </div>
                {!granted && requiresAuth
                    ? <PlatformAuthButton requestedAccounts={invite.requestedAccounts} account={account} />
                    : null
                }

            </div>
            {!preview
                ? <>
                    <ServiceGrantsContainer
                        isPreview={false}
                        account={account}
                        setGranted={(granted: boolean) => setGranted(granted)}
                        id={id}
                        // details={details}
                        isLogged={!!(invite.creds?.[account]?.access_token)}
                        services={invite.requestedAccounts[account]}
                        grantURI={grantURIs[account]}
                        apiRoutes={serviceConfigAPIRoutes[account]}
                    />
                    {/* {account === "Meta"
                        ? <MetaPlatformWideGuide invite={invite} />
                        // @ts-ignore
                        : <ServiceGrantsContainer
                            account={account}
                            setGranted={(granted: boolean) => setGranted(granted)}
                            id={id}
                            // details={details}
                            isLogged={!!(invite.creds?.[account]?.access_token)}
                            services={invite.requestedAccounts[account]}
                            grantURI={grantURIs[account]}
                            apiRoutes={serviceConfigAPIRoutes[account]}
                        />
                    } */}
                </>
                : <>
                    {/* @ts-ignore */}
                    {preview[account]
                        // @ts-ignore
                        ? <ServiceGrantsContainer
                            isPreview={true}
                            account={account}
                            setGranted={() => void 0}
                            id={""}
                            // @ts-ignore
                            services={preview[account]}

                        />
                        : null
                    }
                </>
            }
        </Box>
    )
}

const Tutorial = () => {
    const theme = useContext(ThemeContext)
    const [expanded, setExpanded] = useState(true)
    const { lang: l } = useContext(InviteClientContext)
    const mobile = useMediaQuery("(max-width: 460px)")
    const vertical = useMediaQuery("(max-width: 550px)")
    const steps = [
        {
            text: "Login to the requested platforms using the sign in buttons above.",
            img: "/images/invite/step1.png"
        },
        {
            text: "Select the resource/s you'd like to share for each service.",
            img: "/images/invite/step2.png"
        },
        {
            text: "Once you've made your selections, click grant access. It's as easy as that!",
            img: "/images/invite/step3.png"
        }
    ] as {
        img?: string,
        text: string
    }[]

    return (
        <Box style={{ width: "100%", marginTop: 30, padding: mobile ? 20 : 0, paddingTop: 0 }} w={"100%"} maw={550}>
            <div className='flex aic jcsb'>
                <div className='flex aic' style={{ gap: 8 }}>
                    <InfoCircle size={20} />
                    <Text fw={600} fz="xl">{lang.howItWorks.title[l]}</Text>
                </div>
                <Button size={"compact-sm"} variant={"subtle"} onClick={() => setExpanded(!expanded)} color={theme.color === "dark" && theme.theme === "dark" ? "#fff" : (theme.color || "dark")}>{expanded ? lang.howItWorks.hideButton.shown[l] : lang.howItWorks.hideButton.hidden[l]}</Button>
            </div>
            <Collapse in={expanded}>
                <Paper w={"100%"} radius={10} mt={15} bg={theme.theme === "dark" ? "#171923" : undefined}>
                    <Box p="md" className='flex' style={{ width: "100%", flexDirection: vertical ? "column" : "row", gap: vertical ? 40 : 10 }}>
                        {steps.map((s, i) => {
                            // @ts-ignore
                            let text: { title: Record<ClientLanguage, string>, content: Record<ClientLanguage, string> } = {}
                            switch (i) {
                                case 0:
                                    text = lang.howItWorks.step1
                                    break
                                case 1:
                                    text = lang.howItWorks.step2
                                    break
                                case 2:
                                    text = lang.howItWorks.step3
                                    break
                            }
                            return (
                                <div style={{ flex: 1 }}>
                                    <MantineImage width={"100%"} mah={vertical ? 150 : undefined} style={{ aspectRatio: "4 / 3", borderRadius: 10, objectFit: "contain" }} src={s.img} />
                                    {/* <Skeleton mah={vertical ? 150 : undefined} w={"100%"} style={{ aspectRatio: "4 / 3" }} /> */}
                                    <div style={{ marginTop: 10 }}>
                                        <Text fw={500}>{text.title[l]}</Text>
                                        <Text fz={"sm"} mt={3}>{text.content[l]}</Text>
                                    </div>
                                </div>
                            )
                        })}
                    </Box>
                </Paper>
            </Collapse>
        </Box >
    )
}



export const InviteContainer = ({ children, preview, theme: overwriteTheme }: { children: (theme: Theme) => any, preview?: boolean, theme?: Theme }) => {
    const theme = useContext(ThemeContext)
    const { setColorScheme, colorScheme } = useMantineColorScheme();

    const mobile = useMediaQuery("(max-width: 460px)")
    useEffect(() => {
        if (theme.theme === "dark") {
            setColorScheme("dark")
        } else {
            setColorScheme("light")
        }
    }, [theme])

    console.log("THEME:", theme)
    return (
        <div style={{ width: "100%", position: "relative", minHeight: preview ? "100%" : "100vh", padding: `40px ${mobile ? 0 : 20}px`, background: colorScheme === "dark" ? "#1a202c" : "#f9f9f9" }} className="flex fdc aic">
            <AgencyLogo />
            <div style={{ marginTop: 20, width: "100%" }} className="flex aic jcc fdc">
                {children(theme)}
            </div>
        </div >
    )
}

export const InviteBodyContainer = ({ children }: PropsWithChildren) => {
    const theme = useContext(ThemeContext)
    const mobile = useMediaQuery("(max-width: 460px)")

    return <Paper
        shadow='xs'
        style={{ overflow: "hidden", borderTop: `3px solid var(--mantine-color-${theme.color || "blue"}-6)`, transition: 'all .3s' }}
        radius={mobile ? 0 : "xl"}
        mt={20}
        bg={theme.theme === "dark" ? "#171923" : undefined}
        // bg={`linear-gradient(180deg, ${alpha(`var(--mantine-color-${theme.color || "blue"}-6)`, .1)} 0%, ${theme.theme === "dark" ? "#171923" : "rgba(255,255,255,1)"} 20%)`}
        w={"100%"}
        maw={550}
        px={0}
        py={30}

        className='flex aic jcc fdc'
        id='root_invite_continer'
    >
        {children}
    </Paper>
}

const Platform = ({ acc, focusedAccount, switchAccount }: { acc: AccountTypes, focusedAccount: AccountTypes, switchAccount: (a: AccountTypes) => void }) => {
    const invite = useContext(InviteContext)
    const [isGranted, setIsGranted] = useState(false)
    const theme = useContext(ThemeContext)
    const isFocused = focusedAccount === acc

    useEffect(() => {
        console.log(invite.requestedAccounts)
        setIsGranted(!Object.keys(invite.requestedAccounts[acc]).filter((s) => !invite.requestedAccounts[acc][s as AnyService].granted && invite.requestedAccounts[acc][s as AnyService].requested).length)
    }, [invite])
    return (
        <>
            <Box onClick={() => switchAccount(acc as AccountTypes)} style={{ borderRadius: 10, gap: 5, transition: "all .1s" }} p={"xs"} bg={isFocused ? (theme.color || "dark") : (theme.theme === "dark" ? "dark.7" : "gray.0")} className='flex aic cp'>
                <Box bg={isGranted ? "green.6" : "orange.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
                <Image alt={acc} src={`/images/logos/${acc.replaceAll(" ", "_").toLowerCase()}.png`} style={{ objectFit: "contain", marginLeft: 2 }} width={16} height={16} />
                <Text fw={600} fz="sm" lh={1} c={isFocused || theme.theme === "dark" ? "#fff" : "#000"}>{acc}</Text>
            </Box>
        </>
    )
}

// export const AccountSelectorBar = ({
//     focusedAccount,
//     setFocusedAccount,
//     accountGrantContainerVis,
//     setAccountGrantContainerVis
// }: {
//     focusedAccount: AccountTypes,
//     setFocusedAccount: (a: AccountTypes) => void,
//     accountGrantContainerVis: boolean,
//     setAccountGrantContainerVis: (v: boolean) => void
// }) => {
//     const invite = useContext(InviteContext)
//     const theme = useContext(ThemeContext)
//     const [tooltipShown, setTooltipShown] = useState(true)

//     const switchAccount = useCallback((newAccount: AccountTypes) => {
//         setAccountGrantContainerVis(false)
//         setTimeout(() => {
//             setFocusedAccount(newAccount)
//             setAccountGrantContainerVis(true)
//         }, 200)
//     }, [])

//     useEffect(() => {
//         if (tooltipShown) {
//             setTimeout(() => {
//                 setTooltipShown(false)
//             }, 5000)
//         }
//     }, [tooltipShown])

//     return (
//         <Box w={"100%"} className='flex aic horizontalOverflow' style={{ gap: 5, overflowX: "auto" }} pb={5}>
//             {Object.keys(invite.requestedAccounts).sort().map((acc, i) => {
//                 return <Box>
//                     <Tooltip transitionProps={{ transition: "pop", duration: 300 }} position="left" style={{ zIndex: 999999999999999 }} withArrow opened={!i && tooltipShown} label={
//                         <Box style={{ position: "relative" }}>
//                             <Box className='flex aic' style={{ gap: 5 }}>
//                                 <Key size={15} />
//                                 <Text fw={600} fz="sm">It's time to grant access</Text>
//                             </Box>
//                             <Text fz="xs" className='flex aic' style={{ gap: 8 }}>
//                                 <Box bg={"orange.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
//                                 Indicates access has not yet been granted
//                             </Text>
//                             <Text fz="xs" className='flex aic' style={{ gap: 8 }}>
//                                 <Box bg={"green.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
//                                 Indicates access has been granted successfully
//                             </Text>
//                         </Box>}>
//                         <div>
//                             <Platform acc={acc as AccountTypes} focusedAccount={focusedAccount} switchAccount={switchAccount} key={i} />
//                         </div>
//                     </Tooltip>
//                 </Box>
//             })}
//         </Box>
//     )
// }

export default function ClientInvite() {
    const router = useRouter()
    const invite = useContext(InviteContext)
    const client = useContext(InviteClientContext)
    const granted = useInviteGrantStatus(invite.id, invite)
    const theme = useContext(ThemeContext)
    const mobile = useMediaQuery("(max-width: 460px)")
    const { setColorScheme } = useMantineColorScheme();
    const { lang: l } = client

    useEffect(() => {
        if (theme.theme === "dark") {
            setColorScheme("dark")
        } else {
            setColorScheme("light")
        }
    }, [theme, invite])

    useEffect(() => {
        if (granted && invite.intakeForm?.requested && !invite.intakeForm?.compelte) {
            router.push(`/i/${invite.id}/intake`)
        }
    }, [granted])


    if (granted && invite.intakeForm?.requested && !invite.intakeForm?.compelte) {
        return null
    }

    return (
        <>
            {granted && (invite.intakeForm?.requested ? invite.intakeForm.compelte : true)
                ? <ThankYouScreen invite={invite} />
                : <InviteBodyContainer>
                    <div id='root_invite_content'>
                        <InviteHeader
                            client={client}
                            invite={invite}
                            lang={l}
                            theme={theme}
                        />
                        <AccountsContainer granted={granted} requestedAccounts={invite.requestedAccounts}>
                            {(account, setFocusedAccount) => <Account setFocusedPlatform={setFocusedAccount} id={invite.id} account={account} />}
                        </AccountsContainer>

                    </div>
                </InviteBodyContainer>
            }
            {!granted
                ? <Tutorial />
                : null
            }

            <Footer />
        </>
    )
}