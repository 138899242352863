"use client"
import { useScreen } from '@/hooks/useScreen'
import { Accordion, Box, Highlight, Paper, Text } from '@mantine/core'
import React from 'react'

const FAQs = [
    {
        question: "🙋‍♂️ What is AgencyAccess, and how does it work?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"AgencyAccess is a tool designed to streamline client onboarding by sending a single link to connect accounts."}>
                        AgencyAccess is a tool designed to streamline client onboarding by sending a single link to connect accounts.
                    </Highlight>
                </Text>
                It simplifies workflows and eliminates the need for back-and-forth access requests, making it more efficient for agencies and clients.
            </>
        ),
        category: "General Information",
    },
    {
        question: "🔗 How does the Zapier integration work?",
        answer: <>
            <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"Zapier integration lets you connect AgencyAccess with over 2,000 apps to automate workflows without coding"}>Zapier integration lets you connect AgencyAccess with over 2,000 apps to automate workflows without coding</Highlight></Text>  You can set up triggers so that events in AgencyAccess.co initiate actions in other applications, streamlining your processes and reducing manual tasks. We offer free assistance from an automation expert to help you set up these integrations effectively. (Note: Zapier integration and expert assistance are available in our Pro and Agency plans.)
        </>,
        category: "Integrations"
    },
    {
        question: "🔌 Which platforms can I connect with AgencyAccess?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"AgencyAccess supports integrations with Meta, Google Ads, and other major platforms, with more being added regularly."}>
                        AgencyAccess supports integrations with Meta, Google Ads, and other major platforms, with more being added regularly.
                    </Highlight>
                </Text>
                Click here to see the full list of supported platforms.
            </>
        ),
        category: "Integrations",
    },
    {
        question: "🔒 Is AgencyAccess secure?",
        answer: <><Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"Yes, AgencyAccess is secure."}>Yes, AgencyAccess is secure.</Highlight></Text> We do not save your clients' usernames or passwords; all connections are established directly through secure APIs provided by platforms like Meta (Facebook, Instagram) and Google.<br /><br /> This means sensitive information is never exposed, and all data transmitted is encrypted using industry-standard SSL encryption. We comply with data protection regulations like GDPR to ensure all data is handled responsibly.</>,
        category: "Security"
    },
    {
        question: "⏰ Do I lose client access if I cancel my subscription?",
        answer: <><Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"No, you will not lose access to your clients' accounts if you cancel your subscription."}>No, you will not lose access to your clients' accounts if you cancel your subscription.</Highlight></Text> Since access permissions are granted directly via official APIs, the established connections remain active even if you stop using AgencyAccess.co. Only your clients can revoke access by changing their permissions on the respective platforms, ensuring your ongoing projects aren't disrupted.</>,
        category: "Subscription"
    },
    {
        question: "📈 What happens if I hit my plan limit?",
        answer: <>
            <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"We will not automatically bill you or upgrade your plan without your consent"}>We will not automatically bill you or upgrade your plan without your consent</Highlight></Text> You'll need to wait until your limit resets at the start of your next billing cycle—monthly or annually, depending on your plan. Alternatively, you can manually upgrade your plan at any time to gain immediate access to higher limits.
        </>,
        category: "Subscription"
    },
    {
        question: "🎨 Can I customize the onboarding process for my clients?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"AgencyAccess offers extensive customization options, including multi-language support and branding features."}>
                        AgencyAccess offers extensive customization options, including multi-language support and branding features.
                    </Highlight>
                </Text>
                This ensures a tailored experience for your clients, allowing you to align the process with your agency's brand.
            </>
        ),
        category: "Customization",
    },
    // {
    //     question: "What happens if I stop using AgencyAccess? Will I lose client access?",
    //     answer: (
    //         <>
    //             <Text mb={10} fw={500} suppressHydrationWarning>
    //                 <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"No, you won’t lose access to client accounts. All connections remain intact even if you stop using AgencyAccess."}>
    //                     No, you won’t lose access to client accounts. All connections remain intact even if you stop using AgencyAccess.
    //                 </Highlight>
    //             </Text>
    //             You can always re-enable your access at any time without losing any of your previous configurations.
    //         </>
    //     ),
    //     category: "Account Management",
    // },
    // {
    //     question: "Is AgencyAccess secure for my clients and me?",
    //     answer: (
    //         <>
    //             <Text mb={10} fw={500} suppressHydrationWarning>
    //                 <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"AgencyAccess prioritizes security by avoiding password sharing and using platform-approved methods for account access."}>
    //                     AgencyAccess prioritizes security by avoiding password sharing and using platform-approved methods for account access.
    //                 </Highlight>
    //             </Text>
    //             Read more about our security policy here for further details on our practices and protections.
    //         </>
    //     ),
    //     category: "Security",
    // },
    {
        question: "⏳ How long does it take to onboard a new client with AgencyAccess?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"It only takes a few minutes! Once your client receives the link, they can connect their accounts in just a few clicks."}>
                        It only takes a few minutes! Once your client receives the link, they can connect their accounts in just a few clicks.
                    </Highlight>
                </Text>
                The entire onboarding process is quick and seamless, saving you time and effort.
            </>
        ),
        category: "Onboarding",
    },
    {
        question: "👥 Can I use AgencyAccess for multiple clients simultaneously?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"Yes, AgencyAccess is built for scalability, allowing you to manage onboarding for multiple clients at once."}>
                        Yes, AgencyAccess is built for scalability, allowing you to manage onboarding for multiple clients at once.
                    </Highlight>
                </Text>
                This makes it easy to scale your agency's onboarding process as your client base grows.
            </>
        ),
        category: "Onboarding",
    },
    {
        question: "🛟 What if my clients get stuck during the onboarding process?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"AgencyAccess provides step-by-step guides to troubleshoot common issues, such as adding your agency’s domain to Google before granting access."}>
                        AgencyAccess provides step-by-step guides to troubleshoot common issues, such as adding your agency’s domain to Google before granting access.
                    </Highlight>
                </Text>
                Our support team is also available to assist directly if needed, ensuring your clients' onboarding is seamless.
            </>
        ),
        category: "Onboarding",
    },
    {
        question: "👔 What if my client does not have a Business Manager?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"No Business Manager? No problem! AgencyAccess can help create a Business Manager for your client through our automated systems."}>
                        No Business Manager? No problem! AgencyAccess can help create a Business Manager for your client through our automated systems.
                    </Highlight>
                </Text>
                This ensures a smooth and hassle-free onboarding process even if your client doesn't have one.
            </>
        ),
        category: "Onboarding",
    },
    {
        question: "🆘 What kind of support is available if I encounter issues?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"We offer 24/7 support through live chat and email."}>
                        We offer 24/7 support through live chat and email.
                    </Highlight>
                </Text>
                You can also connect with our team for a personalized demo to guide you through any challenges.
            </>
        ),
        category: "Support",
    },
    {
        question: "🗓️ How does the 30-day trial work?",
        answer: (
            <>
                <Text mb={10} fw={500} suppressHydrationWarning>
                    <Highlight suppressHydrationWarning fw={600} color="blue.1" highlight={"The free trial gives you access to all premium features on paid plans for 30 days, with no credit card required."}>
                        The free trial gives you access to all premium features on paid plans for 30 days, with no credit card required.
                    </Highlight>
                </Text>
                After the trial, you can choose to subscribe or continue on the free plan, depending on your needs.
            </>
        ),
        category: "Subscription",
    }
] as {
    question: string,
    answer: React.ReactNode,
    category: string
}[]


export default function FAQ() {
    const { largeTablet } = useScreen()
    return (
        <Box suppressHydrationWarning className={`flex ${"aic"} jcc fdc`}>
            <Text fw={900} fz={largeTablet ? 75 : 95} style={{
                lineHeight: 1,
                WebkitTextFillColor: "rgba(255,255,255,.88)",
                padding: 5,
                borderRadius: 10,
                textShadow: "0px 2px 1px rgba(0,0,0,.15)",
                background: `linear-gradient(90deg, var(--mantine-color-blue-6) 0%, rgba(255,255,255,1) 100%)`,
                width: "fit-content",
                backgroundClip: "text",
            }}>FAQs</Text>
            <Box mt={largeTablet ? 20 : 50} w={"100%"} maw={largeTablet ? undefined : 550} className='flex fdc' style={{ gap: 40 }}>

                {Array.from(new Set(FAQs.map((f) => f.category))).map((category) => {
                    const categoryFAQs = FAQs.filter((f) => f.category === category)
                    return (
                        <Box>
                            <Text fz={largeTablet ? 24 : 27} fw={700}>{category}</Text>
                            <Paper p={0} shadow='xs' mt={10} bg="#fff" w="100%" style={{ borderRadius: 10, overflow: "hidden", borderBottom: "2px solid var(--mantine-color-blue-6)" }}>
                                <Accordion w="100%" variant='default'>
                                    {categoryFAQs.map((faq) => (
                                        <Accordion.Item p={0} value={faq.question}>
                                            <Accordion.Control p={12} px={20}>
                                                <Text fw={500} fz={"lg"}>{faq.question}</Text>
                                            </Accordion.Control>
                                            <Accordion.Panel px={15}>
                                                {faq.answer}
                                                {/* <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"Yes, AgencyAccess is secure."}>Yes, AgencyAccess is secure.</Highlight></Text> We do not save your clients' usernames or passwords; all connections are established directly through secure APIs provided by platforms like Meta (Facebook, Instagram) and Google.<br /><br /> This means sensitive information is never exposed, and all data transmitted is encrypted using industry-standard SSL encryption. We comply with data protection regulations like GDPR to ensure all data is handled responsibly. */}
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </Paper>
                        </Box>
                    )
                })}

                {/* <Box>
                    <Text fz={largeTablet ? 24 : 27} fw={700}>Security</Text>
                    <Paper p={0} shadow='xs' mt={10} bg="#fff" w="100%" style={{ borderRadius: 10, overflow: "hidden", borderBottom: "2px solid var(--mantine-color-blue-6)" }}>
                        <Accordion w="100%" variant='default'>
                            <Accordion.Item p={0} value='secure'>
                                <Accordion.Control p={12} px={20}>
                                    <Text fw={500} fz={"lg"}>🔒 Is AgencyAccess secure?</Text>
                                </Accordion.Control>
                                <Accordion.Panel px={15}>
                                    <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"Yes, AgencyAccess is secure."}>Yes, AgencyAccess is secure.</Highlight></Text> We do not save your clients' usernames or passwords; all connections are established directly through secure APIs provided by platforms like Meta (Facebook, Instagram) and Google.<br /><br /> This means sensitive information is never exposed, and all data transmitted is encrypted using industry-standard SSL encryption. We comply with data protection regulations like GDPR to ensure all data is handled responsibly.
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Paper>
                </Box>

                <Box>
                    <Text fz={largeTablet ? 24 : 27} fw={700}>Subscription</Text>
                    <Paper p={0} shadow='xs' mt={10} bg="#fff" w="100%" style={{ borderRadius: 10, overflow: "hidden", borderBottom: "2px solid var(--mantine-color-blue-6)" }}>
                        <Accordion w="100%" variant='default'>
                            <Accordion.Item p={0} value='access'>
                                <Accordion.Control p={12} px={20}>
                                    <Text fw={500} fz={"lg"}>⏰ Do I lose client access if I cancel my subscription?</Text>
                                </Accordion.Control>
                                <Accordion.Panel px={15}>
                                    <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"No, you will not lose access to your clients' accounts if you cancel your subscription."}>No, you will not lose access to your clients' accounts if you cancel your subscription.</Highlight></Text> Since access permissions are granted directly via official APIs, the established connections remain active even if you stop using AgencyAccess.co. Only your clients can revoke access by changing their permissions on the respective platforms, ensuring your ongoing projects aren't disrupted.
                                </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item p={0} value='limit'>
                                <Accordion.Control p={12} px={20}>
                                    <Text fw={500} fz={"lg"}>📈 What happens if I hit my plan limit?</Text>
                                </Accordion.Control>
                                <Accordion.Panel px={15}>
                                    <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"We will not automatically bill you or upgrade your plan without your consent"}>We will not automatically bill you or upgrade your plan without your consent</Highlight></Text> You'll need to wait until your limit resets at the start of your next billing cycle—monthly or annually, depending on your plan. Alternatively, you can manually upgrade your plan at any time to gain immediate access to higher limits.
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Paper>
                </Box>

                <Box>
                    <Text fz={largeTablet ? 24 : 27} fw={700}>Integrations</Text>
                    <Paper p={0} shadow='xs' mt={10} bg="#fff" w="100%" style={{ borderRadius: 10, overflow: "hidden", borderBottom: "2px solid var(--mantine-color-blue-6)" }}>
                        <Accordion w="100%" variant='default'>
                            <Accordion.Item p={0} value='zapier'>
                                <Accordion.Control p={12} px={20}>
                                    <Text fw={500} fz={"lg"}>🔗 How does the Zapier integration work?</Text>
                                </Accordion.Control>
                                <Accordion.Panel px={15}>
                                    <Text mb={10} fw={500} suppressHydrationWarning><Highlight suppressHydrationWarning fw={600} color='blue.1' highlight={"Zapier integration lets you connect AgencyAccess with over 2,000 apps to automate workflows without coding"}>Zapier integration lets you connect AgencyAccess with over 2,000 apps to automate workflows without coding</Highlight></Text>  You can set up triggers so that events in AgencyAccess.co initiate actions in other applications, streamlining your processes and reducing manual tasks. We offer free assistance from an automation expert to help you set up these integrations effectively. (Note: Zapier integration and expert assistance are available in our Pro and Agency plans.)
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Paper>
                </Box> */}
            </Box>
        </Box>
    )
}
