import { CustomAssetCreationGuide } from '@/static/assetCreationGuides'
import api from '@/utils/api'
import { Box, Button, TextInput } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import React, { useState } from 'react'

export const FacebookProductCatalogCreationGuide: CustomAssetCreationGuide = ({ close, inviteID, refreshAssets }) => {
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(false)
    return (
        <Box>
            <Box>
                <TextInput value={name} onChange={(e) => setName(e.target.value)} label="Catalog Name" placeholder='My product catalog' />
            </Box>
            <Box className='flex aic' mt={10} style={{ gap: 5 }}>
                <Button disabled={!name} loading={loading} onClick={() => {
                    setLoading(true)
                    api.post(`/accounts/client/meta/catalog/catalogs/create`, {
                        name: name,
                        inviteID
                    }).then(async (res) => {
                        if (res.error) {
                            setLoading(false)
                            notifications.show({
                                color: 'red',
                                title: "Error",
                                message: res.msg
                            })
                            return;
                        }
                        await refreshAssets()
                        notifications.show({
                            color: "green",
                            title: "Catalog created",
                            message: "You can find your new ad account in the dropdown"
                        })
                        setLoading(false)
                        close()
                    })
                }}>Create Product Catalog</Button>
                <Button variant='subtle' disabled={loading} onClick={close}>Close</Button>

            </Box>
        </Box>
    )
}
