import useClient from '@/hooks/useClient'
import { roleValueToLabel } from '@/static/roles'
import api from '@/utils/api'
import { AgencyContext } from '@/utils/context/Agency.context'
import { ClientsContext } from '@/utils/context/Clients.context'
import { ManualGrantsContext } from '@/utils/context/ManualGrants.context'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import { SubscriptionContext } from '@/utils/context/Subscription.context'
import { UserContext } from '@/utils/context/User.context'
import filterRequestedAccounts from '@/utils/data/filterRequestedAccounts'
import getInviteRequestedServices from '@/utils/data/getInviteRequestedServices'
import getPlan from '@/utils/data/getPlan'
import getTheme from '@/utils/helpers/getTheme'
import { ActionIcon, alpha, Avatar, Badge, Box, Button, Checkbox, Collapse, Combobox, CopyButton, Divider, HoverCard, Input, InputBase, Image as MImage, MultiSelect, Paper, Progress, SegmentedControl, Select, Skeleton, Switch, Tabs, Text, Textarea, Tooltip, useCombobox } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import Image from 'next/image'
import Link from 'next/link'
import React, { ButtonHTMLAttributes, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowRight, Check, CircleCheck, CircleMinus, Copy, Forms, InfoCircle, MailFast, Pencil, Reload, TrendingUp, X } from 'tabler-icons-react'
import { isWebUri } from 'valid-url'
import { AccountTypes, AnyService, BillingPeriod, Client, ClientType, GoogleAdsCustomer, GoogleServices, Invite, LinkedInServices, MetaServices, Platform, PlatformService, PublicClient, RequestedAccounts, RequestedServices, Theme } from '../../../../types/global'
import AutosizeModal from '../AutosizeModal'
import Error from '../Error'
import InviteEmailPreview from '../invite/InviteEmailPreview'
import { ClientDetailsInput } from './ClientCreateModal'
import ThankYouRedirectInput from './ThankYouRedirectInput'

type FormProps = {
    currentValues: Partial<Invite & { type: ClientType, sendEmail: boolean }>,
    setValues: (newInviteValues: Partial<(Invite & { type: ClientType, sendEmail: boolean })>) => void,
    next: () => void,
    previous: () => void
}

type Stage = "client_details" | "account_select" | "account_access" | "branding" | "thank_you" | "sum" | "success"

export const StageControllerButtonsContainer = ({ children, disableShadow }: PropsWithChildren & { disableShadow?: boolean }) => {
    return (
        <div className='flex aic' style={{ gap: 10, boxShadow: disableShadow ? "none" : "0px 0px 10px rgba(0,0,0,.1)", zIndex: 999, marginTop: 10, position: "sticky", bottom: 0, background: "#fff", left: -20, width: "100%", height: 65, padding: "0px 20px" }}>
            {children}
        </div>
    )
}

const SelectOption = ({ client }: { client: Client }) => {
    return (
        <div className='flex aic jcsb'>
            <div className='flex' style={{ gap: 10 }}>
                <Avatar src={client.pfp}>{client.name.substring(0, 1)}</Avatar>
                <div>
                    <Text fz="sm">{client.name}</Text>
                    <Text fz="xs" c="dimmed">{client.email} • {client.company}</Text>
                </div>
            </div>
            <Badge size={"sm"} variant={"light"} radius="sm" color={client.type === "Client" ? "gray" : "orange"}>{client.type}</Badge>
        </div>
    )
}


const ClientDetails = (props: FormProps) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    })
    const clients = useContext(ClientsContext)
    const [mode, setMode] = useState<"existing" | "new">(props.currentValues.clientID ? "existing" : "new")
    const [client, setClient] = useState<string | undefined | null>(props.currentValues.clientID)
    const selectedOption = clients.find((item) => item.id === client);
    return (
        <div key={mode} style={{ paddingBottom: 10, minHeight: 100, padding: 20 }}>
            <Text fw={500}>Personalized connection request</Text>
            <Text fz={"sm"} c="dimmed">Add a client or select an existing one</Text>
            <SegmentedControl

                fullWidth
                radius={10} mt={10}
                data={[
                    { value: "existing", label: "Existing", disabled: !clients.length },
                    { value: "new", label: "New" }
                ]}
                id="access_link_modal_client_type_segmentedcontrol"
                // @ts-ignore
                onChange={(m) => {
                    console.log("Setting new mode:", m)
                    // @ts-ignore
                    setMode(m)
                }}
                // defaultValue={mode}
                value={mode}
            />
            {/* <ClientTypeSelector mode={mode} setMode={setMode} /> */}
            {mode === "existing"
                ? <>

                    <Combobox
                        store={combobox}
                        withinPortal
                        zIndex={20000}
                        onOptionSubmit={(val) => {
                            setClient(val);
                            combobox.closeDropdown();
                        }}
                    >
                        <Combobox.Target>
                            <InputBase
                                mt={15}
                                component="button"
                                type="button"
                                pointer
                                rightSection={<Combobox.Chevron />}
                                onClick={() => combobox.toggleDropdown()}
                                rightSectionPointerEvents="none"
                                multiline
                                radius={10}
                            >
                                {selectedOption ? (
                                    <SelectOption client={selectedOption} />
                                ) : (
                                    <Input.Placeholder>Select a client</Input.Placeholder>
                                )}
                            </InputBase>
                        </Combobox.Target>
                        <Combobox.Dropdown mah={250} style={{ overflowY: "auto" }}>
                            <Combobox.Options>{clients.map((c) => {
                                return (
                                    <Combobox.Option value={c.id as string}>
                                        <SelectOption client={c} />
                                    </Combobox.Option>
                                )
                            })}</Combobox.Options>
                        </Combobox.Dropdown>
                    </Combobox>

                    <Button disabled={!client} mt={15} fullWidth onClick={() => {
                        if (!client) return
                        props.setValues({
                            ...props.currentValues,
                            clientID: client,
                            type: selectedOption?.type
                        })
                        props.next()
                    }}>Continue</Button>
                </>
                : <div style={{ marginTop: 10 }}>
                    <ClientDetailsInput
                        hideHeader
                        callback={(clientID, type) => {
                            props.setValues({
                                ...props.currentValues,
                                clientID: clientID as string,
                                type: type
                            })
                            props.next()
                        }}
                    />
                </div>
            }

        </div>
    )
}

const LinkedAccountSelector = ({ platform, service, globalSelectedServices, setGlobalSelectedServices }: {
    globalSelectedServices: RequestedServices,
    setGlobalSelectedServices: (newSelectedServices: RequestedServices) => void,
    platform: AccountTypes,
    service: AnyService
}) => {
    const manualServices = useContext(ManualGrantsContext)
    const agency = useContext(AgencyContext)
    const linkedAccounts = agency.linkedAccounts[platform]

    console.log("MS", globalSelectedServices)

    if (!linkedAccounts) return null;

    if (manualServices.includes(service) && !(["LinkedIn Ads", "LinkedIn Company Page"] as LinkedInServices[]).includes(service as any)) { // Users can only request one acount form manual services. We have an exception in place for LinkedIn services
        return (
            <Select
                required
                label="Account"
                description="Your client will be asked to grant access to this account"
                styles={{ dropdown: { zIndex: 99999 } }}
                data={linkedAccounts?.map((a) => ({ label: (a.business?.name || a.name || a.email) as string, value: a.internalID }))}
                value={globalSelectedServices[service]?.requestedAccountLinks?.map((a) => a.internalAccountID)[0] || ""}
                onChange={(e) => {
                    setGlobalSelectedServices({
                        ...globalSelectedServices,
                        [service]: {
                            ...globalSelectedServices[service],
                            requestedAccountLinks: [{ internalAccountID: e }],
                            metaBusinessUsersToAssign: platform === "Meta" ? globalSelectedServices[service]?.metaBusinessUsersToAssign?.filter(u => agency.linkedAccounts.Meta?.find((la) => la.internalID === e)?.business?.id === u.business.id) : null

                        }
                    })
                }}
                placeholder='Select an account'
            />
        )
    }

    return (
        <MultiSelect
            required
            radius={10}
            label="Accounts"
            description="Your client will be asked to grant access to these accounts"
            styles={{ dropdown: { zIndex: 99999 } }}
            data={linkedAccounts?.map((a) => ({ label: (a.business?.name || a.name || a.email) as string, value: a.internalID }))}
            value={globalSelectedServices[service]?.requestedAccountLinks?.map((a) => a.internalAccountID) || []}
            onChange={(e) => {
                setGlobalSelectedServices({
                    ...globalSelectedServices,
                    [service]: {
                        ...globalSelectedServices[service],
                        requestedAccountLinks: e.map((aid) => ({ internalAccountID: aid })),
                        metaBusinessUsersToAssign: platform === "Meta" ? globalSelectedServices[service]?.metaBusinessUsersToAssign?.filter(u => e?.find((a) => agency.linkedAccounts.Meta?.find((la) => la.internalID === a)?.business?.id === u.business.id)) : null
                    }
                })
            }}
            placeholder='Select accounts'
        />
        // <Popover withArrow shadow="sm" withinPortal zIndex={99999999} transitionProps={{ transition: "pop" }}>
        //     <Popover.Target>
        //         <Box className='cp'>
        //             {selectedAccountIDs.length
        //                 ? <Text c="blue">Granting access to {selectedAccounts[0]?.name || selectedAccounts[0]?.email}</Text>
        //                 : <Text c={"blue"}>Select accounts</Text>
        //             }
        //         </Box>
        //     </Popover.Target>
        //     <Popover.Dropdown>
        //         <Box p="md">

        //         </Box>
        //     </Popover.Dropdown>
        // </Popover>
    )
}

const GoogleAdsMCCAccountSelector = ({ globalSelectedServices, setGlobalSelectedServices }: {
    selectedAccountIDs: string[],
    setGlobalSelectedServices: (newSelectedServices: RequestedServices) => void, globalSelectedServices: RequestedServices
}) => {
    const agency = useContext(AgencyContext)
    const [mccAccountsAvailable, setMCCAccountsAvailable] = useState<Array<GoogleAdsCustomer & { _internalGoogleAccountID: string }>>()
    const [error, setError] = useState("")
    useEffect(() => {
        api.get<Array<GoogleAdsCustomer & { _internalGoogleAccountID: string }>>("/accounts/agency/google/ads/accounts", { all: true }).then((res) => {
            if (res.error) {
                return setError(res.msg)
            }
            setMCCAccountsAvailable(res.data)
        })
    }, [])

    console.log("MCC ACCOUNTS:", mccAccountsAvailable)

    if (!mccAccountsAvailable) {
        return <div>
            <Skeleton h={21} w={150} mb={5} />
            <Skeleton w={350} h={36} />
        </div>
    }
    return (
        // @ts-ignore
        <MultiSelect value={globalSelectedServices['Google Ads MCC']?.requestedAccountLinks?.map((a) => a.googleAdsMCCAccountID) || []} onChange={(e) => {
            setGlobalSelectedServices({
                ...globalSelectedServices,
                "Google Ads MCC": {
                    ...globalSelectedServices['Google Ads MCC'],
                    requestedAccountLinks: e.map((a) => ({
                        internalAccountID: mccAccountsAvailable.find((acc) => acc.id === a)?._internalGoogleAccountID as string,
                        googleAdsMCCAccountID: a
                    }))
                }
            })
        }} styles={{ dropdown: { zIndex: 999999999999 } }} data={mccAccountsAvailable.map((mcc) => ({ label: mcc.descriptiveName, value: mcc.id }))} required radius={10} placeholder='Select Accounts' label="Select MCC accounts" description={`Select the MCC accounts you want to request access from`} maw={350} />
    )
}

const ServiceSelectManager = ({ service, i, platform, globalSelectedServices, isProspectLink, setGlobalSelectedServices, optionalEnabled, metaBusinessUsers }: {
    service: PlatformService,
    platform: Platform, setGlobalSelectedServices: (newSelectedServices: RequestedServices) => void,
    globalSelectedServices: RequestedServices, isProspectLink: boolean, optionalEnabled?: boolean,
    i: number,
    metaBusinessUsers?: { name: string, id: string, businessID: string, businessName: string }[]
}) => {
    const agency = useContext(AgencyContext)
    const [selectedAccountIDs, setSelectedAccountIDs] = useState<string[]>([])
    const [selectionUpdateNotification, setSelectionUpdatedNotification] = useState<null | { service: AnyService, value: string }>(null)

    // const [metaAssignedUsers, setMetaAssignedUsers] = useState<MetaBusinessUser[]>(globalSelectedServices[service.name as AnyService]?.metaBusinessUsersToAssign || [])

    // useEffect(() => {
    //     if (platform.platform !== "Meta") return;
    //     setMetaAssignedUsers((au) => au.filter(u => globalSelectedServices[service.name as AnyService]?.requestedAccountLinks?.find((a) => agency.linkedAccounts.Meta?.find((la) => la.internalID === a.internalAccountID)?.business?.id === u.business.id)))
    // }, [globalSelectedServices])

    useEffect(() => {
        if (!selectionUpdateNotification) return;
        setTimeout(() => {
            setSelectionUpdatedNotification(null)
        }, 1500);
    }, [selectionUpdateNotification])


    // useEffect(() => {
    //     setGlobalSelectedServices({
    //         ...globalSelectedServices,
    //         [service.name]: {
    //             ...globalSelectedServices[service.name],
    //             agencyLinkedAccounts: selectedAccountIDs,
    //             metaAssignedUsers: metaAssignedUsers
    //         }
    //     })
    // }, [selectedAccountIDs])

    return (
        <>
            <Box>
                <Box className='flex fdc' w="100%">
                    <Box className='flex aic jcsb'>
                        <div className='flex aic' style={{ gap: 5, flex: 1 }}>
                            <Image alt={service.name} width={27} height={27} style={{
                                borderRadius: 5, objectFit: "contain", padding: 5,
                                // filter: !linkedAccount ? "grayscale(100%)" : "none"
                            }} src={`/images/logos/${service.name.toLowerCase().replaceAll(" ", "_")}.png`} />
                            <div>
                                <Text
                                    // c={!linkedAccount ? "dimmed" : "#000"}
                                    // style={{ textDecoration: !linkedAccount ? "line-through" : "none" }}
                                    lineClamp={1} fw={500} fz={15}>{service.name}</Text>
                                <Collapse in={globalSelectedServices[service.name]?.requested}>
                                    {service.manual
                                        ? <HoverCard transitionProps={{ transition: "pop" }} shadow={"lg"} withinPortal position="left" withArrow zIndex={10000}>
                                            <HoverCard.Target>
                                                <div className='flex aic' style={{ gap: 5 }}>
                                                    <Text fz={"xs"} c="dimmed">Manual Access</Text>
                                                    <InfoCircle size={12} color="#868e96" />
                                                </div>
                                            </HoverCard.Target>
                                            <HoverCard.Dropdown maw={400}>
                                                <div className='flex' style={{ gap: 10 }}>
                                                    <InfoCircle size={18} style={{ minWidth: 18 }} />

                                                    <div>
                                                        <Text lh={1} fw={600} fz="sm">Manual Access</Text>
                                                        <Text mt={3} fz={"xs"} c="dimmed">As of now, it's not possible to grant access to {service.name} automatically. We'll guide your client through the process of granting access via the native dashboard.</Text>

                                                    </div>
                                                </div>
                                            </HoverCard.Dropdown>
                                        </HoverCard>
                                        : null
                                    }
                                    {/* {service.name === "Google Ads" as GoogleServices && linkedAccount?.google_ads_settings?.type === "MCC"
                                    ? <Text fz="xs" c="dimmed">You are using an MCC account. <Text className='cp' c="blue" fz="xs" span onClick={() => router.push("/dashboard/oauth/google/ads")}>Edit Ad Account Settings</Text></Text>
                                    : null
                                } */}
                                </Collapse>
                            </div>
                        </div>
                        <Switch checked={!!(globalSelectedServices[service.name]?.requested)} onChange={(e) => {
                            if (!e.target.checked) {
                                const newSelectedServices = { ...globalSelectedServices }
                                delete newSelectedServices[service.name]
                                return setGlobalSelectedServices(newSelectedServices)
                            }
                            setGlobalSelectedServices({
                                ...globalSelectedServices,
                                [service.name]: {
                                    ...globalSelectedServices[service.name],
                                    requested: true
                                }
                            })
                        }} />
                    </Box>
                    {!!(globalSelectedServices[service.name]?.requested)
                        ? <Box mb={15} ml={20} mt={10} pl={15} pb={15} style={{ borderLeft: "1px solid var(--mantine-color-gray-3)", borderBottom: "1px solid var(--mantine-color-gray-3)", borderBottomLeftRadius: 10 }}>

                            {globalSelectedServices[service.name]?.requested
                                ? <Box className='flex fdc' style={{ gap: 10 }}>
                                    {service.name === "Google Ads MCC" || service.name === "Amazon Ads" || service.name === "HubSpot Partner" || service.name === "ProfitMetrics Partner"
                                        ? null
                                        : <Select label="Access Level" required error={!globalSelectedServices[service.name]?.accessLevel} w={150} placeholder='Access Level' key={i} value={globalSelectedServices[service.name]?.accessLevel} data={service.accessLevels.filter((al) => isProspectLink ? al.readOnly : true).map((al) => ({ value: al.value, label: al.label }))} onChange={(e) => {
                                            setGlobalSelectedServices({
                                                ...globalSelectedServices,
                                                [service.name]: {
                                                    ...globalSelectedServices[service.name],
                                                    accessLevel: e || ""
                                                }
                                            })
                                        }} />
                                    }

                                    {service.name === "Google Ads MCC"
                                        ? <GoogleAdsMCCAccountSelector globalSelectedServices={globalSelectedServices} selectedAccountIDs={selectedAccountIDs} setGlobalSelectedServices={setGlobalSelectedServices} />
                                        : <LinkedAccountSelector
                                            service={service.name}
                                            setGlobalSelectedServices={setGlobalSelectedServices}
                                            globalSelectedServices={globalSelectedServices}
                                            platform={platform.platform}
                                        />
                                    }

                                    {metaBusinessUsers?.length && globalSelectedServices[service.name as AnyService].requestedAccountLinks?.length && (["Facebook Pages", "Facebook Product Catalog"] as MetaServices[]).includes(service.name as MetaServices)
                                        ? <MultiSelect
                                            styles={{ dropdown: { zIndex: 99999 } }}
                                            value={globalSelectedServices[service.name]?.metaBusinessUsersToAssign?.map((au) => au.user.id) || []}
                                            onChange={(e) => {
                                                setGlobalSelectedServices({
                                                    ...globalSelectedServices,
                                                    [service.name]: {
                                                        ...globalSelectedServices[service.name],
                                                        requested: true,
                                                        metaBusinessUsersToAssign: e.map((uid) => {
                                                            const user = metaBusinessUsers.find((u) => u.id === uid)

                                                            return ({
                                                                business: {
                                                                    name: agency.linkedAccounts.Meta?.find((a) => a.business?.id === user?.businessID)?.business?.name as string,
                                                                    id: user?.businessID as string
                                                                },
                                                                user: {
                                                                    id: user?.id as string,
                                                                    name: user?.name as string,
                                                                }
                                                            })
                                                        })
                                                    }
                                                })
                                            }}
                                            placeholder='Select users (Optional)'
                                            label="Auto-assign Users"
                                            description="We'll assign these users when access has been granted"
                                            data={metaBusinessUsers.filter(u => globalSelectedServices[service.name as AnyService]?.requestedAccountLinks?.find((a) => agency.linkedAccounts.Meta?.find((la) => la.internalID === a.internalAccountID)?.business?.id === u.businessID)).map((a) => ({ label: `${a.name} ${a.businessName ? `(${a.businessName})` : ""}`, value: a.id }))}
                                        />
                                        : null
                                    }
                                </Box>
                                : null
                            }

                            {/* </div> */}
                            {optionalEnabled
                                ? <Box className='flex' style={{ gap: 10 }} mt={15}>
                                    <Checkbox size='xs' checked={!(globalSelectedServices[service.name]?.optional)} onChange={(e) => {
                                        setGlobalSelectedServices({
                                            ...globalSelectedServices,
                                            [service.name]: {
                                                ...globalSelectedServices[service.name],
                                                optional: !e.target.checked
                                            }
                                        })
                                    }} />
                                    <Box>
                                        <Text lh={1} fz="sm" fw={500}>Required</Text>
                                        <Text fz="xs" c="dimmed">Un-tick to allow clients to skip this service</Text>
                                    </Box>
                                </Box>
                                : null
                            }

                        </Box>
                        : null
                    }

                    {/* } */}
                </Box>
                {/* <Collapse in={!!(!!(globalSelectedServices[service.name]?.requested) && optionalEnabled)}>
                </Collapse> */}
            </Box>
        </>
    )
}

const PlatformSelectBox = ({ platform, globalSelectedServices, setGlobalSelectedServices, isProspectLink, optionalEnabled, maxHeight }: {
    platform: Platform, setGlobalSelectedServices: (newSelectedServices: RequestedServices) => void,
    globalSelectedServices: RequestedServices, isProspectLink: boolean, optionalEnabled?: boolean, maxHeight: number
}) => {
    // const user = useContext(UserContext)
    const agency = useContext(AgencyContext)

    const [metaBusinessUsers, setMetaBusinessUsers] = useState<undefined | { name: string, id: string, businessID: string, businessName: string }[]>(undefined) // If the platform is Meta we'll populate this

    useEffect(() => {
        if (platform.platform !== "Meta") return;
        api.get<{ name: string, id: string, businessID: string, businessName: string }[]>("/accounts/agency/meta/business-users", { all: true }).then((res) => {
            if (!res.error) return setMetaBusinessUsers(res.data)
        })
    }, [platform])

    return (
        <Box px={15} className="flex fdc verticalOverflow" style={{ position: "relative", overflowY: "auto", maxHeight, width: "100%" }}>
            {!agency.linkedAccounts[platform.platform]?.length
                ? <div style={{ position: "absolute", borderRadius: 10, zIndex: 1000, background: "rgba(255,255,255,.8)", width: "100%", height: "100%", left: 0, top: 0 }} className="flex aic jcc">
                    <Link href={"/dashboard/accounts"}>
                        <Button rightSection={<ArrowRight size={20} />}>Link {platform.platform}</Button>
                    </Link>
                </div>
                : null
            }
            <Box mt={10} className='flex aic' style={{ gap: 10, position: "sticky", top: 0, background: "#fff", zIndex: 999 }} pb={10}>
                <MImage src={`/images/logos/${platform.platform.toLowerCase().replaceAll(" ", "_")}.png`} alt={`${platform.platform} logo`} style={{ width: 25, height: 25, objectFit: "contain", background: "var(--mantine-color-gray-0)", padding: 3, borderRadius: 20 }} />
                <Text fw={600}>{platform.platform} Services</Text>
            </Box>
            <Box className='flex fdc' style={{ gap: 10, }}>
                {platform.services.map((service, i) => {
                    return (
                        <ServiceSelectManager
                            metaBusinessUsers={metaBusinessUsers}
                            key={i}
                            i={i}
                            globalSelectedServices={globalSelectedServices}
                            setGlobalSelectedServices={setGlobalSelectedServices}
                            isProspectLink={isProspectLink}
                            platform={platform}
                            service={service}
                            optionalEnabled={optionalEnabled}
                        />
                    )
                })}

            </Box>
            {/* </Paper> */}
        </Box>
    )
}

const PlatformSelector = ({ selectedPlatform, setSelectedPlatform, requestedAccounts }: {
    selectedPlatform: AccountTypes,
    setSelectedPlatform: (p: AccountTypes) => void,
    requestedAccounts: RequestedAccounts
}) => {
    const platforms = useContext(PlatformsContext)
    return (
        <>
            {platforms.map((p) => {
                const requestedServices = !filterRequestedAccounts(requestedAccounts)[p.platform] ? [] : Object.keys(filterRequestedAccounts(requestedAccounts)[p.platform])
                const selected = selectedPlatform === p.platform

                return (
                    <Tabs.Tab value={p.platform} leftSection={<MImage src={`/images/logos/${p.platform.toLowerCase().replaceAll(" ", "_")}.png`} alt={`${p.platform} logo`} style={{ width: 20, height: 20, objectFit: "contain", background: "#fff", padding: 3, borderRadius: 20 }} />}>
                        <Box className='flex fdc'>
                            <Text fz="sm" fw={500} lh={1}>{p.platform}</Text>
                            {requestedServices.length
                                ? <Text mt={2} lineClamp={1} style={{ whiteSpace: "nowrap" }} lh={1} fz="xs" c={selected ? "rgba(255,255,255,.5)" : "dimmed"} fw={500}>{requestedServices.length} selected</Text>
                                : null
                            }

                        </Box>
                    </Tabs.Tab>
                )
            })}
        </>
        // <Box className='flex aic horizontalOverflow' style={{ gap: 5, overflowX: "auto", width: "100%", paddingBottom: 5, flexWrap: "wrap" }}>
        //     {platforms.map((p) => {

        //         const requestedServices = !filterRequestedAccounts(requestedAccounts)[p.platform] ? [] : Object.keys(filterRequestedAccounts(requestedAccounts)[p.platform])
        //         const selected = selectedPlatform === p.platform
        //         return (
        //             <Box w={"fit-content"} bg={selected ? "blue" : "gray.0"} px="sm" py={8} style={{ borderRadius: 10, margin: 3, gap: 7, boxShadow: selected ? `0px 0px 3px var(--mantine-color-blue-6)` : "none", transition: "all .1s" }} className='flex aic cp' onClick={() => setSelectedPlatform(p.platform)}>
        //                 <MImage src={`/images/logos/${p.platform.toLowerCase().replaceAll(" ", "_")}.png`} alt={`${p.platform} logo`} style={{ width: 20, height: 20, objectFit: "contain", background: "#fff", padding: 3, borderRadius: 20 }} />
        //                 <Box style={{ width: "fit-content" }}>
        //                     <Text lh={1} fz="sm" c={selected ? "#fff" : "#000"} fw={500}>{p.platform}</Text>
        //                     {requestedServices.length
        //                         ? <Text mt={2} lineClamp={1} style={{ whiteSpace: "nowrap" }} lh={1} fz="xs" c={selected ? "rgba(255,255,255,.5)" : "dimmed"} fw={500}>{requestedServices.length} selected</Text>
        //                         : null
        //                     }

        //                 </Box>
        //             </Box>
        //         )
        //     })}
        // </Box >
    )
}

export const AccountSelect = (props: FormProps & {
    hideHeader?: boolean,
    customAction?: (newValues: RequestedAccounts) => React.ReactElement,
    disablePadding?: boolean,
    onAccountsChange?: ({ accounts, intakeForm }: { accounts: RequestedAccounts, intakeForm: { requested: boolean } }, isValid: boolean) => void,
    preselectedClient?: string,
    optionalEnabled?: boolean
}) => {
    const agency = useContext(AgencyContext)
    // @ts-ignore
    const [accounts, setAccounts] = useState<RequestedAccounts>(props.currentValues.requestedAccounts || {})
    const clients = useContext(ClientsContext)
    const [intakeForm, setIntakeForm] = useState(props.currentValues.intakeForm?.requested || false)
    const platforms = useContext(PlatformsContext)
    const [selectedPlatform, setSelectedPlatform] = useState(platforms[0].platform)

    const { height, ref } = useElementSize()

    useEffect(() => {
        if (props.currentValues.requestedAccounts) {
            setAccounts(props.currentValues.requestedAccounts)
        }
    }, [])

    console.log(accounts)

    // Proud of this lmao
    const disabled = useMemo(() => (!Object.keys(accounts).filter((acc) => (Object.keys(accounts[acc as AccountTypes]) as Array<AnyService>).filter((service: AnyService) => accounts[acc as AccountTypes][service].requested).length).length && intakeForm) ? false : (!!Object.keys(accounts).filter((acc) => (Object.keys(accounts[acc as AccountTypes]) as Array<AnyService>).filter((service: AnyService) => accounts[acc as AccountTypes][service].requested && ((((service === "Google Ads MCC" as GoogleServices) && accounts[acc as AccountTypes][service].requestedAccountLinks?.length) || service === "Amazon Ads" || service === "HubSpot Partner" || service === "ProfitMetrics Partner") ? false : (!accounts[acc as AccountTypes][service].accessLevel || !accounts[acc as AccountTypes][service].requestedAccountLinks?.length))).length).length) || !Object.keys(accounts).filter((acc) => (Object.keys(accounts[acc as AccountTypes]) as Array<AnyService>).filter((service: AnyService) => accounts[acc as AccountTypes][service].requested).length).length, [accounts, intakeForm])

    useEffect(() => {
        if (props.onAccountsChange) props.onAccountsChange({
            accounts,
            intakeForm: { requested: intakeForm }
        }, !disabled);
    }, [accounts, intakeForm])


    return (
        <div>
            <div style={{ padding: props.disablePadding ? 0 : 20, paddingBottom: 0 }}>
                {!props.hideHeader
                    ? <>
                        <Text fw={500}>Choose accounts</Text>
                        <Text fz={"sm"} c="dimmed">Select the accounts you need access to</Text>
                    </>
                    : null
                }
                {props.preselectedClient
                    ? <Paper className='flex aic' style={{ gap: 10 }} mt={5} radius={10} withBorder p="xs">
                        <Avatar size={"sm"} src={clients.find((c) => c.id === props.preselectedClient)?.pfp}>{clients.find((c) => c.id === props.preselectedClient)?.name.substring(0, 1)}</Avatar>
                        <Text fz="sm">Creating a link for <Text span fw={600} fz="sm">{clients.find((c) => c.id === props.preselectedClient)?.name}</Text></Text>
                        <Button onClick={() => props.previous()} ml={"auto"} size="compact-xs" variant="subtle">Change</Button>
                    </Paper>
                    : null
                }

                {/* {platforms.sort((a, b) => (agency.linkedAccounts[b.platform]?.length ? 1 : 0) - (agency.linkedAccounts[a.platform]?.length ? 1 : 0)).map((paltform) => (
                    <Box>
                        <Im
                    </Box>
                ))} */}

                <div className='flex fdc' id='access_link_modal_platform_box_container' style={{ gap: 10, flexWrap: "wrap", marginTop: 10 }}>
                    {/* {agency.intakeForm?.enabled
                        ? <Paper p="sm" bg={"gray.0"} radius={"md"} className='flex aic jcsb' style={{ gap: 15 }}>
                            <Box className='flex' style={{ gap: 10 }}>
                                <Forms style={{ minWidth: 20 }} size={20} />
                                <Box>
                                    <Box className='flex fdc' style={{ gap: 5 }}>
                                        <Text lh={1} fz={15} fw={600}>Intake Form</Text>
                                        <Text fz="sm" lh={1}>
                                            <InlinePageLink newTab style={{ fontSize: 13, width: "fit-content", lineHeight: 1 }} href='/dashboard/intake'>Edit your intake form</InlinePageLink>
                                        </Text>
                                    </Box>
                                    {clients.find((c) => c.id === props.currentValues.clientID)?.intakeForm
                                        ? <Collapse in={intakeForm}>
                                            <Text mt={8} fz="xs" c="red">This client has already filled out an intake form. This one will replace their previous one.</Text>
                                        </Collapse>
                                        : null
                                    }
                                </Box>
                            </Box>
                            <Switch checked={intakeForm} onChange={(e) => setIntakeForm(e.target.checked)} />
                        </Paper>
                        : null
                    } */}

                    {/* <Box className='flex'> */}
                    <Tabs keepMounted={false} variant='pills' orientation='vertical' value={selectedPlatform} onChange={(e) => setSelectedPlatform(e as AccountTypes)}>
                        <Tabs.List ref={ref} style={{ gap: 0, borderRight: `1px solid ${alpha("#dee2e6", .5)}` }} pr={10}>
                            {agency.intakeForm?.enabled
                                ? <>
                                    <Box style={{ padding: "10px 0px", paddingRight: 0, background: "#fff", zIndex: 999 }}>
                                        <Box className='flex aic jcsb'>
                                            <Forms size={16} style={{ minWidth: 16 }} />
                                            <Text ml={10} fz="sm" fw={500} style={{ whiteSpace: "nowrap" }}>Intake Form</Text>
                                            <Switch checked={intakeForm} onChange={(e) => setIntakeForm(e.target.checked)} size='xs' ml={30} />
                                        </Box>
                                    </Box>
                                    <Divider opacity={.5} />
                                </>
                                : null
                            }

                            <Box mt={5} className='flex fdc' style={{ gap: 5 }}>
                                <PlatformSelector
                                    selectedPlatform={selectedPlatform}
                                    setSelectedPlatform={setSelectedPlatform}
                                    requestedAccounts={accounts || {} as RequestedAccounts}
                                />

                            </Box>
                        </Tabs.List>
                        <Box mah={height} style={{ overflow: "hidden" }} w="100% ">
                            {platforms.map((plat) => (
                                <Tabs.Panel value={plat.platform} style={{ overflow: "hidden", maxHeight: "100%" }}>
                                    <PlatformSelectBox maxHeight={height} optionalEnabled={props.optionalEnabled} isProspectLink={props.currentValues.type === "Prospect"} globalSelectedServices={accounts[selectedPlatform] || {}} platform={platforms.find((p) => p.platform === plat.platform) as Platform} setGlobalSelectedServices={(selectedServices) => {
                                        setAccounts((prev) => ({
                                            ...prev,
                                            [selectedPlatform]: { ...selectedServices }
                                        }))
                                    }} />

                                </Tabs.Panel>
                            ))}

                        </Box>
                    </Tabs>
                    {/* </Box> */}
                    {/* {platforms.sort((a, b) => (agency.linkedAccounts[b.platform]?.length ? 1 : 0) - (agency.linkedAccounts[a.platform]?.length ? 1 : 0)).map((plat, i) => {
                        return (
                            <>
                                {!!i ? <Divider opacity={.5} my={5} /> : null}
                                <PlatformSelectBox optionalEnabled={props.optionalEnabled} isProspectLink={props.currentValues.type === "Prospect"} globalSelectedServices={accounts[plat.platform] || {}} platform={plat} key={i} setGlobalSelectedServices={(selectedServices) => {
                                    setAccounts((prev) => ({
                                        ...prev,
                                        [plat.platform]: { ...selectedServices }
                                    }))
                                }} />
                            </>
                        )
                    })} */}
                </div>
            </div>
            {!props.customAction
                ? <StageControllerButtonsContainer>
                    <Button variant={"default"} style={{ flex: 1 }} onClick={() => props.previous()}>Back</Button>
                    <Button id='access_link_modal_platform_box_submit' style={{ flex: 1 }} onClick={() => {
                        props.setValues({
                            ...props.currentValues,
                            requestedAccounts: accounts,
                            intakeForm: {
                                requested: intakeForm,
                                compelte: false
                            }
                        })
                        props.next()
                    }} disabled={disabled}>Continue</Button>
                </StageControllerButtonsContainer>
                : <>{props.customAction(accounts)}</>
            }

        </div>
    )
}

const PreviewEmail = (props: FormProps) => {
    const user = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState("")
    return (
        <>
            <Paper withBorder shadow='xs' p="md" radius={10} mt={18} className='flex aic jcsb' style={{ gap: 12 }}>
                {success
                    ? <Box className='flex aic jcc fdc' style={{ flex: 1 }}>
                        <MailFast size={22} style={{ minWidth: 22 }} />
                        <Text fz={15} lh={1} fw={500}>Email Sent</Text>
                        <Text fz="sm" c="dimmed">We sent a preview to {user?.email}</Text>
                        <Box className='flex aic' style={{ gap: 7 }} mt={5}>
                            <Button component={Link} href='https://mail.google.com' target="_blank" leftSection={<MImage src={"/images/logos/gmail.webp"} w={17} />} variant="default" size='xs'>Open Gmail</Button>
                            <Button component={Link} href={"https://outlook.com"} target='_blank' leftSection={<MImage src={"/images/logos/outlook.webp"} w={17} />} variant="default" size='xs'>Open Outlook</Button>
                        </Box>
                    </Box>
                    : <>
                        <Box className='flex' style={{ gap: 10 }}>
                            {/* <Mail size={20} /> */}
                            <Box>
                                <Box className='flex' style={{ gap: 8 }}>
                                    <MailFast size={22} style={{ minWidth: 22 }} />
                                    <Text fz={15} lh={1} fw={500}>Email Preview <Text span ml={5} c="dimmed" fz="sm">- Send a preview email to yourself before sending this request to your client.</Text></Text>
                                </Box>
                                {/* <Text mt={3} c="dimmed" fz="sm">Send a preview email to yourself before sending this request to your client.</Text> */}
                            </Box>
                        </Box>
                        <Button miw={"fit-content"} style={{ cursor: success ? "default" : "pointer", transition: "all .3s" }} variant={success ? "light" : "subtle"} loading={loading} color={success ? "green" : "dark"} leftSection={success ? <CircleCheck size={20} /> : null} onClick={() => {
                            if (success) return;
                            setError("")
                            setLoading(true)
                            api.post("/invite/preview/email/send", {
                                clientID: props.currentValues.clientID,
                                requestedAccounts: props.currentValues.requestedAccounts,
                                intakeForm: props.currentValues.intakeForm?.requested
                            }).then((res) => {
                                setLoading(false)
                                if (res.error) {
                                    return setError(res.msg)
                                }
                                setSuccess(true)
                            })
                        }}>{success ? "Sent" : "Send Preview"}</Button>
                    </>
                }

            </Paper>
            <Error mt={10}>{error}</Error>
        </>
    )
}

const Branding = (props: FormProps) => {
    const agency = useContext(AgencyContext)
    const [previewTheme, setTheme] = useState<Theme | undefined>(undefined)
    const [height, setHeight] = useState(0)
    const [page, setPage] = useState<"Access Form" | "Intake Form" | "Email">(
        // @ts-ignore
        !Object.keys(props.currentValues.requestedAccounts).filter((a) => Object.keys(props.currentValues.requestedAccounts[a]).filter((s) => props.currentValues.requestedAccounts[a][s].requested).length).length
            ? "Intake Form"
            : "Access Form"
    )
    const client = useClient(props.currentValues.clientID as string)
    const publicClient = useMemo(() => {
        if (!client) return null;
        return {
            lang: client.language || "English",
            id: client.id,
            name: client.name,
            type: client.type
        }
    }, [client])
    useEffect(() => {
        getTheme(agency.id as string).then(setTheme)
    }, [])

    useEffect(() => {
        const h = document.getElementById("access_link_modal-body")?.clientHeight
        setHeight(h || 0)
    }, [])

    return (
        <div style={{ padding: 20, height: height, width: 650 }} className="flex fdc">
            <Box className='flex aic jcsb'>
                <Box>
                    <Text fw={500}>Preview your link</Text>
                    <Text fz={"sm"} c="dimmed">Not happy? <Link href={"/dashboard/branding"} target='_blank'><Text fz="sm" span c="blue">Edit branding settings</Text></Link></Text>
                </Box>
            </Box>
            {/* {props.currentValues.intakeForm?.requested
                ? <SegmentedControl
                    mt={10}
                    radius={10}
                    value={page}
                    // @ts-ignore
                    data={[{ value: "Access Form", label: "Access Form", disabled: !Object.keys(props.currentValues.requestedAccounts).filter((a) => Object.keys(props.currentValues.requestedAccounts[a]).filter((s) => props.currentValues.requestedAccounts[a][s].requested).length).length }, { value: "Intake Form", label: "Intake Form", disabled: !props.currentValues.intakeForm.requested }]}
                    onChange={(e) => {
                        // @ts-ignore
                        setPage(e)
                    }}
                />
                : null
            } */}

            <SegmentedControl
                mt={10}
                radius={10}
                value={page}
                data={[
                    {
                        value: "Access Form",
                        label: "Access Form",
                        // @ts-ignore
                        disabled: !Object.keys(props.currentValues.requestedAccounts).filter((a) => Object.keys(props.currentValues.requestedAccounts[a]).filter((s) => props.currentValues.requestedAccounts[a][s].requested).length).length
                    },
                    {
                        value: "Intake Form",
                        label: "Intake Form",
                        // @ts-ignore
                        disabled: !props.currentValues.intakeForm.requested
                    },
                    {
                        value: "Email",
                        label: "Email",
                        // disabled: !props.currentValues.intakeForm.requested
                    }
                ]}
                onChange={(e) => {
                    // @ts-ignore
                    setPage(e)
                }}
            />

            <div style={{ marginTop: 10, flex: 1, borderRadius: 10, overflow: "hidden", position: "relative" }}>
                <Tooltip label="Refresh" zIndex={99999999}>
                    <ActionIcon mr={30} color="gray" variant='light' style={{ position: "absolute", top: 15, right: -10 }} onClick={() => {
                        getTheme(agency.id).then((t) => {
                            setTheme(t)
                        })
                    }}>
                        <Reload size={20} />
                    </ActionIcon>

                </Tooltip>
                {previewTheme && publicClient
                    ? <>
                        {page === "Access Form"
                            // &theme=${previewTheme.theme}&displayName=${previewTheme.displayName || agency?.name}${previewTheme.logo ? `&logo=${previewTheme.logo}` : ""}&logoWidth=${previewTheme.logoWidthPercentage}
                            ? <iframe width={"100%"} height={"100%"} src={`${window.location.origin}/i/preview/invite?theme=${encodeURIComponent(JSON.stringify({ ...previewTheme, displayName: previewTheme.displayName || agency.name } as Theme))}&client=${encodeURIComponent(JSON.stringify({
                                ...publicClient
                            } as PublicClient))}&requestedAccounts=${encodeURIComponent(JSON.stringify(filterRequestedAccounts(props.currentValues.requestedAccounts as RequestedAccounts)))}`} frameBorder="0"></iframe>
                            : <>
                                {page === "Email"
                                    ? <InviteEmailPreview clientID={props.currentValues.clientID as string} intakeForm={!!props.currentValues.intakeForm?.requested} requestedAccounts={props.currentValues.requestedAccounts as RequestedAccounts} />
                                    : <iframe width={"100%"} height={"100%"} src={`${window.location.origin}/i/preview/intake?aid=${agency.id}&client=${encodeURIComponent(JSON.stringify({ ...publicClient }))}&preview=${JSON.stringify(agency.intakeForm)}&agencyName=${agency.name}`} frameBorder="0"></iframe>
                                }
                            </>
                        }


                    </>
                    : <Skeleton width={"100%"} height={"100%"} style={{ borderRadius: 10 }} />
                }
            </div>
            <PreviewEmail {...props} />
            <div className='flex aic' style={{ gap: 10, marginTop: 20 }}>
                <Button variant={"default"} style={{ flex: 1 }} onClick={() => props.previous()}>Back</Button>
                <Button id='access_link_modal_branding_submit' style={{ flex: 1 }} onClick={() => {
                    props.next()
                }}>Continue</Button>
            </div>
        </div>
    )
}

const ThankYou = (props: FormProps) => {
    const agency = useContext(AgencyContext)
    const [message, setMessage] = useState(props.currentValues.thankYouMessage || "")
    const [redirect, setRedirect] = useState(props.currentValues.redirect || "")
    const [redirectValid, setRedirectValid] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        if (typeof props.currentValues.redirect === "undefined" && !redirect && agency?.website) {
            setRedirect(agency.website.replace("https://", ""))
        }
    }, [])

    useEffect(() => {
        setRedirectValid(undefined)
        if (isWebUri(`https://${redirect}`)) {
            setRedirectValid(true)
        } else {
            setRedirectValid(false)
        }
    }, [redirect])

    return (
        <div style={{ height: "100%", padding: 20 }} className="flex fdc">
            <Text fw={500}>Thank you page</Text>
            <Text fz={"sm"} c="dimmed">Add an optional message and next step link</Text>
            <Box mt={10}>
                <ThankYouRedirectInput
                    redirect={redirect}
                    redirectValid={redirectValid}
                    setRedirect={setRedirect}
                />
                <Textarea value={message} onChange={(e) => setMessage(e.target.value)} mt={5} radius={10} placeholder='Thank you. Now the real work begins...' label="Message" />
            </Box>
            <div className='flex aic' style={{ gap: 10, marginTop: 10 }}>
                <Button variant={"default"} style={{ flex: 1 }} onClick={() => props.previous()}>Back</Button>
                <Button id='access_link_modal_thank_you_submit' style={{ flex: 1 }} onClick={() => {
                    props.next()
                    props.setValues({
                        ...props.currentValues,
                        redirect,
                        thankYouMessage: message
                    })
                }}>Continue</Button>
            </div>
        </div>
    )
}

const ExtraStep = ({ service, children }: { service: AnyService } & PropsWithChildren) => {
    return (
        <>
            <Paper radius={10} mt={0} withBorder p="xs">
                <Box className='flex aic' style={{ gap: 10 }}>
                    <Image alt={`${service} logo`} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} width={22} height={22} />
                    <Text fw={600} fz="sm">{service} requires extra steps</Text>
                </Box>
                <Text fz="xs" c="dimmed" mt={5}>{children}</Text>
            </Paper>
        </>
    )
}

const Summary = (props: FormProps & { goto: (page: Stage) => void, success: (id: string) => void }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [sendEmail, setSendEmail] = useState(true)
    const [sendCloneEmailToAgency, setSendCloneEmailToAgency] = useState(false)
    const platforms = useContext(PlatformsContext)
    const client = useClient(props.currentValues.clientID as string)

    // @ts-ignore
    const filteredReqeustedAccounts = filterRequestedAccounts(props.currentValues.requestedAccounts || {})

    console.log(filteredReqeustedAccounts)

    if (!client) {
        return null
    }
    return (
        <>
            <div style={{ padding: 20 }}>
                <Text fw={500}>Preview summary</Text>
                <Text fz={"sm"} c="dimmed">Review your request before sending</Text>
                <div style={{ marginTop: 10, gap: 10 }} className="flex fdc">
                    <Box p={"sm"} bg="#f9f9f9" style={{ borderRadius: 8 }}>
                        <div className='flex aic jcsb'>
                            <Text fw={500}>Client Details</Text>
                            <ActionIcon disabled={loading} variant={"subtle"} onClick={() => props.goto("client_details")}>
                                <Pencil size={20} />
                            </ActionIcon>
                        </div>
                        <div className='flex jcsb' style={{ marginTop: 10 }}>
                            <div>
                                <Text fz={"xs"} fw={500}>Name</Text>
                                <Text fz={"sm"} lineClamp={1}>{client.name}</Text>
                            </div>
                            <div>
                                <Text fz={"xs"} fw={500}>Company</Text>
                                <Text fz={"sm"} lineClamp={1}>{client.company}</Text>
                            </div>
                            <div>
                                <Text fz={"xs"} fw={500}>Email</Text>
                                <Text fz={"sm"} lineClamp={1}>{client.email}</Text>
                            </div>
                        </div>
                    </Box>
                    {/* @ts-ignore */}
                    {!!Object.keys(filteredReqeustedAccounts).length
                        ? <Box p={"sm"} bg="#f9f9f9" style={{ borderRadius: 8 }}>
                            <div className='flex aic jcsb'>
                                <Text fw={500}>Accounts</Text>
                                <ActionIcon disabled={loading} variant={"subtle"} onClick={() => props.goto("account_select")}>
                                    <Pencil size={20} />
                                </ActionIcon>
                            </div>
                            <div className='flex jcsb fdc' style={{ marginTop: 10, flexWrap: "wrap", gap: 10 }}>
                                {/* @ts-ignore */}
                                {Object.keys(filteredReqeustedAccounts).map((account: AccountTypes) => {
                                    {/* @ts-ignore */ }
                                    if (!Object.keys(filteredReqeustedAccounts[account]).filter((s) => filteredReqeustedAccounts[account][s].requested).length) {
                                        return null
                                    }
                                    return (
                                        <Paper p="md" withBorder radius={"md"} className="flex fdc" style={{ gap: 10, flex: 1 }}>
                                            <div className='flex aic' style={{ gap: 10 }}>
                                                <Image alt={account} width={30} height={30} style={{ borderRadius: 5, objectFit: "contain", backgroundColor: "#f9f9f9", padding: 5 }} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} />
                                                <Text fw={600} fz="md">{account}</Text>
                                            </div>
                                            <Divider />
                                            <div className='flex fdc' style={{ gap: 3 }}>
                                                {/* @ts-ignore */}
                                                {Object.keys(filteredReqeustedAccounts[account]).map((s, i) => {
                                                    // @ts-ignore
                                                    const service = filteredReqeustedAccounts[account][s]
                                                    return (
                                                        <Box className='flex aic jcsb'>
                                                            <div className='flex aic' style={{ gap: 8 }}>
                                                                <Image alt={`${s} logo`} src={`/images/logos/${s.replaceAll(" ", "_").toLowerCase()}.png`} width={20} style={{ padding: 2, objectFit: "contain" }} height={20} />
                                                                <Text fz={"sm"} fw={500}>{s}</Text>
                                                            </div>
                                                            <Text fz={"xs"} c="dimmed">{(s as AnyService === "Google Ads MCC") ? "MCC Access" : roleValueToLabel(s, service.accessLevel, platforms)}</Text>

                                                        </Box>
                                                    )
                                                })}
                                            </div>
                                        </Paper>
                                    )
                                })}
                            </div>
                        </Box>
                        : null

                    }

                    <Box p={"sm"} bg="#f9f9f9" style={{ borderRadius: 8 }}>
                        <div className='flex aic jcsb'>
                            <Text fw={500}>Intake Form</Text>
                            <ActionIcon disabled={loading} variant={"subtle"} onClick={() => props.goto("account_select")}>
                                <Pencil size={20} />
                            </ActionIcon>
                        </div>
                        <div className='flex jcsb fdc' style={{ marginTop: 5, flexWrap: "wrap", gap: 10 }}>
                            {props.currentValues.intakeForm?.requested
                                ? <Badge color="green" variant="light" leftSection={<Check size={14} />}>Requested</Badge>
                                : <Badge color="gray" variant="light" leftSection={<CircleMinus size={14} />}>Not Requested</Badge>
                            }
                        </div>
                    </Box>
                    <Box p={"sm"} bg="#f9f9f9" style={{ borderRadius: 8 }}>
                        <div className='flex aic jcsb'>
                            <Text fw={500}>Thank you page</Text>
                            <ActionIcon disabled={loading} variant={"subtle"} onClick={() => props.goto("thank_you")}>
                                <Pencil size={20} />
                            </ActionIcon>
                        </div>
                        <div className='flex jcsb fdc' style={{ marginTop: 10, gap: 10 }}>
                            <div>
                                <Text fz={"xs"} fw={500}>Redirect</Text>
                                <Text fz={"sm"} lineClamp={1}>{props.currentValues.redirect || "None"}</Text>
                            </div>
                            <div>
                                <Text fz={"xs"} fw={500}>Message</Text>
                                <Text fz={"sm"} lineClamp={3}>{props.currentValues.thankYouMessage || "None"}</Text>
                            </div>
                        </div>
                    </Box>
                    {getInviteRequestedServices(props.currentValues.requestedAccounts as RequestedAccounts).includes("Amazon Ads") || getInviteRequestedServices(props.currentValues.requestedAccounts as RequestedAccounts).includes("Shopify Store Collaborator")
                        ? <>
                            <Text lh={1} fw={600} mt={8}>Notes</Text>
                            {getInviteRequestedServices(props.currentValues.requestedAccounts as RequestedAccounts).includes("Amazon Ads")
                                ? <ExtraStep service={"Amazon Ads"}>Once your client has submitted their account info, you'll need to send a link request from the dashboard. Don't worry, we'll guide you through the process.</ExtraStep>
                                : null
                            }
                            {getInviteRequestedServices(props.currentValues.requestedAccounts as RequestedAccounts).includes("Shopify Store Collaborator")
                                ? <ExtraStep service="Shopify Store Collaborator">Once your client has submitted their account info, you'll need to send a link request from the Shopify Partner Dashboard. Don't worry, we'll guide you through the process.</ExtraStep>
                                : null
                            }
                        </>
                        : null

                    }
                    <Checkbox
                        mt={10}
                        checked={sendEmail}
                        onChange={(e) => setSendEmail(e.target.checked)}
                        label="Send email notification"
                        description="Send an email to your client with your invite link attached"
                    />

                </div>

            </div>
            <Box px={20} className='flex' bg={"#fff"} style={{ position: "sticky", bottom: 0, paddingBottom: 10, paddingTop: 10, boxShadow: "0px 0px 10px rgba(0,0,0,.1)", gap: 10 }}>
                <Button disabled={loading} variant={"default"} style={{ flex: 1 }} onClick={() => props.previous()}>Back</Button>
                <Button style={{ flex: 1 }} id='access_link_modal_submit' loading={loading} onClick={() => {
                    setLoading(true)
                    setError("")
                    api.post<string>("/invite/create", {
                        invite: {
                            ...props.currentValues
                        } as Invite,
                        sendEmail,
                        sendCloneEmailToAgency
                    }).then((res) => {
                        // @ts-ignore
                        dataLayer.push({ 'event': 'create_link' })
                        setLoading(false)
                        if (res.error) {
                            return setError(res.msg)
                        }
                        props.setValues({
                            ...props.currentValues,
                            sendEmail
                        })
                        props.success(res.data)
                    })
                }}>{loading ? "Sending..." : "Send"}</Button>
            </Box>
            <Error mt={10}>{error}</Error>
        </>
    )
}

const Success = ({ id, close, currentValues }: { id: string, close: () => void, currentValues: Invite & { sendEmail: boolean } }) => {
    const agency = useContext(AgencyContext)
    return (
        <div className='flex aic jcc fdc' style={{ width: "100%", padding: 20 }}>
            <CircleCheck size={60} />
            <Text fz={"xl"} fw={600} mt={10} id="link_ready_text">Link Ready</Text>
            <Text c="dimmed" fz="sm">We've created a unique onboarding link for your client</Text>
            <Paper radius={10} px="sm" mt={10} className='flex aic jcsb' withBorder p={0} style={{ padding: "0px 10px", width: "100%", overflow: "hidden" }}>
                <div style={{ padding: "5px 0", flex: 1, maxWidth: "90%" }}>
                    <Text maw={"100%"} lineClamp={1} fz={"sm"}>{`https://${agency?.subdomain ? `${agency.subdomain}.` : ""}agencyaccess.co/i/${id}`}</Text>
                </div>
                <Divider orientation="vertical" />
                <div style={{ paddingLeft: 10, width: "10%" }} className="flex aic jcc">
                    <CopyButton value={`https://${agency?.subdomain ? `${agency.subdomain}.` : ""}agencyaccess.co/i/${id}`} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied' : 'Copy'} style={{ zIndex: 1000000 }} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} style={{ zIndex: 1000000 }} variant="subtle" onClick={copy}>
                                    {copied ? (
                                        <Check style={{ width: 16 }} />
                                    ) : (
                                        <Copy style={{ width: 16 }} />
                                    )}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                </div>
            </Paper>
            {/* @ts-ignore */}
            {!!Object.keys(currentValues.requestedAccounts).filter((account) => Object.keys(currentValues.requestedAccounts[account]).filter((s) => currentValues.requestedAccounts[account][s].requested).length).length
                ? <Box style={{ border: "1px solid rgba(0,0,0,.1)", borderRadius: 10, width: "100%", marginTop: 10, gap: 10 }} className="flex fdc" p="sm">
                    {/* @ts-ignore */}
                    {Object.keys(currentValues.requestedAccounts).map((account: AccountTypes) => {
                        // @ts-ignore
                        if (!Object.keys(currentValues.requestedAccounts[account]).filter((s) => currentValues.requestedAccounts[account][s].requested).length) {
                            return null
                        }
                        return (
                            <>
                                <div className='flex aic' style={{ gap: 10 }}>
                                    <Image alt={account} width={30} height={30} style={{ borderRadius: 5, objectFit: "contain", backgroundColor: "#f9f9f9", padding: 5 }} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} />
                                    <div>
                                        <Text fz={"sm"} fw={500}>{account} Accounts</Text>
                                        <Text fz="xs" c="dimmed">{Object.keys(currentValues.requestedAccounts[account]).length} requested</Text>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </Box>
                : null
            }

            <Box style={{ border: "1px solid rgba(0,0,0,.1)", borderRadius: 10, width: "100%", marginTop: 10, gap: 10 }} className="flex fdc" p="sm">
                <div className='flex aic' style={{ gap: 10 }}>
                    <Forms size={24} />
                    <div>
                        <Text fz={"sm"} fw={500}>Intake Form</Text>
                        {currentValues.intakeForm?.requested
                            ? <Badge color="green" variant="light" leftSection={<Check size={14} />}>Requested</Badge>
                            : <Badge color="gray" variant="light" leftSection={<CircleMinus size={14} />}>Not Requested</Badge>
                        }                    </div>
                </div>
            </Box>
            <Button fullWidth mt={10} onClick={close}>Close</Button>
        </div>
    )
}

const stages = ["client_details", "account_select", "branding", "thank_you", "sum", "success"] as Array<Stage>

const StageController = ({ open, setOpen, client, intakeFormQuickstart, setStage: setGlobalStage, setOverlay }: {
    open: boolean,
    setOpen: (open: boolean) => void,
    client?: string,
    intakeFormQuickstart?: boolean,
    setStage: (s: Stage) => void,
    stage: Stage,
    setOverlay: (o: ((h: number) => React.ReactElement) | null) => void
}) => {
    const agency = useContext(AgencyContext)
    const planDetails = getPlan(agency?.plan)

    const [stage, setStage] = useState<Stage>(intakeFormQuickstart ? "sum" : (client ? "account_select" : "client_details"))
    // @ts-ignore
    const [details, setDetails] = useState<Partial<Invite & { type: ClientType }>>({
        // @ts-ignore
        clientID: client || ""
    })
    const [inviteID, setInviteID] = useState<string | undefined>(undefined)
    const incrementStage = () => {
        setStage(stages[stages.indexOf(stage) + 1])
    }
    const decrementStage = () => {
        setStage(stages[stages.indexOf(stage) - 1])
    }
    const processSuccess = (newInviteID: string) => {
        setInviteID(newInviteID)
    }

    useEffect(() => {
        setGlobalStage(stage)
    }, [stage])

    useEffect(() => {
        if (inviteID) {
            setStage('success')
        }
    }, [inviteID])

    useEffect(() => {
        if (intakeFormQuickstart) {
            // @ts-ignore
            setDetails((prev) => ({
                ...prev,
                intakeForm: {
                    requested: true,
                    compelte: false
                },
                requestedAccounts: {},
            }))
        }
    }, [intakeFormQuickstart])

    useEffect(() => {
        if (!open) {
            setStage("client_details")
            setDetails({})
        }
    }, [open])

    useEffect(() => {
        // @ts-ignore
        setOverlay((agency.created < 1734762981000 ? false : (details.type === "Client" && (!planDetails?.limits[agency?.billingPeriod].accessLinks ? false : agency?.monthlyAccessLinkCount >= planDetails?.limits[agency?.billingPeriod].accessLinks)))
            ? () => (h: number) => <Box className='flex aic jcc fdc' px={15} style={{ position: "absolute", zIndex: 10000, width: "100%", height: h, left: 0, right: 0, background: 'rgba(255,255,255, .9)' }}>
                <TrendingUp size={30} />
                <Text fz={"lg"} fw={600}>You've reached your {details.type} limit</Text>
                <Text c="dimmed" ta={"center"}>Your plan allows {planDetails?.limits[agency?.billingPeriod as BillingPeriod].accessLinks} invites per month. Upgrade to increase your limit.</Text>
                <div className='flex aic' style={{ gap: 10, marginTop: 10 }}>
                    <Button variant="default" onClick={() => {
                        setOpen(false)
                    }}>Close</Button>
                    <Link href={"/dashboard/settings/billing"}>
                        <Button>Manage subscription</Button>
                    </Link>
                </div>
                <ActionIcon variant={"subtle"} pos="absolute" style={{ top: 20, right: 20 }} onClick={() => setOpen(false)}><X /></ActionIcon>
            </Box>
            : null
        )
    }, [details])

    return (
        <>
            {stage === "client_details" && <ClientDetails currentValues={details} setValues={setDetails} next={incrementStage} previous={decrementStage} />}
            {stage === "account_select" && <AccountSelect preselectedClient={client} currentValues={details} setValues={setDetails} next={incrementStage} previous={decrementStage} />}
            {/* {stage === "account_access" && <AccountAccess currentValues={details as Invite} setValues={setDetails} next={incrementStage} previous={decrementStage} />} */}
            {stage === "branding" && <Branding currentValues={details} setValues={setDetails} next={incrementStage} previous={decrementStage} />}
            {stage === "thank_you" && <ThankYou currentValues={details} setValues={setDetails} next={incrementStage} previous={decrementStage} />}
            {stage === "sum" && <Summary currentValues={details} setValues={setDetails} next={incrementStage} previous={decrementStage} goto={(page) => setStage(page)} success={processSuccess} />}
            {stage === "success" && <Success currentValues={details as Invite & { sendEmail: boolean }} id={inviteID as string} close={() => setOpen(false)} />}
        </>
    )
}

export const AccessLinkStateController = ({ open, setOpen, intakeFormQuickstart, client }: {
    open: boolean,
    setOpen: (val: boolean) => void,
    intakeFormQuickstart?: boolean,
    client?: string
}) => {
    const [stage, setStage] = useState<Stage>("client_details")
    const [overlay, setOverlay] = useState<null | ((h: number) => React.ReactElement)>(null)
    return (
        <AutosizeModal
            opened={open}
            onClose={() => setOpen(false)}
            fixedWidth={stage === "branding" ? 650 : (stage === "account_select" ? 700 : 440)}
            fixedHeader={
                <Progress value={((stages.indexOf(stage) + 1) / stages.length) * 100} style={{ borderRadius: 0 }} styles={{
                    root: { borderRadius: 0 }, section: {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                    }
                }} />
            }
            fixedHeight={stage === "branding" ? "100%" : "fit-content"}
            setOpen={setOpen}
            id='access_link_modal'
            overlay={overlay}
        >
            <StageController setOverlay={setOverlay} setStage={setStage} stage={stage} intakeFormQuickstart={intakeFormQuickstart} client={client} open={open} setOpen={setOpen} />
        </AutosizeModal>
    )
}

export default function AccessLinkModal({ target, client, intakeFormQuickstart, open: openBind, setOpen: setOpenBind }: {
    target?: React.ReactElement<{}>, client?: string,
    intakeFormQuickstart?: boolean, // If true, we'll skip straight to the summary page with only the intake form requested
    open?: boolean,
    setOpen?: (o: boolean) => void
}) {
    const subscriptionActive = useContext(SubscriptionContext)
    const [open, setOpen] = useState(false)
    const agency = useContext(AgencyContext)

    useEffect(() => {
        if (openBind && setOpenBind) {
            setOpenBind(open)
        }
    }, [open])
    useEffect(() => {
        if (openBind !== undefined) {
            setOpen(openBind)
        }
    }, [openBind])

    return (
        <>
            {target
                ? <div>
                    {React.cloneElement(target, {
                        onClick: () => setOpen(true),
                        disabled: !subscriptionActive && agency?.subscriptionExpires as number < Date.now()
                    } as ButtonHTMLAttributes<{}>)}

                </div>
                : null
            }
            <AccessLinkStateController
                client={client}
                intakeFormQuickstart={intakeFormQuickstart}
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}
