import {
    HoverCard,
    Group,
    Button,
    Text,
    Divider,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    Paper,
    Avatar,
    TextInput,
    Accordion,
    ActionIcon,
    Badge,
    Popover,
    MantineColor,
    Modal,
    Select,
    Loader,
    ThemeIcon,
} from '@mantine/core';
import { Spotlight, SpotlightActionData, spotlight } from '@mantine/spotlight';
import { useDisclosure, useHover, useMediaQuery } from '@mantine/hooks';
import {
    ChevronDown,
    ArrowRight,
    Search,
    DeviceDesktopAnalytics,
    Plus,
    UserPlus,
    Paint,
    Link as LinkIcon,
    Settings,
    Check,
    InfoCircle,
    X,
    Logout,
    Presentation,
    Key,
    QuestionMark,
    WorldWww,
    Globe,
    ExternalLink,
    Cash,
    Switch,
    Sitemap,
    CursorText,
    AlignJustified,
    Calendar,
    Robot,
    ChevronRight
} from 'tabler-icons-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Logo } from './Logo';
import { UserContext } from '@/utils/context/User.context';
import { AccountTypes, AnyService, AuditLog as AuditLogType, Currency, GoogleServices, MetaServices, PinterestServices, TwitterServices } from '../../../types/global';
import '@mantine/spotlight/styles.css';
import { AccessLinkStateController } from './dashboard/AccessLinkModal';
import { ClientCreateStateController } from './dashboard/ClientCreateModal';
import { CurrencyContext, CurrencyControllerContext } from '@/utils/context/Currency.context';
import { CurrencyController } from './landing/CurrencyController';
import { useScreen } from '@/hooks/useScreen';
import { AgencyContext } from '@/utils/context/Agency.context';
import { collection, DocumentSnapshot, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import { firestore } from '@/utils/firebase';
import { PlatformsContext } from '@/utils/context/Platforms.context';
import { IconDots } from '@tabler/icons-react';

const AccountTypeTitle = ({ accountType }: { accountType: AccountTypes }) => {
    const mobile = useMediaQuery("(max-width: 960px)")
    return (<div className='flex aic' style={{ gap: 15 }}>
        <Image alt={`${accountType} Logo`} width={mobile ? 30 : 35} height={mobile ? 30 : 35} style={{ objectFit: "contain", borderRadius: 25, background: "#f9f9f9", padding: 5 }} src={`/images/logos/${accountType.toLowerCase().replaceAll(" ", "_")}.png`} />
        <Text style={{}} fw={600} fz={mobile ? "md" : "lg"}>{accountType}</Text>
    </div>)
}

const Service = ({ service }: { service: AnyService }) => {
    const { hovered, ref } = useHover()
    const mobile = useMediaQuery("(max-width: 960px)")
    return (
        <Link href={`/service/${service.replaceAll(" ", "-")}`}>
            <Box px={mobile ? 0 : "sm"} py={mobile ? 10 : "lg"} ref={ref} className="flex aic jcsb cp" style={{ borderRadius: 0 }} bg={hovered ? "#f9f9f9" : "transparent"}>
                <div className='flex aic' style={{ gap: 10 }}>
                    <Image alt={`${service} logo`} width={30} height={30} style={{ borderRadius: 30, background: hovered ? "#fff" : "#f9f9f9", objectFit: "contain", padding: 5 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} />
                    <div>
                        <Text fw={500} lineClamp={1} c="#000">{service}</Text>
                        {/* <Collapse in={hovered}>
                            <Text c={"dimmed"} fz="xs">Click to learn about integration</Text>
                        </Collapse> */}
                    </div>
                </div>
                <ArrowRight size={16} color={"#000"} />
            </Box>
        </Link >
    )
}

const ServiceList = ({ services }: { services: Array<AnyService> }) => {
    const mobile = useMediaQuery("(max-width: 960px)")
    return (
        <div className='flex fdc' style={{ gap: 0 }}>
            {services.map((service) => {
                return <>
                    <Service service={service} />
                    {/* {i === 0
                        ? null
                    } */}
                    {!mobile && <Divider style={{ opacity: .4 }} />}
                </>
            })}
        </div>
    )
}
const IntegrationDropdown = () => {
    const mobile = useMediaQuery("(max-width: 960px)")
    return (
        <Box className={`flex ${mobile ? "fdc" : ""}`} style={{ gap: 20 }}>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Google" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Google Ads", "Google Analytics", "Google Search Console", "Google Tag Manager", "Google Merchant Center", "Google Business Profile"] as Array<GoogleServices>} />
            </Box>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Meta" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Meta Ads", "Facebook Pages", "Facebook Product Catalog"] as Array<MetaServices>} />
            </Box>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Pinterest" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Pinterest Ads"] as Array<PinterestServices>} />
            </Box>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Twitter" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Twitter Ads", "Twitter Delegate Account"] as Array<TwitterServices>} />
            </Box>
        </Box>
    )
}
const Message = () => {
    const router = useRouter()
    const { message } = router.query
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (message) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [message])

    return (
        <Collapse in={open}>
            <Box p={"lg"} bg={"red"} className="flex aic jcsb">
                <div className='flex aic' style={{ gap: 10 }}>
                    <InfoCircle size={20} color="#fff" />
                    <Text c={"#fff"} fw={500}>{message}</Text>
                </div>
                <ActionIcon color={"white"} variant="subtle" onClick={() => setOpen(false)}>
                    <X />
                </ActionIcon>
            </Box>
        </Collapse>
    )
}

type HeaderMenuItem = {
    title: string, description?: string, image?: string, icon?: React.ReactElement, color?: MantineColor, customBG?: string,
    href: string,
    category?: string
}
const HeaderMenu = ({ title, items, mode, maw }: { title: string, items: HeaderMenuItem[], mode: "dark" | "light", maw?: number }) => {
    const { hiddenMobile } = useScreen({
        hiddenMobile: "960px"
    })
    const [open, setOpen] = useState(false)
    const categories = Array.from(new Set(items.map((i) => i.category))).filter((c) => !!c) as string[]

    console.log(categories)

    const Item = ({ item, fixedWidth }: { item: HeaderMenuItem, fixedWidth?: string | number }) => {
        const { hovered, ref } = useHover()
        return (
            // @ts-ignore
            <Box component={Link} href={item.href} className={`cp flex ${hiddenMobile ? "fdc" : "aic"}`} ref={ref} p={hiddenMobile ? 0 : "sm"} style={{ borderRadius: 10, gap: hiddenMobile ? 5 : 15, width: fixedWidth }} bg={hiddenMobile ? "transparent" : (hovered ? "gray.0" : "#fff")}>
                {item.icon
                    ? <ThemeIcon color={item.customBG ? undefined : (item.color || "gray")} radius={hiddenMobile ? 5 : 10} size={hiddenMobile ? 25 : (!item.description ? 30 : 35)} variant='light' styles={{
                        root: {
                            background: item.customBG ? `${item.customBG}` : undefined
                        }
                    }}>
                        {React.cloneElement(item.icon, { size: hiddenMobile ? 16 : undefined })}
                    </ThemeIcon>
                    : null
                }
                <Box>
                    <Text fz="sm" c={hiddenMobile ? "#fff" : undefined} fw={500}>{item.title}</Text>
                    {item.description
                        ? <Text lh={1.2} fz="xs" c="dimmed">{item.description}</Text>
                        : null
                    }
                </Box>
                {!hiddenMobile
                    ? <Box w={20} ml={"auto"}>
                        <ArrowRight style={{
                            transition: "all .3s",
                            transform: `translateX(${hovered ? 0 : -10}px)`,
                            opacity: hovered ? 1 : 0
                        }} size={16} />
                    </Box>
                    : null
                }

            </Box>
        )
    }

    if (hiddenMobile) {
        return (
            <Box h="fit-content" style={{ flex: 1, borderRadius: 10 }} p="md" bg="#212121">
                <Box className='flex aic jcsb' onClick={() => setOpen(!open)}>
                    <Text fz="md" c={"#fff"} style={{ cursor: "default" }} fw={500}>{title}</Text>
                    <ChevronDown color={"#fff"} style={{ transition: "all .3s", transform: `rotate(${open ? 180 : 0}deg)` }} size={16} />
                </Box>
                <Collapse in={open}>
                    <Box mt={10} className='flex fdc' style={{ gap: 15 }}>
                        {items.map((item, i) => (
                            <>
                                {hiddenMobile && !!i ? <Divider opacity={.1} /> : null}
                                <Item key={i} item={item} />
                            </>
                        ))}
                    </Box>
                </Collapse>
            </Box>
        )
    }

    return (
        <Box>
            <HoverCard radius={15} transitionProps={{ transition: "pop" }} withArrow onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <HoverCard.Target>
                    <Box className='flex aic' style={{ gap: 8 }}>
                        <Text fz="sm" c={mode === "dark" ? "#fff" : "#212121"} style={{ cursor: "default" }} fw={500}>{title}</Text>
                        <ChevronDown color={mode === "dark" ? "#fff" : "#212121"} style={{ transition: "all .3s", transform: `rotate(${open ? 180 : 0}deg)` }} size={16} />
                    </Box>
                </HoverCard.Target>
                <HoverCard.Dropdown maw={maw || undefined} styles={{ dropdown: { boxShadow: "inset 0px 0px 4px rgba(0,0,0,.25)" } }} px={5} py={5} style={{ zIndex: 5000 }}>
                    <Box className='flex fdc' style={{ gap: 3 }}>
                        {categories.length
                            ? <Box py={"xs"} className='flex fdc' style={{ gap: 8 }}>
                                {categories.map((c) => {
                                    const categoryItems = items.filter((i) => i.category === c)
                                    return <Box>
                                        <Text px="sm" fz="xs" fw={800} c="dimmed">{c}</Text>
                                        <Box px={"xs"} maw={500} className='flex' style={{ flexWrap: "wrap" }}>
                                            {categoryItems.map((item, i) => (
                                                <>
                                                    {hiddenMobile && !!i ? <Divider opacity={.4} styles={{ root: { borderColor: "#fff", background: "#fff" } }} /> : null}
                                                    <Item fixedWidth={`calc(100% / ${3})`} key={i} item={item} />
                                                </>
                                            ))}

                                        </Box>
                                    </Box>
                                })}
                            </Box>
                            : <>
                                {items.map((item, i) => (
                                    <>
                                        {hiddenMobile && !!i ? <Divider opacity={.4} styles={{ root: { borderColor: "#fff", background: "#fff" } }} /> : null}
                                        <Item key={i} item={item} />
                                    </>
                                ))}
                            </>
                        }

                    </Box>
                </HoverCard.Dropdown>
            </HoverCard>

        </Box>
    )
}

const HeaderLinkContent = ({ mode }: { mode: "dark" | "light" }) => {
    const platforms = useContext(PlatformsContext)
    const { hiddenMobile } = useScreen({
        hiddenMobile: "960px"
    })

    const isFeatured = (p: AccountTypes) => {
        return (["Google", "Meta", "Shopify"] as AccountTypes[]).includes(p)
    }

    return (
        <>
            <HeaderMenu
                maw={400}
                mode={mode}
                items={[
                    { href: "/feature/access", title: "Automatic Access", color: "blue", description: "Get access to your client's accounts seamlessly", icon: <Key /> },
                    { href: "/feature/intake-form", title: "Intake Forms", color: "yellow", description: "Gather important details from your clients in a beautiful and seamless way.", icon: <QuestionMark /> },
                    { href: "/feature/branding", title: "Custom Branding", color: "red", description: "Make AgencyAccess your own with custom wording, colours, and logos.", icon: <Paint /> },
                    { href: "/feature/subdomain", title: "Subdomains", color: "pink", description: "Build trust with clients by hosting your onboarding flow on your own AgencyAccess subdomain", icon: <WorldWww /> },
                    { href: "/feature/languages", title: "Multi-language support", color: "green", description: "Break down borders, with seamless multilingual support for access links, emails, intake forms, and more", icon: <Globe /> },
                    { href: "/feature/embeds", title: "Embedding", color: "gray", description: "Prefer to host onboarding on your own site? Embed AgencyAccess anywhere", icon: <ExternalLink /> },
                ]}
                title='Features'
            />
            {/* <HeaderMenu
                mode={mode}
                title='Customers'
                items={[
                    { href: "/", title: "SEO-agencies", icon: <Sitemap />, color: "pink" },
                    { href: "/", title: "PPC Agencies", icon: <Cash />, color: "red" },
                    { href: "/", title: "Web Design Agencies", icon: <CursorText />, color: "blue" },
                ]}
            /> */}
            <HeaderMenu
                mode={mode}
                title='Integrations'
                items={[...platforms.map((p) => p.platform).filter((p) => {
                    if (hiddenMobile && !isFeatured(p)) return false;
                    return true
                }).map((p) => {
                    const featured = isFeatured(p)
                    return ({
                        href: `/integrations/${featured ? p.toLowerCase().replaceAll(" ", "-") : ""}`, title: p,
                        icon: <Image alt={p} width={20} height={20} src={`/images/logos/${p.toLowerCase().replaceAll(" ", "")}.png`} style={{ objectFit: "contain" }} />,
                        category: featured ? "FEATURED PLATFORMS" : "OTHER PLATFORMS"
                        // color: "",
                    })
                }), {
                    title: "See All",
                    href: "/integrations",
                    category: "OTHER PLATFORMS",
                    color: "gray",
                    icon: <IconDots />,
                }]}
            />
            {hiddenMobile
                ? <Box className='flex aic jcsb' h="fit-content" style={{ flex: 1, borderRadius: 10 }} p="md" bg="#212121">
                    <Text fz="md" c={"#fff"} style={{ cursor: "default" }} component={Link} href={"/pricing"} fw={500}>{"Pricing"}</Text>
                    <ChevronRight color={mode === "dark" ? "#fff" : "#212121"} style={{ transition: "all .3s" }} size={16} />
                </Box>
                : <Text c={mode === "dark" ? "#fff" : "#212121"} component={Link} href={"/pricing"} fz="sm" fw={500}>Pricing</Text>
            }

            {/* <HeaderMenu
                mode={mode}
                items={[
                    { href: "/competitors/leadsie", title: "AgencyAccess vs Leadsie", color: "cyan", description: "Compare AgencyAccess to Leadsie", icon: <Image alt='Leadsie' width={20} height={20} src={"/images/landing_page/companies/Leadsie_2.png"} /> },
                    { href: "/competitors/digitalsero", title: "AgencyAccess vs DigitalSero", color: "blue", description: "Compare AgencyAccess to DigitalSero", icon: <Image alt='DigitalSero' width={20} height={20} src={"/images/landing_page/companies/DigitalSero_2.png"} /> },
                    { href: "/competitors/linkmyagency", title: "AgencyAccess vs LinkMyAgency", color: "gray", description: "Compare AgencyAccess to LinkMyAgency", icon: <Image alt='LinkMyAgency' width={20} height={20} src={"/images/landing_page/companies/LinkMyAgency_2.png"} /> },
                ]}
                title='Competitors'
            /> */}

        </>
    )
}

export const DefaultHeader = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const router = useRouter()
    const [mode, setMode] = useState<"dark" | "light">("light")
    const user = useContext(UserContext)
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

    const [isAuthRoute, setIsAuthRoute] = useState(false)

    const { hiddenMobile, laptop, largeMobile } = useScreen({
        hiddenMobile: "960px"
    })

    const scrollEventAction = useCallback(() => {
        if (window.scrollY > 600) {
            setMode("light")
        } else {
            setMode("dark")
        }
    }, [])

    useEffect(() => {
        router.events.on("routeChangeComplete", () => {
            closeDrawer()
        })
        router.events.on("hashChangeComplete", () => {
            closeDrawer()
        })
    }, [])

    useEffect(() => {
        if (!router.pathname || router.pathname === "/") {
            setMode("dark")
            window.addEventListener("scroll", scrollEventAction)
        } else {
            window.removeEventListener("scroll", scrollEventAction)
            setMode("light")
        }

    }, [router.pathname])

    useEffect(() => {
        if ((router.pathname as string).startsWith("/auth")) {
            setIsAuthRoute(true)
        } else {
            setIsAuthRoute(false)
        }
    }, [router.pathname])

    const AuthModule = () => {
        if (isLoggedIn) {
            return (
                <div className='flex aic' style={{ gap: 10, flexWrap: "wrap" }}>
                    <Link href={"/logout"}>
                        <Button color={mode === "dark" ? '#fff' : "dark"} variant={"subtle"}>Logout</Button>
                    </Link>
                    <Link href={"/dashboard"}>
                        <Button id='go_to_dashboard_button' rightSection={<ArrowRight size={20} />}>Go to Dashboard</Button>
                    </Link>
                    <Link href={"/settings"}>
                        <Avatar src={user?.pfp}>{user?.name?.substring(0, 1)}</Avatar>
                    </Link>
                </div>
            )
        }
        return (
            <div className='flex aic' style={{ gap: 10 }}>
                <Link href={"/auth/login"}>
                    <Button color={mode === "dark" ? 'white' : "dark"} id='login_button' variant={'subtle'}>Login</Button>
                </Link>
                <Link href={"/pricing"}>
                    <Button id='signup_button' rightSection={<ArrowRight size={20} />}>Get Started Free</Button>
                </Link>
            </div >
        )
    }
    return (
        <>
            <Box mx={largeMobile || isAuthRoute ? 0 : 25} mt={largeMobile || isAuthRoute ? 0 : 15} mb={15} style={{ zIndex: 500, position: "fixed", width: `calc(100vw - ${largeMobile || isAuthRoute ? "0px" : "50px"})`, backdropFilter: "blur(20px)", borderTopLeftRadius: largeMobile || isAuthRoute ? 0 : 20, borderTopRightRadius: largeMobile || isAuthRoute ? 0 : 20, borderBottomLeftRadius: largeMobile || isAuthRoute ? 10 : 20, borderBottomRightRadius: largeMobile || isAuthRoute ? 10 : 20, background: mode === "light" ? "rgba(255,255,255,.25)" : "rgba(0,0,0,.25)", top: 0 }}>
                <Message />
                <Box>
                    <Box component={"header"} className="flex  jcc" mx={25} h={80} style={{ borderBottom: "1px solid rgba(0,0,0,.05)" }}>
                        <Box className='flex aic jcsb' maw={1500} w="100%" style={{ height: '100%' }}>
                            <div className='flex aic' style={{ gap: 5 }}>
                                <Logo dark={mode === "dark"} width={175} href='/' />
                            </div>
                            {!hiddenMobile
                                ? <HeaderLinkContent mode={mode} />
                                : null
                            }


                            <div className='flex aic' style={{ gap: 20 }}>
                                <Group className={"hiddenMobile"}>
                                    <AuthModule />
                                </Group>
                                <div className={"hiddenMobile"}>
                                    {/* <CurrencyController /> */}

                                </div>
                                <Burger color={mode === "dark" ? "#fff" : "#212121"} opened={drawerOpened} onClick={toggleDrawer} className={"hiddenDesktop"} />
                            </div>
                        </Box>
                    </Box>

                </Box>
            </Box>
            {hiddenMobile
                ? <Box pb={40} bg="rgba(0,0,0,.8)" h="100dvh" w="100vw" style={{ position: "fixed", top: 0, left: 0, transform: `translateY(${drawerOpened ? "0px" : "-100dvh"})`, zIndex: 900, transition: "all .3s", backdropFilter: "blur(30px)", overflowY: "auto" }}>
                    <Box p="xl" className='flex aic jcsb'>
                        <Logo dark width={175} href='/' />
                        <ActionIcon onClick={() => toggleDrawer()} variant='subtle' color='white'>
                            <X size={25} />
                        </ActionIcon>
                    </Box>
                    <Box px="xl" className={`flex ${laptop ? "fdc" : ""}`} style={{ gap: 15 }}>
                        <HeaderLinkContent mode={"dark"} />
                    </Box>
                </Box>
                : null
            }
        </>
    );
}

const AuditLog = () => {
    const agency = useContext(AgencyContext)
    const [search, setSearch] = useState("")
    const [date, setDate] = useState<Date | undefined>(undefined)
    const [logs, setLogs] = useState<AuditLogType[] | undefined>(undefined)
    const [startAfterSnap, setStartAfterSnap] = useState<undefined | DocumentSnapshot>()
    const [loadingMore, setLoadingMore] = useState(false)
    const [more, setMore] = useState(true)
    const fetchLogs = useCallback(async (reset?: boolean) => {
        const LIMIT = 25
        const startAfterQueryArgs = []
        if (startAfterSnap && !reset) startAfterQueryArgs.push(startAfter(startAfterSnap));
        const { docs } = await getDocs(query(collection(firestore, "agencies", agency.id, "auditLog"), orderBy("created", "desc"), limit(LIMIT), ...startAfterQueryArgs))
        const data = docs.map((d) => ({ ...d.data() as AuditLogType, id: d.id }))
        setLogs((logs) => logs && !reset ? [...logs, ...data] : [...data])
        if (docs.length < LIMIT) {
            setMore(false)
        } else {
            setMore(true)
        }
        setStartAfterSnap(docs[docs.length - 1])
    }, [date, search, startAfterSnap])

    useEffect(() => {
        fetchLogs(true)
    }, [])

    const filteredLogs = useMemo(() => (
        logs
            ? logs.filter((log) => {
                if (!search) return true;
                if (!log.description.toLowerCase().includes(search.toLowerCase()) && !log.title.toLowerCase().includes(search.toLowerCase())) return false;
                return true;
            })
            : undefined
    ), [logs, search])

    return (
        <Box className='flex fdc verticalOverflow' style={{
            overflowY: "auto", maxHeight: "calc(var(--modal-content-max-height, calc(100dvh - var(--modal-y-offset)* 2)) - 70px)",
            height: "var(--modal-content-height, auto)"
        }} mr={5}>
            <Box mx={16} mb={5} className='flex aic' style={{ gap: 10, borderRadius: 10 }} bg={"linear-gradient(-90deg, rgba(0,0,0,1) 0%, rgba(34,139,230,1) 100%)"} p={10}>
                <Text lh={1} fz="sm">📝</Text>
                <Box style={{ position: "relative", bottom: 4 }}>
                    <Badge color='red' size='xs' lh={1}>New</Badge>
                    <Text mt={3} fz={"sm"} lh={1} fw={600} c="#fff">Audit log helps you keep track of account actions</Text>
                </Box>
            </Box>
            <Box px={16} className='flex' style={{ gap: 10, position: "sticky", top: 0, zIndex: 300 }} pb={10} bg={"#fff"}>
                <TextInput value={search} onChange={(e) => setSearch(e.target.value)} leftSection={<Search size={18} />} style={{ flex: 1 }} placeholder='Search logs' />
                {/* <DateInput w={140} leftSection={<Calendar size={18} />} placeholder='Select a date' /> */}
            </Box>
            <Box px={16} pb={16} style={{ overflow: "visible", flex: 1 }}>
                {!logs
                    ? <Box className='flex aic jcc' py={20}>
                        <Loader size={"sm"} />
                    </Box>
                    : <Box mt={15} className='flex fdc' style={{ gap: 15 }}>
                        {!filteredLogs?.length
                            ? <Text ta="center" pb={10} c="dimmed" fz="sm">No logs to display</Text>
                            : null
                        }
                        {filteredLogs?.map((log) => (
                            <Box className='flex' style={{ gap: 10 }}>
                                <ThemeIcon size={"sm"} color='gray' radius={100} variant='light'>
                                    <InfoCircle size={16} />
                                </ThemeIcon>
                                <Box>
                                    <Box className='flex aic' style={{ gap: 5 }}>
                                        <Text lh={1} fz="sm" fw={500}>{log.title}</Text>
                                        {log.automatic
                                            ? <Badge radius={5} variant='light' size='xs' leftSection={<Robot size={12} />}>Automatic</Badge>
                                            : null
                                        }
                                    </Box>
                                    <Text mt={2} fz="xs" c="dimmed">{log.description}</Text>
                                    <Box className='flex aic' style={{ gap: 5 }} mt={3}>
                                        <Text fw={500} fz={10} c="dimmed">{new Date(log.created).toDateString()} {new Date(log.created).toLocaleTimeString()}</Text>
                                        {log.uid
                                            ? <>
                                                <Divider orientation='vertical' my={3} />
                                                <Text fw={500} fz={10} c="dimmed">By {agency.users.find((u) => u.uid === log.uid)?.name || log.uid}</Text>

                                            </>
                                            : null
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                        {more
                            ? <Button loading={loadingMore} onClick={async () => {
                                setLoadingMore(true)
                                await fetchLogs()
                                setLoadingMore(false)
                            }} variant='default' size='xs' w="fit-content" mx="auto">Load more</Button>
                            : null
                        }

                    </Box>
                }
            </Box>
        </Box>
    )
}

const AuditLogModal = () => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Modal
                opened={open}
                onClose={() => setOpen(false)}
                title={<Box>
                    <Text fw={500}>Audit Log</Text>
                    <Text fz="sm" c="dimmed">Review past account actions</Text>
                </Box>}
                styles={{
                    content: {
                        overflow: "hidden"
                    },
                    body: {
                        maxHeight: "calc(var(--modal-content-max-height, calc(100dvh - var(--modal-y-offset)* 2)) - 70px)",
                        height: "var(--modal-content-height, auto)",
                        padding: 0
                        // maxHeight: "100%"
                        // overflow: "hidden"
                    }
                }}
            >
                <Box>
                    <AuditLog />
                </Box>
            </Modal>
            <ActionIcon variant="subtle" size={"md"} onClick={() => setOpen(true)}>
                <AlignJustified size={19} />
            </ActionIcon>
        </>
    )
}


export const DashboardHeader = () => {
    const user = useContext(UserContext)
    const router = useRouter()
    const [createLinkOpen, setCreateLinkOpen] = useState(false)
    const [createClientOpen, setCreateClientOpen] = useState(false)
    const spotlightActions: SpotlightActionData[] = [
        {
            id: 'dashboard',
            label: 'Dashboard',
            description: 'Go to the dashboard',
            onClick: () => router.push("/dashboard"),
            leftSection: <DeviceDesktopAnalytics size={25} />,
        },
        {
            id: 'link',
            label: 'Create a Link',
            description: 'Create a new access link',
            onClick: () => setCreateLinkOpen(true),
            leftSection: <Plus size={25} />,
        },
        {
            id: 'client',
            label: 'Create a Client',
            description: 'Create a new client',
            onClick: () => setCreateClientOpen(true),
            leftSection: <UserPlus size={25} />,
        },
        {
            id: 'branding',
            label: 'Edit Branding',
            description: 'Customize your branding options',
            onClick: () => router.push("/dashboard/branding"),
            leftSection: <Paint size={25} />,
        },
        {
            id: 'accounts',
            label: 'Manage Accounts',
            description: 'Manage your connected accounts',
            onClick: () => router.push("/dashboard/accounts"),
            leftSection: <LinkIcon size={25} />,
        },
        {
            id: 'settings',
            label: 'Settings',
            description: 'Edit Settings',
            onClick: () => router.push("/dashboard/settings/general"),
            leftSection: <Settings size={25} />,
        },
    ];


    return (
        <>
            <AccessLinkStateController open={createLinkOpen} setOpen={setCreateLinkOpen} />
            <ClientCreateStateController open={createClientOpen} setOpen={setCreateClientOpen} />
            <Box component={"header"} h={80} px={25} className="flex aic jcsb" style={{ borderBottom: "1px solid #e9ecef" }}>
                <Logo width={185} href='/dashboard' />
                <div className='flex aic' style={{ gap: 20 }}>
                    <AuditLogModal />
                    <Popover keepMounted shadow='xl' radius={10} withArrow transitionProps={{ transition: "pop" }}>
                        <Popover.Target>
                            <ActionIcon variant="subtle" size={"md"}>
                                <Presentation size={19} />
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown h={280} w={500} p={0}>
                            <video
                                style={{ width: "100%", borderRadius: 10, height: "100%", objectFit: "cover" }}
                                controls
                                src="https://storage.googleapis.com/agencyaccess-branding-assets/videos/Reports%20dashboard%20-%2030%20April%202024.mp4"
                            />
                        </Popover.Dropdown>
                    </Popover>
                    <div onClick={() => spotlight.open()} className="cp">
                        <TextInput
                            className='cp'
                            styles={{
                                input: {
                                    cursor: "pointer"
                                }
                            }}
                            leftSection={<Search size={18} />}
                            placeholder='Search'
                        />
                    </div>
                    <Spotlight
                        actions={spotlightActions}
                        searchProps={{
                            leftSection: <Search style={{ width: rem(20), height: rem(20) }} />,
                            placeholder: 'Search anything...',

                        }}
                        radius="md"
                    />
                    <Link href={"/settings"}>
                        <Avatar radius={100} alt={user?.name} src={user?.pfp}>{user?.name.substring(0, 1)}</Avatar>
                    </Link>
                </div>
            </Box>
        </>
    )
}

export const SettingsHeader = () => {
    const router = useRouter()
    return (
        <Box component={"header"} h={75} className="flex aic jcsb" p="sm" style={{ boxShadow: "0px 0px 10px rgba(0,0,0,.1)", background: "#fff", zIndex: 100, position: 'fixed', width: "100%", top: 0 }}>
            <div className='flex aic jcc' style={{ gap: 10 }}>
                <Logo width={200} />
                <Divider orientation="vertical" my={4} />
                <Badge variant={"light"} color="blue" radius={4} size="md">User Settings</Badge>
                {/* <Text>User Settings</Text> */}
            </div>
            <div className='flex aic' style={{ gap: 10 }}>
                <Button variant={"subtle"} leftSection={<Logout size={18} />} onClick={() => router.push("/logout")}>Log Out</Button>

                <Button variant={"default"} rightSection={<ArrowRight size={18} />} onClick={() => router.push("/dashboard")}>Back to Dashboard</Button>
            </div>
        </Box>
    )
}