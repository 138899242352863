import { ThemeContext } from '@/utils/context/Theme.context'
import { Box, useMantineColorScheme } from '@mantine/core'
import Link from 'next/link'
import React, { useContext } from 'react'
import { Logo as AgencyAccessLogo } from '../Logo'

export default function Logo() {
    const { colorScheme } = useMantineColorScheme()
    const theme = useContext(ThemeContext)
    return (
        <Box w={"100%"} maw={550} mx={"auto"}>
            <Link style={{ width: "100%" }} href={theme.logo ? "#" : "https://www.agencyaccess.co"} className='flex aic jcc'>
                {theme.logo
                    ? <img src={theme.logo} style={{
                        objectFit: "contain",
                        width: theme.logoWidthPercentage || 200,
                        maxWidth: 200
                    }} />
                    : <AgencyAccessLogo width={200} dark={colorScheme === "dark"} />
                }
            </Link>
        </Box>
    )
}
