"use client"
import { useScreen } from '@/hooks/useScreen';
import '@mantine/carousel/styles.css';
import { Box, Image, Paper } from '@mantine/core';
import { AnyService } from '../../../../types/global';
const logoBar = [
    "Google Analytics",
    "Google Tag Manager",
    "HubSpot Partner",
    "Instagram",
    "Google Search Console",
    "Amazon Ads",
    "Twitter Ads",
    "Google Ads",
    "ProfitMetrics Partner",
    "Snapchat Ads",
    "YouTube Studio",
    "Shopify Store Collaborator"
] as Array<AnyService>

export const PlatformBoxRender = ({ size, service }: { size?: number, service: AnyService }) => {
    return (
        <Paper bg={`linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.5) 100%)`} className="flex aic jcc platform" radius={(size || 40) / 2} style={{
            width: "fit-content",
            boxShadow: "inset 2px 0px 2px #0001",
            transition: `all .3s`,
        }} p="sm">
            <Image style={{ objectFit: "contain" }} alt={`${service} logo`} src={`/images/logos/${service.replaceAll(" ", "_").toLowerCase()}.png`} w={size || 40} h={size || 40} />
        </Paper>
    )
}

export const SupportedPlatforms = () => {
    const { tablet } = useScreen()
    return (
        <Box style={{ position: "relative", overflow: "hidden", maxWidth: tablet ? "100dvw" : 500 }} mt={30} className='flex'>
            <Box style={{ position: "absolute", left: 0, height: 54, width: 125, zIndex: 200 }} bg={"linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)"} />
            <div className='flex aic jcc supportedPlatforms' style={{ gap: 10, overflow: "hidden", position: "relative", width: 500 }}>
                {logoBar.map((_, i) => {
                    return <PlatformBoxRender service={_} key={i} size={30} />
                })}
            </div>
            <div className='flex aic jcc supportedPlatformsOffset' style={{ gap: 10, position: "absolute", left: 0, overflow: "hidden", width: 500 }}>
                {logoBar.map((_, i) => {
                    return <PlatformBoxRender service={_} key={i} size={30} />
                })}
            </div>
            <Box style={{ position: "absolute", right: 0, height: 54, width: 125, zIndex: 200 }} bg={"linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)"} />
        </Box>
    )
}
