import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@/styles/globals.css'
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Button, createTheme, Input, MantineProvider, Select, ActionIcon, ColorSchemeScript, Alert, Modal, NumberInput, Box, Checkbox, useMantineColorScheme } from '@mantine/core';
import DefaultLayout from '@/layouts/Default.layout';
import { useRouter } from 'next/router';
import DashboardLayout from '@/layouts/Dashboard.layout';
import { useEffect, useState } from 'react';
import TrialLayout from '@/layouts/Trial.layout';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { CurrencyContext, CurrencyControllerContext } from '@/utils/context/Currency.context';
import api from '@/utils/api';
import { Currency, Platform, User } from '../../../types/global';
import { auth, firestore } from '@/utils/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { PlatformsContext } from '@/utils/context/Platforms.context';
import { LoadingScreen } from '@/components/LoadingScreen';
import SettingsLayout from '@/layouts/Settings.layout';
import PlatformsProvider from '@/providers/Platforms.provider';
import AdminProvider from '@/providers/Admin.provider';
import AdminLayout from '@/layouts/Admin.layout';
import BlogLayout from '@/layouts/Blog.layout';
import InviteLayout from '@/layouts/Invite.layout';
import OnboardingLayout from '@/layouts/Onboarding.layout';
import Script from 'next/script';


export default function App(props: AppProps) {
    const { Component, pageProps } = props;
    const router = useRouter()
    const [currency, setCurrency] = useState<Currency>("USD")
    // const [platforms, setPlatforms] = useState<Array<Platform> | undefined>(undefined)

    useEffect(() => {
        api.get<Currency>("/public/geo", undefined, undefined, false).then((res) => {
            if (res.data) {
                setCurrency(res.data)
            }
        })
    }, [])

    useEffect(() => {
        const { ref } = router.query
        if (ref) {
            window.localStorage.setItem("ref", ref as string)
        }
    }, [router])

    useEffect(() => {
        if (global?.window) {
            window.localStorage.setItem("currency", currency)
            if (auth.currentUser) {
                updateDoc(doc(firestore, "users", auth.currentUser.uid), {
                    currency
                } as Partial<User>)
            }

        }
    }, [currency])

    useEffect(() => {
        setTimeout(() => {
            console.log("%cSTOP!",
                "color:red; font-size:40px;")
            console.log("%cThis is a browser feature intended for developers. If someone told you to copy and paste something here, it is a scam and will give them access to your AgencyAccess linked accounts.",
                "font-size:25px;")
        }, 10000)
    }, [])
    return (
        <>
            <Head>
                <title>AgencyAccess | Credentials Made Simple</title>
                <script src="https://cdn.jsdelivr.net/gh/greentfrapp/pocoloco@minigl/minigl.js"></script>
                {/* <Script async src='https://widget.senja.io/widget/5458b560-dc56-4136-943f-3fc122d4b701/platform.js' type="text/javascript" /> */}
                {/* <script src="https://widget.senja.io/widget/5458b560-dc56-4136-943f-3fc122d4b701/platform.js" type="text/javascript" async></script> */}
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <script dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5HWZVKHB');`}} />
                {/* <script
                    async
                    type="text/javascript"
                    src="https://widget.senja.io/widget/1f1d4a17-60c0-4f54-b580-86cc5ade8062/platform.js"
                ></script> */}
                <script type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `
                        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                        (function(){
                        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                        s1.async=true;
                        s1.src='https://embed.tawk.to/660356f1a0c6737bd1253303/1hpugn7lv';
                        s1.charset='UTF-8';
                        s1.setAttribute('crossorigin','*');
                        s0.parentNode.insertBefore(s1,s0);
                        })();
                        `}}
                >
                </script>
            </Head>
            <ColorSchemeScript forceColorScheme="light" />
            <MantineProvider
                defaultColorScheme="light"
                forceColorScheme={!router.pathname.startsWith("/i") ? "light" : undefined}
                theme={createTheme({
                    components: {
                        Button: Button.extend({
                            defaultProps: {
                                radius: 10,
                            },
                            styles(theme, props) {
                                const scheme = typeof window !== "undefined" ? document.documentElement.getAttribute('data-mantine-color-scheme') : "light"
                                if ((!props.variant || props.variant === "filled") && !props.disabled && !props.color) return {
                                    root: {
                                        boxShadow: `inset 0 2px #${scheme === "dark" ? "000A" : "fff3"},inset 2px 0 #${scheme === "dark" ? "000A" : "fff3"},inset -2px 0 #fff3,inset 0 -1px 0 1px #${scheme === "dark" ? "fff" : "000"},inset 0 1px #${scheme === "dark" ? "fff" : "000"}`
                                    }
                                }
                                if (props.variant === "default") return {
                                    root: {
                                        // boxShadow: "1px 1px 1px #0001",
                                        background: undefined,
                                        // boxShadow: "inset 0 -2px #0001, inset 2px 0 #0001, inset -2px 0 #0001, inset 0 -1px 0 1px #fff, inset 0 1px #f1f1f1"
                                        boxShadow: `inset 0 2px #${scheme === "dark" ? "FFF1" : "0000"},inset 1.5px -1.5px #${scheme === "dark" ? "FFF1" : "0001"},inset -1px 0 #${scheme === "dark" ? "fff0" : "0001"},inset 0 -1px 0 1px #${scheme === "dark" ? "000" : "fff"}`
                                    }
                                }
                                return {}
                            },

                        }),
                        Input: Input.extend({
                            defaultProps: {
                                radius: 8
                            },
                            styles: () => {
                                const scheme = typeof window !== "undefined" ? document.documentElement.getAttribute('data-mantine-color-scheme') : "light"
                                return (
                                    {
                                        input: {
                                            boxShadow: `inset 0 2px #${scheme === "dark" ? "252525" : "0000"},inset 2px 0 #${scheme === "dark" ? "252525" : "0001"},inset -2px 0 #${scheme === "dark" ? "000A" : "0001"},inset 0 -1px 0 1px #${scheme === "dark" ? "212121" : "fff"}`
                                            // boxShadow: "inset 0 2px #0000,inset 0px -1px #0001,inset -1px 0 #0001,inset 0 -1px 0 1px #fff,inset 0 1px #fff"
                                        }
                                    }
                                )
                            }
                        }),
                        PasswordInput: Input.extend({
                            defaultProps: {
                                radius: 10
                            }
                        }),
                        NumberInput: NumberInput.extend({
                            defaultProps: {
                                radius: 10
                            }
                        }),
                        Select: Select.extend({
                            defaultProps: {
                                radius: 10
                            },
                            styles: {
                                dropdown: {
                                    zIndex: 100000
                                }
                            }
                        }),
                        ActionIcon: ActionIcon.extend({
                            defaultProps: {
                                variant: "subtle",
                                color: "gray"
                            }
                        }),
                        Alert: Alert.extend({
                            styles: {
                                body: {
                                    marginTop: 0
                                },
                                message: {
                                    marginTop: 0
                                }
                            }
                        }),
                        Modal: Modal.extend({
                            styles: {
                                header: {
                                    paddingBottom: 5
                                }
                            }
                        }),
                        Checkbox: Checkbox.extend({
                            defaultProps: {
                                radius: 5
                            }
                        })
                    },
                    primaryColor: "dark",
                    defaultRadius: 10,
                    focusClassName: "input-element-focused"
                })}
            >
                <CurrencyContext.Provider value={currency}>
                    <CurrencyControllerContext.Provider value={{ set: (newCurrency: Currency) => setCurrency(newCurrency) }}>
                        <ModalsProvider>
                            <Notifications position="bottom-center" />
                            {router.pathname.startsWith("/portal")
                                ? <Component {...pageProps} />
                                : <>
                                    {router.pathname.startsWith("/dashboard")
                                        ? <DashboardLayout>
                                            {router.pathname.startsWith("/dashboard/onboarding")
                                                ? <OnboardingLayout>
                                                    <Component {...pageProps} />
                                                </OnboardingLayout>
                                                : <Component {...pageProps} />
                                            }

                                        </DashboardLayout>
                                        : <>
                                            {router.pathname.startsWith("/settings")
                                                ? <SettingsLayout>
                                                    <Component {...pageProps} />
                                                </SettingsLayout>
                                                : <>
                                                    {router.pathname.startsWith("/i/")
                                                        ? <InviteLayout>
                                                            <Component {...pageProps} />
                                                        </InviteLayout>
                                                        : <>
                                                            {router.pathname.startsWith("/admin")
                                                                ? <AdminLayout>
                                                                    <Component {...pageProps} />
                                                                </AdminLayout>
                                                                : <DefaultLayout>
                                                                    <>
                                                                        {router.pathname.startsWith("/auth/trial")
                                                                            ? <TrialLayout>
                                                                                <Component {...pageProps} />
                                                                            </TrialLayout>
                                                                            : <Component {...pageProps} />
                                                                        }
                                                                    </>


                                                                </DefaultLayout>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }

                                        </>
                                    }
                                </>
                            }
                        </ModalsProvider>
                    </CurrencyControllerContext.Provider>
                    {/* <PlatformsContext.Provider value={platforms as Array<Platform>}> */}

                </CurrencyContext.Provider>

                {/* </PlatformsContext.Provider> */}
            </MantineProvider>
            {/* <Script src='https://widget.senja.io/widget/1f1d4a17-60c0-4f54-b580-86cc5ade8062/platform.js' type='text/javascript' async />
            <Script src="https://widget.senja.io/widget/6d32a22f-9782-4122-9ca4-4901fbd3e452/platform.js" type="text/javascript" async />
            <Script src="https://widget.senja.io/widget/7f0e05b6-e898-437f-b312-1dc0704386af/platform.js" type="text/javascript" async /> */}

        </>
    );
}
