import { useMediaQuery } from "@mantine/hooks";

export const useScreen = <K extends { [key in string]: string }>(custom?: K) => {
    const smallerDesktop = useMediaQuery('(max-width: 920px)');
    const laptop = useMediaQuery('(max-width: 800px)')
    const largeTablet = useMediaQuery('(max-width: 750px)');
    const largerTablet = useMediaQuery('(max-width: 700px)');
    const mediumTablet = useMediaQuery('(max-width: 600px)');
    const tablet = useMediaQuery('(max-width: 500px)');
    const largeMobile = useMediaQuery('(max-width: 400px)');
    const mobile = useMediaQuery('(max-width: 370px)');

    const customQueries = custom ? Object.keys(custom).reduce((prev, key) => ({ ...prev, [key]: useMediaQuery(`(max-width: ${custom[key]})`) }), {}) : {}
    console.log(customQueries)
    const breakpoints = {
        smallerDesktop,
        largeTablet,
        tablet,
        laptop,
        largeMobile,
        mobile,
        mediumTablet,
        largerTablet
    } as const
    return {
        ...breakpoints,
        ...customQueries
    } as typeof breakpoints & Record<keyof K, boolean>
}