import { Text } from "@mantine/core";
import { AnyService, ClientLanguage, Invite } from "../../../types/global";
import { InlinePageLink } from "@/components/InlineLink";
import MetaAdsCreationGuide from "@/components/invite/assetCreationGuides/MetaAds";
import React from "react";
import { FacebookProductCatalogCreationGuide } from "@/components/invite/assetCreationGuides/FacebookProductCatalog";
import { Plus } from "tabler-icons-react";
import GoogleAnalytics from "@/components/invite/assetCreationGuides/GoogleAnalytics";

export type AssetCreationGuide = {
    video?: string,
    steps: (invite: Invite) => {
        content: Record<ClientLanguage, React.ReactNode>,
        image?: string
    }[]
}

export type CustomAssetCreationGuide = ((props: {
    inviteID: string,
    close: VoidFunction,
    refreshAssets: () => Promise<void>
}) => React.ReactElement)

export const assetCreationGuides = {
    "Facebook Pages": {
        steps: () => [
            {
                content: {
                    English: <Text fz="sm"><InlinePageLink href="https://www.facebook.com/pages/creation/?ref_type=launch_point" newTab>Visit this page</InlinePageLink></Text>,
                    Spanish: <Text fz="sm"><InlinePageLink href="https://www.facebook.com/pages/creation/?ref_type=launch_point" newTab>Visita esta página</InlinePageLink></Text>,
                    Dutch: <Text fz="sm"><InlinePageLink href="https://www.facebook.com/pages/creation/?ref_type=launch_point" newTab>Bezoek deze pagina</InlinePageLink></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Enter a <Text fz="sm" fw={500} span>page name and category</Text>.</Text>,
                    Spanish: <Text fz="sm">Introduce un <Text fz="sm" fw={500} span>nombre de página y una categoría</Text>.</Text>,
                    Dutch: <Text fz="sm">Voer een <Text fz="sm" fw={500} span>paginanaam en een categorie</Text> in.</Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Once you've created your new page, click the button below to continue.</Text>,
                    Spanish: <Text fz="sm">Una vez que hayas creado tu nueva página, haz clic en el botón de abajo para continuar.</Text>,
                    Dutch: <Text fz="sm">Klik op de knop hieronder om door te gaan nadat je je nieuwe pagina hebt gemaakt.</Text>
                }
            }
        ]
    },
    "Meta Ads": {
        steps: (invite) => [
            {
                content: {
                    English: <Text fz="sm"><InlinePageLink href={`https://business.facebook.com/latest/settings/ad_accounts?business_id=${invite.creds.Meta?.business?.id}`} newTab>Visit this page</InlinePageLink></Text>,
                    Spanish: <Text fz="sm"><InlinePageLink href={`https://business.facebook.com/latest/settings/ad_accounts?business_id=${invite.creds.Meta?.business?.id}`} newTab>Visita esta página</InlinePageLink></Text>,
                    Dutch: <Text fz="sm"><InlinePageLink href={`https://business.facebook.com/latest/settings/ad_accounts?business_id=${invite.creds.Meta?.business?.id}`} newTab>Bezoek deze pagina</InlinePageLink></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Click the <strong><Plus size={13} /> Add</strong> button in the top right</Text>,
                    Spanish: <Text fz="sm">Haz clic en el botón <strong><Plus size={13} /> Añadir</strong> en la parte superior derecha</Text>,
                    Dutch: <Text fz="sm">Klik op de knop <strong><Plus size={13} /> Toevoegen</strong> rechtsboven</Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">In the modal, click <strong><Plus size={13} /> Create a new ad account</strong></Text>,
                    Spanish: <Text fz="sm">En la ventana modal, haz clic en <strong><Plus size={13} /> Crear una nueva cuenta publicitaria</strong></Text>,
                    Dutch: <Text fz="sm">Klik in het venster op <strong><Plus size={13} /> Een nieuw advertentieaccount maken</strong></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Fill out the creation form. Once you've created your new ad account, click the button below to continue.</Text>,
                    Spanish: <Text fz="sm">Rellena el formulario de creación. Una vez que hayas creado tu nueva cuenta publicitaria, haz clic en el botón de abajo para continuar.</Text>,
                    Dutch: <Text fz="sm">Vul het aanmaakformulier in. Klik op de knop hieronder om door te gaan nadat je je nieuwe advertentieaccount hebt aangemaakt.</Text>
                }
            }
        ]
    },
    "Meta Pixels": {
        steps: (invite) => [
            {
                content: {
                    English: <Text fz="sm"><InlinePageLink href={`https://business.facebook.com/latest/settings/events_dataset?business_id=${invite.creds.Meta?.business?.id}&selected_asset_type=events-dataset-new`} newTab>Visit this page</InlinePageLink></Text>,
                    Spanish: <Text fz="sm"><InlinePageLink href={`https://business.facebook.com/latest/settings/events_dataset?business_id=${invite.creds.Meta?.business?.id}&selected_asset_type=events-dataset-new`} newTab>Visita esta página</InlinePageLink></Text>,
                    Dutch: <Text fz="sm"><InlinePageLink href={`https://business.facebook.com/latest/settings/events_dataset?business_id=${invite.creds.Meta?.business?.id}&selected_asset_type=events-dataset-new`} newTab>Bezoek deze pagina</InlinePageLink></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Click the <strong><Plus size={13} /> Add</strong> button</Text>,
                    Spanish: <Text fz="sm">Haz clic en el botón <strong><Plus size={13} /> Añadir</strong></Text>,
                    Dutch: <Text fz="sm">Klik op de knop <strong><Plus size={13} /> Toevoegen</strong></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Enter a name for the new pixel</Text>,
                    Spanish: <Text fz="sm">Introduce un nombre para el nuevo píxel</Text>,
                    Dutch: <Text fz="sm">Voer een naam in voor de nieuwe pixel</Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Click the <strong>Create</strong> button</Text>,
                    Spanish: <Text fz="sm">Haz clic en el botón <strong>Crear</strong></Text>,
                    Dutch: <Text fz="sm">Klik op de knop <strong>Maken</strong></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Once you've created your new pixel, click the button below to continue.</Text>,
                    Spanish: <Text fz="sm">Una vez que hayas creado tu nuevo píxel, haz clic en el botón de abajo para continuar.</Text>,
                    Dutch: <Text fz="sm">Klik op de knop hieronder om door te gaan nadat je je nieuwe pixel hebt gemaakt.</Text>
                }
            }
        ]
    },
    "Google Ads MCC": {
        steps: () => [
            {
                content: {
                    English: <Text fz="sm"><InlinePageLink href={`https://ads.google.com/nav/selectaccount`} newTab>Visit this page</InlinePageLink></Text>,
                    Spanish: <Text fz="sm"><InlinePageLink href={`https://ads.google.com/nav/selectaccount`} newTab>Visita esta página</InlinePageLink></Text>,
                    Dutch: <Text fz="sm"><InlinePageLink href={`https://ads.google.com/nav/selectaccount`} newTab>Bezoek deze pagina</InlinePageLink></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Click the <strong>New Google Ads Account</strong> button</Text>,
                    Spanish: <Text fz="sm">Haz clic en el botón <strong>Nueva cuenta de Google Ads</strong></Text>,
                    Dutch: <Text fz="sm">Klik op de knop <strong>Nieuw Google Ads-account</strong></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Fill out the creation form. Once you've created your new ad account, click the button below to continue.</Text>,
                    Spanish: <Text fz="sm">Rellena el formulario de creación. Una vez que hayas creado tu nueva cuenta publicitaria, haz clic en el botón de abajo para continuar.</Text>,
                    Dutch: <Text fz="sm">Vul het aanmaakformulier in. Klik op de knop hieronder om door te gaan nadat je je nieuwe advertentieaccount hebt aangemaakt.</Text>
                }
            }
        ]
    },
    "Google Ads": {
        steps: () => [
            {
                content: {
                    English: <Text fz="sm"><InlinePageLink href={`https://ads.google.com/nav/selectaccount`} newTab>Visit this page</InlinePageLink></Text>,
                    Spanish: <Text fz="sm"><InlinePageLink href={`https://ads.google.com/nav/selectaccount`} newTab>Visita esta página</InlinePageLink></Text>,
                    Dutch: <Text fz="sm"><InlinePageLink href={`https://ads.google.com/nav/selectaccount`} newTab>Bezoek deze pagina</InlinePageLink></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Click the <strong>New Google Ads Account</strong> button</Text>,
                    Spanish: <Text fz="sm">Haz clic en el botón <strong>Nueva cuenta de Google Ads</strong></Text>,
                    Dutch: <Text fz="sm">Klik op de knop <strong>Nieuw Google Ads-account</strong></Text>
                }
            },
            {
                content: {
                    English: <Text fz="sm">Fill out the creation form. Once you've created your new ad account, click the button below to continue.</Text>,
                    Spanish: <Text fz="sm">Rellena el formulario de creación. Una vez que hayas creado tu nueva cuenta publicitaria, haz clic en el botón de abajo para continuar.</Text>,
                    Dutch: <Text fz="sm">Vul het aanmaakformulier in. Klik op de knop hieronder om door te gaan nadat je je nieuwe advertentieaccount hebt aangemaakt.</Text>
                }
            }
        ]
    },
    "Google Analytics": GoogleAnalytics,
    "Facebook Product Catalog": FacebookProductCatalogCreationGuide,
} as {
        [key in AnyService]?: CustomAssetCreationGuide | AssetCreationGuide
    }