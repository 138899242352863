import { CustomAssetCreationGuide } from '@/static/assetCreationGuides'
import { Box, Button, Select, Text, TextInput } from '@mantine/core'
import React, { useState } from 'react'
import BaseAssetCreationGuide from './BaseAssetCreationGuide'
import api from '@/utils/api'
import { notifications } from '@mantine/notifications'
import { regions } from '@/static/regions'

export default (function GoogleAnalyticsCreationGuide({ close, inviteID, refreshAssets }) {
    const [name, setName] = useState("")
    const [region, setRegion] = useState<string | null | undefined>("")
    const [loading, setLoading] = useState(false)
    const [waiting, setWaiting] = useState(false)

    if (waiting) {
        return (
            <Box my={20} className='flex aic jcc fdc'>
                <Text fz="xl" className='wave'>{"👋"}</Text>
                <Text fw={600}>Finished setting up?</Text>
                <Text fz="sm" c="dimmed">Click the button below to continue.</Text>
                <Button loading={loading} mt={5} onClick={async () => {
                    setLoading(true)
                    await refreshAssets()
                    setLoading(false)
                    close()
                }}>Done</Button>
            </Box>
        )
    }

    return (
        <BaseAssetCreationGuide close={close} loading={loading} disabled={!name || !region} onSubmit={() => {
            setLoading(true)
            api.post<string>(`/accounts/client/google/analytics/accounts/create`, {
                name,
                region,
                inviteID
            }).then((res) => {
                if (res.error) {
                    notifications.show({
                        color: "red",
                        title: "Error",
                        message: res.msg
                    })
                    setLoading(false)
                    return;
                }
                window.open(res.data)
                setLoading(false)
                setWaiting(true)
            })
        }}>
            <TextInput placeholder='My analytics account' label="Account Name" value={name} onChange={(e) => setName(e.target.value)} />
            <Select searchable label="Region" placeholder='United States' data={regions.map((r) => ({ ...r, label: r.label.split(" ").map((s) => `${s[0].toUpperCase()}${s.toLowerCase().substring(1, s.length)}`).join(" ") }))} value={region} onChange={setRegion} />
            <Text fz="xs" c="dimmed">A new tab will be opened with a form where you can finish setting up your new Google Analytics account. Once finished, return to this page.</Text>
        </BaseAssetCreationGuide>
    )
}) as CustomAssetCreationGuide
