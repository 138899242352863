import { Logo } from '@/components/Logo'
import { useScreen } from '@/hooks/useScreen'
import { Avatar, Badge, Box, Divider, Paper, Rating, Text, useMantineTheme } from '@mantine/core'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

// @ts-ignore
const MotionComponent = dynamic(() => import('framer-motion').then(module => module.motion.div))
// @ts-ignore
const AnimatePresenceComponent = dynamic(() => import('framer-motion').then(module => module.AnimatePresence));

const ReviewsCycle = () => {
    const reviews = [
        {
            review: "AgencyAccess has revolutionized the way we onboard clients. The customization options are fantastic, and the security measures put our minds at ease. It's a must-have tool for agencies.",
            stars: 5,
            person: {
                name: "Bernt Muurling",
                job: "Co-founder at Taskforce Agency",
                icon: "/images/landing_page/bernt.jpeg"
            }
        },
        {
            review: "Using AgencyAccess has significantly improved our efficiency. The link preview feature is a game-changer, and the fact that they don't access our data shows their commitment to privacy.",
            stars: 5,
            person: {
                name: "Ruben Runneboom",
                job: "Co-founder at Taskforce Agency",
                icon: "/images/landing_page/ruben.jpeg"
            }
        },
        // {
        //     review: "AgencyAccess has been a productivity booster for our agency. The branding options help us stand out, and the ease of sending links is fantastic. It's become an essential part of our workflow.",
        //     stars: 5,
        //     person: {
        //         name: "Person Name",
        //         job: "Head of Marketing",
        //         icon: ""
        //     }
        // }

    ] as Array<{
        review: string,
        stars: number,
        person: {
            name: string,
            icon: string,
            job: string
        }
    }>
    const [activeReview, setActiveReview] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (reviews[activeReview + 1]) {
                setActiveReview(activeReview + 1)
            } else {
                setActiveReview(0)
            }
        }, 5000)
    }, [activeReview])
    if (!AnimatePresenceComponent || !MotionComponent) {
        return null
    }
    return (
        <div style={{ marginBottom: 30 }}>
            <AnimatePresenceComponent mode="wait">
                {/* @ts-ignore */}
                <MotionComponent
                    key={activeReview}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <Box style={{ backdropFilter: "blur(20px)", borderRadius: 15, background: "rgba(255,255,255,.1)" }} p="lg">
                        <Rating value={5} readOnly />
                        <Text mt={5} fz="lg" fw={500} c="#fff">
                            <Text c="#fff" fz={"lg"} style={{ textShadow: "0px 0px 0px #fff" }} fw={900} span>“</Text>
                            {reviews[activeReview].review}
                            <Text c="#fff" fz={"lg"} style={{ textShadow: "0px 0px 0px #fff" }} fw={900} span>”</Text>
                        </Text>
                        <div style={{ marginTop: 10, gap: 10 }} className="flex" >
                            <Avatar variant={"filled"} radius={100} src={reviews[activeReview].person.icon} />
                            <div>
                                <Text fw={600} c="#fff">{reviews[activeReview].person.name}</Text>
                                <Text c="rgba(255,255,255,.5)" fz={"sm"}>{reviews[activeReview].person.job}</Text>
                            </div>
                        </div>
                    </Box>
                </MotionComponent>
            </AnimatePresenceComponent>

        </div>
    );
}

const LegalLinks = () => (
    <>
        <Text fz="xs" c="dimmed" component={Link} href={`/legal/terms`}>Terms and conditions</Text>
        <Divider orientation='vertical' opacity={.65} />
        <Text fz="xs" c="dimmed" component={Link} href={`/legal/privacy`}>Privacy Policy</Text>
    </>
)

export default function AuthLayout({ children }: { children: React.ReactNode }) {
    const { screenWidth, mobile } = useScreen({
        screenWidth: "500px"
    })
    const { ref: containerRef, height } = useElementSize()
    useEffect(() => {
        try {
            // @ts-ignore
            var gradient = new Gradient();
            gradient.initGradient("#canvas");
        } catch (err) {
            void 0
        }
    }, [])
    return (
        <>
            <div>
                <Box style={{ position: 'relative', height: 200, overflow: "hidden", width: "100%" }}>
                    <canvas id="canvas" style={{ left: 0, top: 0, minHeight: "100vh" }} />
                </Box>

                <Paper shadow={screenWidth ? "none" : 'xs'} w={screenWidth ? "100%" : 500} mx="auto" p="lg" style={{ flex: 1, zIndex: 300, height: height + 40, position: "relative", bottom: 90, transition: "all .3s" }} >
                    <div ref={containerRef}>
                        {children}
                    </div>
                </Paper>
                <Box className={`flex jcc aic ${mobile ? "fdc" : ""}`} style={{ gap: 10, position: "relative", bottom: 90 }} mt={20}>
                    <Box component={Link} href={`/`} style={{ filter: "grayscale(100%)", height: "fit-content", opacity: .75 }}>
                        <Logo width={120} />
                    </Box>
                    {!mobile
                        ? <>
                            <LegalLinks />
                            <Divider orientation='vertical' opacity={.65} />
                        </>
                        : <Box className='flex aic jcc' style={{ gap: 10 }}>
                            <LegalLinks />
                        </Box>
                    }

                </Box>
            </div>
        </>
    )
}
