import { Box, Text } from '@mantine/core'
import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'
import { Logo } from '../Logo'
import { ThemeContext } from '@/utils/context/Theme.context'
import lang from '@/../public/translations/invite.json'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { InviteContext } from '@/utils/context/Invite.context'
import { Agency, ClientLanguage, Plans } from '../../../../types/global'
import api from '@/utils/api'

export default function Footer({ overwriteLang, overwriteEmail }: { overwriteLang?: ClientLanguage, overwriteEmail?: string }) {
    const theme = useContext(ThemeContext)
    const invite = useContext(InviteContext)
    const client = useContext(InviteClientContext)
    const l = overwriteLang || client?.lang || "English"
    const [plan, setPlan] = useState<Plans | undefined>(undefined)

    useEffect(() => {
        if (!invite || invite.DEMO) return;
        api.get<Partial<Agency>>(`/public/agency?id=${invite.agency.id}`).then((res) => {
            setPlan(res.data.plan)
        })
    }, [invite])

    return (
        <>
            <Box mt={20}>
                <Text fw={600} fz={"sm"} c="dimmed">{lang.footer.help[l]} <Text target="_blank" href={`mailto:${overwriteEmail || invite?.agency?.email || "kevin@agencyaccess.co"}`} span fw={600} fz="sm" c="blue" className='cp' component={Link}>{overwriteEmail || invite?.agency?.email || "kevin@agencyaccess.co"}</Text></Text>
            </Box>
            <div style={{ paddingBottom: 30, marginTop: 30 }} className="flex aic jcc fdc">
                <Text c="dimmed" fz={"sm"}>{lang.footer.poweredBy[l]}</Text>
                <Link href={"https://agencyaccess.co"}>
                    <div style={{ filter: "grayscale(100%)" }}>
                        <Logo dark={theme.theme === "dark"} width={150} />
                    </div>
                </Link>
                {plan && plan === "Free"
                    ? <Link href={"https://agencyaccess.co"}>
                        <Text fz="xs" c="dimmed" style={{ textDecoration: "underline" }}>{lang.footer.ad[l]}</Text>
                    </Link>
                    : null
                }
            </div>
        </>
    )
}
