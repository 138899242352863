import { doc, getDoc, onSnapshot, Unsubscribe } from "firebase/firestore"
import { useEffect, useState } from "react"
import { AccountTypes, AnyService, Invite } from "../../../../types/global"
import { firestore } from "../firebase"
import getServices from "../data/getInviteRequestedServices"

export const getGrantedStatus = (invite: Invite, accountType: AccountTypes) => {
    const unfulfilledAccountsArray = (Object.keys(invite.requestedAccounts[accountType]) as Array<AnyService>).filter((acc) => !invite.requestedAccounts[accountType][acc].granted)
    return !unfulfilledAccountsArray.length
}

export const getInviteGrantedStatus = (invite?: Invite) => {
    if (!invite) return false
    const unfulfilledServices = getServices(invite.requestedAccounts).filter(s => {
        const platform = Object.keys(invite.requestedAccounts).find((p) => Object.keys(invite.requestedAccounts[p as AccountTypes]).includes(s)) as AccountTypes
        const service = invite.requestedAccounts[platform][s]
        return !service.granted
    })

    console.log("US:", unfulfilledServices)
    console.log("Returning:", unfulfilledServices.length > 0 ? false : true)

    return unfulfilledServices.length > 0 ? false : true
    // const unfulfilledAccountsArray = Object.keys(invite.requestedAccounts).filter((reqAcc: unknown) => {
    //     return !!(((Object.keys(invite.requestedAccounts[reqAcc as AccountTypes]) as Array<AnyService>).filter((acc) => !invite.requestedAccounts[reqAcc as AccountTypes][acc].granted)).length)
    // })
    // return !unfulfilledAccountsArray.length
}

export const useInviteGrantStatus = (inviteID?: string, inviteData?: Invite) => {
    const [granted, setGranted] = useState(getInviteGrantedStatus(inviteData))

    useEffect(() => {
        let listener: Unsubscribe
        if (!inviteData?.DEMO) {
            if (!inviteID) return setGranted(false);
            listener = onSnapshot(doc(firestore, "invites", inviteID), (snap) => {
                if (!snap.exists()) return;
                setGranted(getInviteGrantedStatus(snap.data() as Invite))
            })

        }
        setGranted(getInviteGrantedStatus(inviteData))
        return () => {
            if (!listener) return;
            listener()
        }
    }, [inviteData])

    return granted
}