export const metaGraphTimezones = [
    {
        "value": 0,
        "label": "UNKNOWN"
    },
    {
        "value": 1,
        "label": "AMERICA_LOS_ANGELES"
    },
    {
        "value": 2,
        "label": "AMERICA_DENVER"
    },
    {
        "value": 3,
        "label": "PACIFIC_HONOLULU"
    },
    {
        "value": 4,
        "label": "AMERICA_ANCHORAGE"
    },
    {
        "value": 5,
        "label": "AMERICA_PHOENIX"
    },
    {
        "value": 6,
        "label": "AMERICA_CHICAGO"
    },
    {
        "value": 7,
        "label": "AMERICA_NEW_YORK"
    },
    {
        "value": 8,
        "label": "ASIA_DUBAI"
    },
    {
        "value": 9,
        "label": "AMERICA_ARGENTINA_SAN_LUIS"
    },
    {
        "value": 10,
        "label": "AMERICA_ARGENTINA_BUENOS_AIRES"
    },
    {
        "value": 11,
        "label": "AMERICA_ARGENTINA_SALTA"
    },
    {
        "value": 12,
        "label": "EUROPE_VIENNA"
    },
    {
        "value": 13,
        "label": "AUSTRALIA_PERTH"
    },
    {
        "value": 14,
        "label": "AUSTRALIA_BROKEN_HILL"
    },
    {
        "value": 15,
        "label": "AUSTRALIA_SYDNEY"
    },
    {
        "value": 16,
        "label": "EUROPE_SARAJEVO"
    },
    {
        "value": 17,
        "label": "ASIA_DHAKA"
    },
    {
        "value": 18,
        "label": "EUROPE_BRUSSELS"
    },
    {
        "value": 19,
        "label": "EUROPE_SOFIA"
    },
    {
        "value": 20,
        "label": "ASIA_BAHRAIN"
    },
    {
        "value": 21,
        "label": "AMERICA_LA_PAZ"
    },
    {
        "value": 22,
        "label": "AMERICA_NORONHA"
    },
    {
        "value": 23,
        "label": "AMERICA_CAMPO_GRANDE"
    },
    {
        "value": 24,
        "label": "AMERICA_BELEM"
    },
    {
        "value": 25,
        "label": "AMERICA_SAO_PAULO"
    },
    {
        "value": 26,
        "label": "AMERICA_NASSAU"
    },
    {
        "value": 27,
        "label": "AMERICA_DAWSON"
    },
    {
        "value": 28,
        "label": "AMERICA_VANCOUVER"
    },
    {
        "value": 29,
        "label": "AMERICA_DAWSON_CREEK"
    },
    {
        "value": 30,
        "label": "AMERICA_EDMONTON"
    },
    {
        "value": 31,
        "label": "AMERICA_RAINY_RIVER"
    },
    {
        "value": 32,
        "label": "AMERICA_REGINA"
    },
    {
        "value": 33,
        "label": "AMERICA_ATIKOKAN"
    },
    {
        "value": 34,
        "label": "AMERICA_IQALUIT"
    },
    {
        "value": 35,
        "label": "AMERICA_TORONTO"
    },
    {
        "value": 36,
        "label": "AMERICA_BLANC_SABLON"
    },
    {
        "value": 37,
        "label": "AMERICA_HALIFAX"
    },
    {
        "value": 38,
        "label": "AMERICA_ST_JOHNS"
    },
    {
        "value": 39,
        "label": "EUROPE_ZURICH"
    },
    {
        "value": 40,
        "label": "PACIFIC_EASTER"
    },
    {
        "value": 41,
        "label": "AMERICA_SANTIAGO"
    },
    {
        "value": 42,
        "label": "ASIA_SHANGHAI"
    },
    {
        "value": 43,
        "label": "AMERICA_BOGOTA"
    },
    {
        "value": 44,
        "label": "AMERICA_COSTA_RICA"
    },
    {
        "value": 45,
        "label": "ASIA_NICOSIA"
    },
    {
        "value": 46,
        "label": "EUROPE_PRAGUE"
    },
    {
        "value": 47,
        "label": "EUROPE_BERLIN"
    },
    {
        "value": 48,
        "label": "EUROPE_COPENHAGEN"
    },
    {
        "value": 49,
        "label": "AMERICA_SANTO_DOMINGO"
    },
    {
        "value": 50,
        "label": "PACIFIC_GALAPAGOS"
    },
    {
        "value": 51,
        "label": "AMERICA_GUAYAQUIL"
    },
    {
        "value": 52,
        "label": "EUROPE_TALLINN"
    },
    {
        "value": 53,
        "label": "AFRICA_CAIRO"
    },
    {
        "value": 54,
        "label": "ATLANTIC_CANARY"
    },
    {
        "value": 55,
        "label": "EUROPE_MADRID"
    },
    {
        "value": 56,
        "label": "EUROPE_HELSINKI"
    },
    {
        "value": 57,
        "label": "EUROPE_PARIS"
    },
    {
        "value": 58,
        "label": "EUROPE_LONDON"
    },
    {
        "value": 59,
        "label": "AFRICA_ACCRA"
    },
    {
        "value": 60,
        "label": "EUROPE_ATHENS"
    },
    {
        "value": 61,
        "label": "AMERICA_GUATEMALA"
    },
    {
        "value": 62,
        "label": "ASIA_HONG_KONG"
    },
    {
        "value": 63,
        "label": "AMERICA_TEGUCIGALPA"
    },
    {
        "value": 64,
        "label": "EUROPE_ZAGREB"
    },
    {
        "value": 65,
        "label": "EUROPE_BUDAPEST"
    },
    {
        "value": 66,
        "label": "ASIA_JAKARTA"
    },
    {
        "value": 67,
        "label": "ASIA_MAKASSAR"
    },
    {
        "value": 68,
        "label": "ASIA_JAYAPURA"
    },
    {
        "value": 69,
        "label": "EUROPE_DUBLIN"
    },
    {
        "value": 70,
        "label": "ASIA_JERUSALEM"
    },
    {
        "value": 71,
        "label": "ASIA_KOLKATA"
    },
    {
        "value": 72,
        "label": "ASIA_BAGHDAD"
    },
    {
        "value": 73,
        "label": "ATLANTIC_REYKJAVIK"
    },
    {
        "value": 74,
        "label": "EUROPE_ROME"
    },
    {
        "value": 75,
        "label": "AMERICA_JAMAICA"
    },
    {
        "value": 76,
        "label": "ASIA_AMMAN"
    },
    {
        "value": 77,
        "label": "ASIA_TOKYO"
    },
    {
        "value": 78,
        "label": "AFRICA_NAIROBI"
    },
    {
        "value": 79,
        "label": "ASIA_SEOUL"
    },
    {
        "value": 80,
        "label": "ASIA_KUWAIT"
    },
    {
        "value": 81,
        "label": "ASIA_BEIRUT"
    },
    {
        "value": 82,
        "label": "ASIA_COLOMBO"
    },
    {
        "value": 83,
        "label": "EUROPE_VILNIUS"
    },
    {
        "value": 84,
        "label": "EUROPE_LUXEMBOURG"
    },
    {
        "value": 85,
        "label": "EUROPE_RIGA"
    },
    {
        "value": 86,
        "label": "AFRICA_CASABLANCA"
    },
    {
        "value": 87,
        "label": "EUROPE_SKOPJE"
    },
    {
        "value": 88,
        "label": "EUROPE_MALTA"
    },
    {
        "value": 89,
        "label": "INDIAN_MAURITIUS"
    },
    {
        "value": 90,
        "label": "INDIAN_MALDIVES"
    },
    {
        "value": 91,
        "label": "AMERICA_TIJUANA"
    },
    {
        "value": 92,
        "label": "AMERICA_HERMOSILLO"
    },
    {
        "value": 93,
        "label": "AMERICA_MAZATLAN"
    },
    {
        "value": 94,
        "label": "AMERICA_MEXICO_CITY"
    },
    {
        "value": 95,
        "label": "ASIA_KUALA_LUMPUR"
    },
    {
        "value": 96,
        "label": "AFRICA_LAGOS"
    },
    {
        "value": 97,
        "label": "AMERICA_MANAGUA"
    },
    {
        "value": 98,
        "label": "EUROPE_AMSTERDAM"
    },
    {
        "value": 99,
        "label": "EUROPE_OSLO"
    },
    {
        "value": 100,
        "label": "PACIFIC_AUCKLAND"
    },
    {
        "value": 101,
        "label": "ASIA_MUSCAT"
    },
    {
        "value": 102,
        "label": "AMERICA_PANAMA"
    },
    {
        "value": 103,
        "label": "AMERICA_LIMA"
    },
    {
        "value": 104,
        "label": "ASIA_MANILA"
    },
    {
        "value": 105,
        "label": "ASIA_KARACHI"
    },
    {
        "value": 106,
        "label": "EUROPE_WARSAW"
    },
    {
        "value": 107,
        "label": "AMERICA_PUERTO_RICO"
    },
    {
        "value": 108,
        "label": "ASIA_GAZA"
    },
    {
        "value": 109,
        "label": "ATLANTIC_AZORES"
    },
    {
        "value": 110,
        "label": "EUROPE_LISBON"
    },
    {
        "value": 111,
        "label": "AMERICA_ASUNCION"
    },
    {
        "value": 112,
        "label": "ASIA_QATAR"
    },
    {
        "value": 113,
        "label": "EUROPE_BUCHAREST"
    },
    {
        "value": 114,
        "label": "EUROPE_BELGRADE"
    },
    {
        "value": 115,
        "label": "EUROPE_KALININGRAD"
    },
    {
        "value": 116,
        "label": "EUROPE_MOSCOW"
    },
    {
        "value": 117,
        "label": "EUROPE_SAMARA"
    },
    {
        "value": 118,
        "label": "ASIA_YEKATERINBURG"
    },
    {
        "value": 119,
        "label": "ASIA_OMSK"
    },
    {
        "value": 120,
        "label": "ASIA_KRASNOYARSK"
    },
    {
        "value": 121,
        "label": "ASIA_IRKUTSK"
    },
    {
        "value": 122,
        "label": "ASIA_YAKUTSK"
    },
    {
        "value": 123,
        "label": "ASIA_VLADIVOSTOK"
    },
    {
        "value": 124,
        "label": "ASIA_MAGADAN"
    },
    {
        "value": 125,
        "label": "ASIA_KAMCHATKA"
    },
    {
        "value": 126,
        "label": "ASIA_RIYADH"
    },
    {
        "value": 127,
        "label": "EUROPE_STOCKHOLM"
    },
    {
        "value": 128,
        "label": "ASIA_SINGAPORE"
    },
    {
        "value": 129,
        "label": "EUROPE_LJUBLJANA"
    },
    {
        "value": 130,
        "label": "EUROPE_BRATISLAVA"
    },
    {
        "value": 131,
        "label": "AMERICA_EL_SALVADOR"
    },
    {
        "value": 132,
        "label": "ASIA_BANGKOK"
    },
    {
        "value": 133,
        "label": "AFRICA_TUNIS"
    },
    {
        "value": 134,
        "label": "EUROPE_ISTANBUL"
    },
    {
        "value": 135,
        "label": "AMERICA_PORT_OF_SPAIN"
    },
    {
        "value": 136,
        "label": "ASIA_TAIPEI"
    },
    {
        "value": 137,
        "label": "EUROPE_KIEV"
    },
    {
        "value": 138,
        "label": "AMERICA_MONTEVIDEO"
    },
    {
        "value": 139,
        "label": "AMERICA_CARACAS"
    },
    {
        "value": 140,
        "label": "ASIA_HO_CHI_MINH"
    },
    {
        "value": 141,
        "label": "AFRICA_JOHANNESBURG"
    },
    {
        "value": 142,
        "label": "AMERICA_WINNIPEG"
    },
    {
        "value": 143,
        "label": "AMERICA_DETROIT"
    },
    {
        "value": 144,
        "label": "AUSTRALIA_MELBOURNE"
    },
    {
        "value": 145,
        "label": "ASIA_KATHMANDU"
    },
    {
        "value": 146,
        "label": "ASIA_BAKU"
    },
    {
        "value": 147,
        "label": "AFRICA_ABIDJAN"
    },
    {
        "value": 148,
        "label": "AFRICA_ADDIS_ABABA"
    },
    {
        "value": 149,
        "label": "AFRICA_ALGIERS"
    },
    {
        "value": 150,
        "label": "AFRICA_ASMARA"
    },
    {
        "value": 151,
        "label": "AFRICA_BAMAKO"
    },
    {
        "value": 152,
        "label": "AFRICA_BANGUI"
    },
    {
        "value": 153,
        "label": "AFRICA_BANJUL"
    },
    {
        "value": 154,
        "label": "AFRICA_BISSAU"
    },
    {
        "value": 155,
        "label": "AFRICA_BLANTYRE"
    },
    {
        "value": 156,
        "label": "AFRICA_BRAZZAVILLE"
    },
    {
        "value": 157,
        "label": "AFRICA_BUJUMBURA"
    },
    {
        "value": 158,
        "label": "AFRICA_CEUTA"
    },
    {
        "value": 159,
        "label": "AFRICA_CONAKRY"
    },
    {
        "value": 160,
        "label": "AFRICA_DAKAR"
    },
    {
        "value": 161,
        "label": "AFRICA_DAR_ES_SALAAM"
    },
    {
        "value": 162,
        "label": "AFRICA_DJIBOUTI"
    },
    {
        "value": 163,
        "label": "AFRICA_DOUALA"
    },
    {
        "value": 164,
        "label": "AFRICA_EL_AAIUN"
    },
    {
        "value": 165,
        "label": "AFRICA_FREETOWN"
    },
    {
        "value": 166,
        "label": "AFRICA_GABORONE"
    },
    {
        "value": 167,
        "label": "AFRICA_HARARE"
    },
    {
        "value": 168,
        "label": "AFRICA_JUBA"
    },
    {
        "value": 169,
        "label": "AFRICA_KAMPALA"
    },
    {
        "value": 170,
        "label": "AFRICA_KHARTOUM"
    },
    {
        "value": 171,
        "label": "AFRICA_KIGALI"
    },
    {
        "value": 172,
        "label": "AFRICA_KINSHASA"
    },
    {
        "value": 173,
        "label": "AFRICA_LIBREVILLE"
    },
    {
        "value": 174,
        "label": "AFRICA_LOME"
    },
    {
        "value": 175,
        "label": "AFRICA_LUANDA"
    },
    {
        "value": 176,
        "label": "AFRICA_LUBUMBASHI"
    },
    {
        "value": 177,
        "label": "AFRICA_LUSAKA"
    },
    {
        "value": 178,
        "label": "AFRICA_MALABO"
    },
    {
        "value": 179,
        "label": "AFRICA_MAPUTO"
    },
    {
        "value": 180,
        "label": "AFRICA_MASERU"
    },
    {
        "value": 181,
        "label": "AFRICA_MBABANE"
    },
    {
        "value": 182,
        "label": "AFRICA_MOGADISHU"
    },
    {
        "value": 183,
        "label": "AFRICA_MONROVIA"
    },
    {
        "value": 184,
        "label": "AFRICA_NDJAMENA"
    },
    {
        "value": 185,
        "label": "AFRICA_NIAMEY"
    },
    {
        "value": 186,
        "label": "AFRICA_NOUAKCHOTT"
    },
    {
        "value": 187,
        "label": "AFRICA_OUAGADOUGOU"
    },
    {
        "value": 188,
        "label": "AFRICA_PORTO_NOVO"
    },
    {
        "value": 189,
        "label": "AFRICA_SAO_TOME"
    },
    {
        "value": 190,
        "label": "AFRICA_TRIPOLI"
    },
    {
        "value": 191,
        "label": "AFRICA_WINDHOEK"
    },
    {
        "value": 192,
        "label": "AMERICA_ADAK"
    },
    {
        "value": 193,
        "label": "AMERICA_ANGUILLA"
    },
    {
        "value": 194,
        "label": "AMERICA_ANTIGUA"
    },
    {
        "value": 195,
        "label": "AMERICA_ARAGUAINA"
    },
    {
        "value": 196,
        "label": "AMERICA_ARGENTINA_CATAMARCA"
    },
    {
        "value": 197,
        "label": "AMERICA_ARGENTINA_CORDOBA"
    },
    {
        "value": 198,
        "label": "AMERICA_ARGENTINA_JUJUY"
    },
    {
        "value": 199,
        "label": "AMERICA_ARGENTINA_LA_RIOJA"
    },
    {
        "value": 200,
        "label": "AMERICA_ARGENTINA_MENDOZA"
    },
    {
        "value": 201,
        "label": "AMERICA_ARGENTINA_RIO_GALLEGOS"
    },
    {
        "value": 202,
        "label": "AMERICA_ARGENTINA_SAN_JUAN"
    },
    {
        "value": 203,
        "label": "AMERICA_ARGENTINA_TUCUMAN"
    },
    {
        "value": 204,
        "label": "AMERICA_ARGENTINA_USHUAIA"
    },
    {
        "value": 205,
        "label": "AMERICA_ARUBA"
    },
    {
        "value": 206,
        "label": "AMERICA_BAHIA"
    },
    {
        "value": 207,
        "label": "AMERICA_BAHIA_BANDERAS"
    },
    {
        "value": 208,
        "label": "AMERICA_BARBADOS"
    },
    {
        "value": 209,
        "label": "AMERICA_BELIZE"
    },
    {
        "value": 210,
        "label": "AMERICA_BOA_VISTA"
    },
    {
        "value": 211,
        "label": "AMERICA_BOISE"
    },
    {
        "value": 212,
        "label": "AMERICA_CAMBRIDGE_BAY"
    },
    {
        "value": 213,
        "label": "AMERICA_CANCUN"
    },
    {
        "value": 214,
        "label": "AMERICA_CAYENNE"
    },
    {
        "value": 215,
        "label": "AMERICA_CAYMAN"
    },
    {
        "value": 216,
        "label": "AMERICA_CHIHUAHUA"
    },
    {
        "value": 217,
        "label": "AMERICA_CRESTON"
    },
    {
        "value": 218,
        "label": "AMERICA_CUIABA"
    },
    {
        "value": 219,
        "label": "AMERICA_CURACAO"
    },
    {
        "value": 220,
        "label": "AMERICA_DANMARKSHAVN"
    },
    {
        "value": 221,
        "label": "AMERICA_DOMINICA"
    },
    {
        "value": 222,
        "label": "AMERICA_EIRUNEPE"
    },
    {
        "value": 223,
        "label": "AMERICA_FORT_NELSON"
    },
    {
        "value": 224,
        "label": "AMERICA_FORTALEZA"
    },
    {
        "value": 225,
        "label": "AMERICA_GLACE_BAY"
    },
    {
        "value": 226,
        "label": "AMERICA_GODTHAB"
    },
    {
        "value": 227,
        "label": "AMERICA_GOOSE_BAY"
    },
    {
        "value": 228,
        "label": "AMERICA_GRAND_TURK"
    },
    {
        "value": 229,
        "label": "AMERICA_GRENADA"
    },
    {
        "value": 230,
        "label": "AMERICA_GUADELOUPE"
    },
    {
        "value": 231,
        "label": "AMERICA_GUYANA"
    },
    {
        "value": 232,
        "label": "AMERICA_HAVANA"
    },
    {
        "value": 233,
        "label": "AMERICA_INDIANA_INDIANAPOLIS"
    },
    {
        "value": 234,
        "label": "AMERICA_INDIANA_KNOX"
    },
    {
        "value": 235,
        "label": "AMERICA_INDIANA_MARENGO"
    },
    {
        "value": 236,
        "label": "AMERICA_INDIANA_PETERSBURG"
    },
    {
        "value": 237,
        "label": "AMERICA_INDIANA_TELL_CITY"
    },
    {
        "value": 238,
        "label": "AMERICA_INDIANA_VEVAY"
    },
    {
        "value": 239,
        "label": "AMERICA_INDIANA_VINCENNES"
    },
    {
        "value": 240,
        "label": "AMERICA_INDIANA_WINAMAC"
    },
    {
        "value": 241,
        "label": "AMERICA_INDIANAPOLIS"
    },
    {
        "value": 242,
        "label": "AMERICA_INUVIK"
    },
    {
        "value": 243,
        "label": "AMERICA_JUNEAU"
    },
    {
        "value": 244,
        "label": "AMERICA_KENTUCKY_LOUISVILLE"
    },
    {
        "value": 245,
        "label": "AMERICA_KENTUCKY_MONTICELLO"
    },
    {
        "value": 246,
        "label": "AMERICA_KRALENDIJK"
    },
    {
        "value": 247,
        "label": "AMERICA_LOWER_PRINCES"
    },
    {
        "value": 248,
        "label": "AMERICA_MACEIO"
    },
    {
        "value": 249,
        "label": "AMERICA_MANAUS"
    },
    {
        "value": 250,
        "label": "AMERICA_MARIGOT"
    },
    {
        "value": 251,
        "label": "AMERICA_MARTINIQUE"
    },
    {
        "value": 252,
        "label": "AMERICA_MATAMOROS"
    },
    {
        "value": 253,
        "label": "AMERICA_MENOMINEE"
    },
    {
        "value": 254,
        "label": "AMERICA_MERIDA"
    },
    {
        "value": 255,
        "label": "AMERICA_METLAKATLA"
    },
    {
        "value": 256,
        "label": "AMERICA_MIQUELON"
    },
    {
        "value": 257,
        "label": "AMERICA_MONCTON"
    },
    {
        "value": 258,
        "label": "AMERICA_MONTERREY"
    },
    {
        "value": 259,
        "label": "AMERICA_MONTREAL"
    },
    {
        "value": 260,
        "label": "AMERICA_MONTSERRAT"
    },
    {
        "value": 261,
        "label": "AMERICA_NIPIGON"
    },
    {
        "value": 262,
        "label": "AMERICA_NOME"
    },
    {
        "value": 263,
        "label": "AMERICA_NORTH_DAKOTA_BEULAH"
    },
    {
        "value": 264,
        "label": "AMERICA_NORTH_DAKOTA_CENTER"
    },
    {
        "value": 265,
        "label": "AMERICA_NORTH_DAKOTA_NEW_SALEM"
    },
    {
        "value": 266,
        "label": "AMERICA_OJINAGA"
    },
    {
        "value": 267,
        "label": "AMERICA_PANGNIRTUNG"
    },
    {
        "value": 268,
        "label": "AMERICA_PARAMARIBO"
    },
    {
        "value": 269,
        "label": "AMERICA_PORT_AU_PRINCE"
    },
    {
        "value": 270,
        "label": "AMERICA_PORTO_VELHO"
    },
    {
        "value": 271,
        "label": "AMERICA_PUNTA_ARENAS"
    },
    {
        "value": 272,
        "label": "AMERICA_RANKIN_INLET"
    },
    {
        "value": 273,
        "label": "AMERICA_RECIFE"
    },
    {
        "value": 274,
        "label": "AMERICA_RESOLUTE"
    },
    {
        "value": 275,
        "label": "AMERICA_RIO_BRANCO"
    },
    {
        "value": 276,
        "label": "AMERICA_SANTAREM"
    },
    {
        "value": 277,
        "label": "AMERICA_SCORESBYSUND"
    },
    {
        "value": 278,
        "label": "AMERICA_SITKA"
    },
    {
        "value": 279,
        "label": "AMERICA_ST_BARTHELEMY"
    },
    {
        "value": 280,
        "label": "AMERICA_ST_KITTS"
    },
    {
        "value": 281,
        "label": "AMERICA_ST_LUCIA"
    },
    {
        "value": 282,
        "label": "AMERICA_ST_THOMAS"
    },
    {
        "value": 283,
        "label": "AMERICA_ST_VINCENT"
    },
    {
        "value": 284,
        "label": "AMERICA_SWIFT_CURRENT"
    },
    {
        "value": 285,
        "label": "AMERICA_THULE"
    },
    {
        "value": 286,
        "label": "AMERICA_THUNDER_BAY"
    },
    {
        "value": 287,
        "label": "AMERICA_TORTOLA"
    },
    {
        "value": 288,
        "label": "AMERICA_WHITEHORSE"
    },
    {
        "value": 289,
        "label": "AMERICA_YAKUTAT"
    },
    {
        "value": 290,
        "label": "AMERICA_YELLOWKNIFE"
    },
    {
        "value": 291,
        "label": "ANTARCTICA_CASEY"
    },
    {
        "value": 292,
        "label": "ANTARCTICA_DAVIS"
    },
    {
        "value": 293,
        "label": "ANTARCTICA_DUMONTDURVILLE"
    },
    {
        "value": 294,
        "label": "ANTARCTICA_MACQUARIE"
    },
    {
        "value": 295,
        "label": "ANTARCTICA_MAWSON"
    },
    {
        "value": 296,
        "label": "ANTARCTICA_MCMURDO"
    },
    {
        "value": 297,
        "label": "ANTARCTICA_PALMER"
    },
    {
        "value": 298,
        "label": "ANTARCTICA_ROTHERA"
    },
    {
        "value": 299,
        "label": "ANTARCTICA_SYOWA"
    },
    {
        "value": 300,
        "label": "ANTARCTICA_TROLL"
    },
    {
        "value": 301,
        "label": "ANTARCTICA_VOSTOK"
    },
    {
        "value": 302,
        "label": "ARCTIC_LONGYEARBYEN"
    },
    {
        "value": 303,
        "label": "ASIA_ADEN"
    },
    {
        "value": 304,
        "label": "ASIA_ALMATY"
    },
    {
        "value": 305,
        "label": "ASIA_ANADYR"
    },
    {
        "value": 306,
        "label": "ASIA_AQTAU"
    },
    {
        "value": 307,
        "label": "ASIA_AQTOBE"
    },
    {
        "value": 308,
        "label": "ASIA_ASHGABAT"
    },
    {
        "value": 309,
        "label": "ASIA_ATYRAU"
    },
    {
        "value": 310,
        "label": "ASIA_BARNAUL"
    },
    {
        "value": 311,
        "label": "ASIA_BISHKEK"
    },
    {
        "value": 312,
        "label": "ASIA_BRUNEI"
    },
    {
        "value": 313,
        "label": "ASIA_CHITA"
    },
    {
        "value": 314,
        "label": "ASIA_CHOIBALSAN"
    },
    {
        "value": 315,
        "label": "ASIA_DAMASCUS"
    },
    {
        "value": 316,
        "label": "ASIA_DILI"
    },
    {
        "value": 317,
        "label": "ASIA_DUSHANBE"
    },
    {
        "value": 318,
        "label": "ASIA_FAMAGUSTA"
    },
    {
        "value": 319,
        "label": "ASIA_HEBRON"
    },
    {
        "value": 320,
        "label": "ASIA_HOVD"
    },
    {
        "value": 321,
        "label": "ASIA_ISTANBUL"
    },
    {
        "value": 322,
        "label": "ASIA_KABUL"
    },
    {
        "value": 323,
        "label": "ASIA_KHANDYGA"
    },
    {
        "value": 324,
        "label": "ASIA_KUCHING"
    },
    {
        "value": 325,
        "label": "ASIA_MACAU"
    },
    {
        "value": 326,
        "label": "ASIA_NOVOKUZNETSK"
    },
    {
        "value": 327,
        "label": "ASIA_NOVOSIBIRSK"
    },
    {
        "value": 328,
        "label": "ASIA_ORAL"
    },
    {
        "value": 329,
        "label": "ASIA_PHNOM_PENH"
    },
    {
        "value": 330,
        "label": "ASIA_PONTIANAK"
    },
    {
        "value": 331,
        "label": "ASIA_PYONGYANG"
    },
    {
        "value": 332,
        "label": "ASIA_QOSTANAY"
    },
    {
        "value": 333,
        "label": "ASIA_QYZYLORDA"
    },
    {
        "value": 334,
        "label": "ASIA_SAKHALIN"
    },
    {
        "value": 335,
        "label": "ASIA_SAMARKAND"
    },
    {
        "value": 336,
        "label": "ASIA_SREDNEKOLYMSK"
    },
    {
        "value": 337,
        "label": "ASIA_TASHKENT"
    },
    {
        "value": 338,
        "label": "ASIA_TBILISI"
    },
    {
        "value": 339,
        "label": "ASIA_TEHRAN"
    },
    {
        "value": 340,
        "label": "ASIA_THIMPHU"
    },
    {
        "value": 341,
        "label": "ASIA_TOMSK"
    },
    {
        "value": 342,
        "label": "ASIA_ULAANBAATAR"
    },
    {
        "value": 343,
        "label": "ASIA_URUMQI"
    },
    {
        "value": 344,
        "label": "ASIA_UST_NERA"
    },
    {
        "value": 345,
        "label": "ASIA_VIENTIANE"
    },
    {
        "value": 346,
        "label": "ASIA_YANGON"
    },
    {
        "value": 347,
        "label": "ASIA_YEREVAN"
    },
    {
        "value": 348,
        "label": "ATLANTIC_BERMUDA"
    },
    {
        "value": 349,
        "label": "ATLANTIC_CAPE_VERDE"
    },
    {
        "value": 350,
        "label": "ATLANTIC_FAROE"
    },
    {
        "value": 351,
        "label": "ATLANTIC_MADEIRA"
    },
    {
        "value": 352,
        "label": "ATLANTIC_SOUTH_GEORGIA"
    },
    {
        "value": 353,
        "label": "ATLANTIC_ST_HELENA"
    },
    {
        "value": 354,
        "label": "ATLANTIC_STANLEY"
    },
    {
        "value": 355,
        "label": "AUSTRALIA_ADELAIDE"
    },
    {
        "value": 356,
        "label": "AUSTRALIA_BRISBANE"
    },
    {
        "value": 357,
        "label": "AUSTRALIA_CURRIE"
    },
    {
        "value": 358,
        "label": "AUSTRALIA_DARWIN"
    },
    {
        "value": 359,
        "label": "AUSTRALIA_EUCLA"
    },
    {
        "value": 360,
        "label": "AUSTRALIA_HOBART"
    },
    {
        "value": 361,
        "label": "AUSTRALIA_LINDEMAN"
    },
    {
        "value": 362,
        "label": "AUSTRALIA_LORD_HOWE"
    },
    {
        "value": 363,
        "label": "CET"
    },
    {
        "value": 364,
        "label": "CST6CDT"
    },
    {
        "value": 365,
        "label": "EET"
    },
    {
        "value": 366,
        "label": "EST"
    },
    {
        "value": 367,
        "label": "EST5EDT"
    },
    {
        "value": 368,
        "label": "ETC_GMT"
    },
    {
        "value": 369,
        "label": "ETC_GMT_PLUS_0"
    },
    {
        "value": 370,
        "label": "ETC_GMT_PLUS_1"
    },
    {
        "value": 371,
        "label": "ETC_GMT_PLUS_10"
    },
    {
        "value": 372,
        "label": "ETC_GMT_PLUS_11"
    },
    {
        "value": 373,
        "label": "ETC_GMT_PLUS_12"
    },
    {
        "value": 374,
        "label": "ETC_GMT_PLUS_2"
    },
    {
        "value": 375,
        "label": "ETC_GMT_PLUS_3"
    },
    {
        "value": 376,
        "label": "ETC_GMT_PLUS_4"
    },
    {
        "value": 377,
        "label": "ETC_GMT_PLUS_5"
    },
    {
        "value": 378,
        "label": "ETC_GMT_PLUS_6"
    },
    {
        "value": 379,
        "label": "ETC_GMT_PLUS_7"
    },
    {
        "value": 380,
        "label": "ETC_GMT_PLUS_8"
    },
    {
        "value": 381,
        "label": "ETC_GMT_PLUS_9"
    },
    {
        "value": 382,
        "label": "ETC_GMT_MINUS_0"
    },
    {
        "value": 383,
        "label": "ETC_GMT_MINUS_1"
    },
    {
        "value": 384,
        "label": "ETC_GMT_MINUS_10"
    },
    {
        "value": 385,
        "label": "ETC_GMT_MINUS_11"
    },
    {
        "value": 386,
        "label": "ETC_GMT_MINUS_12"
    },
    {
        "value": 387,
        "label": "ETC_GMT_MINUS_13"
    },
    {
        "value": 388,
        "label": "ETC_GMT_MINUS_14"
    },
    {
        "value": 389,
        "label": "ETC_GMT_MINUS_2"
    },
    {
        "value": 390,
        "label": "ETC_GMT_MINUS_3"
    },
    {
        "value": 391,
        "label": "ETC_GMT_MINUS_4"
    },
    {
        "value": 392,
        "label": "ETC_GMT_MINUS_5"
    },
    {
        "value": 393,
        "label": "ETC_GMT_MINUS_6"
    },
    {
        "value": 394,
        "label": "ETC_GMT_MINUS_7"
    },
    {
        "value": 395,
        "label": "ETC_GMT_MINUS_8"
    },
    {
        "value": 396,
        "label": "ETC_GMT_MINUS_9"
    },
    {
        "value": 397,
        "label": "ETC_GMT0"
    },
    {
        "value": 398,
        "label": "ETC_GREENWICH"
    },
    {
        "value": 399,
        "label": "ETC_UNIVERSAL"
    },
    {
        "value": 400,
        "label": "ETC_ZULU"
    },
    {
        "value": 401,
        "label": "EUROPE_ANDORRA"
    },
    {
        "value": 402,
        "label": "EUROPE_ASTRAKHAN"
    },
    {
        "value": 403,
        "label": "EUROPE_BUSINGEN"
    },
    {
        "value": 404,
        "label": "EUROPE_CHISINAU"
    },
    {
        "value": 405,
        "label": "EUROPE_GIBRALTAR"
    },
    {
        "value": 406,
        "label": "EUROPE_GUERNSEY"
    },
    {
        "value": 407,
        "label": "EUROPE_ISLE_OF_MAN"
    },
    {
        "value": 408,
        "label": "EUROPE_JERSEY"
    },
    {
        "value": 409,
        "label": "EUROPE_KIROV"
    },
    {
        "value": 410,
        "label": "EUROPE_MARIEHAMN"
    },
    {
        "value": 411,
        "label": "EUROPE_MINSK"
    },
    {
        "value": 412,
        "label": "EUROPE_MONACO"
    },
    {
        "value": 413,
        "label": "EUROPE_NICOSIA"
    },
    {
        "value": 414,
        "label": "EUROPE_PODGORICA"
    },
    {
        "value": 415,
        "label": "EUROPE_SAN_MARINO"
    },
    {
        "value": 416,
        "label": "EUROPE_SARATOV"
    },
    {
        "value": 417,
        "label": "EUROPE_SIMFEROPOL"
    },
    {
        "value": 418,
        "label": "EUROPE_TIRANE"
    },
    {
        "value": 419,
        "label": "EUROPE_ULYANOVSK"
    },
    {
        "value": 420,
        "label": "EUROPE_UZHGOROD"
    },
    {
        "value": 421,
        "label": "EUROPE_VADUZ"
    },
    {
        "value": 422,
        "label": "EUROPE_VATICAN"
    },
    {
        "value": 423,
        "label": "EUROPE_VOLGOGRAD"
    },
    {
        "value": 424,
        "label": "EUROPE_ZAPOROZHYE"
    },
    {
        "value": 425,
        "label": "GMT"
    },
    {
        "value": 426,
        "label": "HST"
    },
    {
        "value": 427,
        "label": "INDIAN_ANTANANARIVO"
    },
    {
        "value": 428,
        "label": "INDIAN_CHAGOS"
    },
    {
        "value": 429,
        "label": "INDIAN_CHRISTMAS"
    },
    {
        "value": 430,
        "label": "INDIAN_COCOS"
    },
    {
        "value": 431,
        "label": "INDIAN_COMORO"
    },
    {
        "value": 432,
        "label": "INDIAN_KERGUELEN"
    },
    {
        "value": 433,
        "label": "INDIAN_MAHE"
    },
    {
        "value": 434,
        "label": "INDIAN_MAYOTTE"
    },
    {
        "value": 435,
        "label": "INDIAN_REUNION"
    },
    {
        "value": 436,
        "label": "MET"
    },
    {
        "value": 437,
        "label": "MST"
    },
    {
        "value": 438,
        "label": "MST7MDT"
    },
    {
        "value": 439,
        "label": "PST8PDT"
    },
    {
        "value": 440,
        "label": "PACIFIC_APIA"
    },
    {
        "value": 441,
        "label": "PACIFIC_BOUGAINVILLE"
    },
    {
        "value": 442,
        "label": "PACIFIC_CHATHAM"
    },
    {
        "value": 443,
        "label": "PACIFIC_CHUUK"
    },
    {
        "value": 444,
        "label": "PACIFIC_EFATE"
    },
    {
        "value": 445,
        "label": "PACIFIC_ENDERBURY"
    },
    {
        "value": 446,
        "label": "PACIFIC_FAKAOFO"
    },
    {
        "value": 447,
        "label": "PACIFIC_FIJI"
    },
    {
        "value": 448,
        "label": "PACIFIC_FUNAFUTI"
    },
    {
        "value": 449,
        "label": "PACIFIC_GAMBIER"
    },
    {
        "value": 450,
        "label": "PACIFIC_GUADALCANAL"
    },
    {
        "value": 451,
        "label": "PACIFIC_GUAM"
    },
    {
        "value": 452,
        "label": "PACIFIC_KIRITIMATI"
    },
    {
        "value": 453,
        "label": "PACIFIC_KOSRAE"
    },
    {
        "value": 454,
        "label": "PACIFIC_KWAJALEIN"
    },
    {
        "value": 455,
        "label": "PACIFIC_MAJURO"
    },
    {
        "value": 456,
        "label": "PACIFIC_MARQUESAS"
    },
    {
        "value": 457,
        "label": "PACIFIC_MIDWAY"
    },
    {
        "value": 458,
        "label": "PACIFIC_NAURU"
    },
    {
        "value": 459,
        "label": "PACIFIC_NIUE"
    },
    {
        "value": 460,
        "label": "PACIFIC_NORFOLK"
    },
    {
        "value": 461,
        "label": "PACIFIC_NOUMEA"
    },
    {
        "value": 462,
        "label": "PACIFIC_PAGO_PAGO"
    },
    {
        "value": 463,
        "label": "PACIFIC_PALAU"
    },
    {
        "value": 464,
        "label": "PACIFIC_PITCAIRN"
    },
    {
        "value": 465,
        "label": "PACIFIC_POHNPEI"
    },
    {
        "value": 466,
        "label": "PACIFIC_PORT_MORESBY"
    },
    {
        "value": 467,
        "label": "PACIFIC_RAROTONGA"
    },
    {
        "value": 468,
        "label": "PACIFIC_SAIPAN"
    },
    {
        "value": 469,
        "label": "PACIFIC_TAHITI"
    },
    {
        "value": 470,
        "label": "PACIFIC_TARAWA"
    },
    {
        "value": 471,
        "label": "PACIFIC_TONGATAPU"
    },
    {
        "value": 472,
        "label": "PACIFIC_WAKE"
    },
    {
        "value": 473,
        "label": "PACIFIC_WALLIS"
    },
    {
        "value": 474,
        "label": "UTC"
    },
    {
        "value": 475,
        "label": "WET"
    },
    {
        "value": 476,
        "label": "ASIA_CALCUTTA"
    },
    {
        "value": 477,
        "label": "ASIA_KATMANDU"
    },
    {
        "value": 478,
        "label": "AMERICA_NUUK"
    },
    {
        "value": 479,
        "label": "AMERICA_BUENOS_AIRES"
    }
]