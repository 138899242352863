import { Box, Button, Divider, Image, MantineColor, Text, ThemeIcon } from '@mantine/core'
import React, { useState } from 'react'
import { Carousel } from '@mantine/carousel';
import { IconArrowRight, IconExternalLink, IconKey, IconPaint, IconQuestionMark } from '@tabler/icons-react';
import Link from 'next/link';
import { useScreen } from '@/hooks/useScreen';


const components = [
    {
        title: "Automatic Access",
        description: <><span >Get access to all of your clients' accounts with one click.</span> Say goodbye to endless onboarding calls and wasted time.</>,
        image: "access.png",
        icon: <IconKey />,
        color: "blue",
        href: "/feature/access"
    },
    {
        title: "Intake Forms",
        description: "Gather important details from your clients in a beautiful and seamless way",
        image: "intake.png",
        icon: <IconQuestionMark />,
        color: "yellow",
        href: "/feature/intake-form"
    },
    {
        title: "Branding",
        description: "Access invitations, intake forms, emails, and thank you pages are fully customizable. Make AgencyAccess your own with custom wording, colours, and logos.",
        image: "branding.png",
        color: "red",
        icon: <IconPaint />,
        href: "/feature/branding"
    },
    {
        title: "Embedding",
        description: "Prefer to host onboarding on your own site? Embed AgencyAccess anywhere, with the click of a button.",
        image: "embed.png",
        color: "gray",
        icon: <IconExternalLink />,
        href: "/feature/embeds"
    }
] as {
    icon: React.ReactElement
    title: string,
    description: React.ReactNode,
    image: string,
    color: MantineColor,
    href: string
}[]

export default function ComponentBreakdown() {
    const { mediumTablet } = useScreen({})
    const [component, setComponent] = useState(0)

    return (
        <Box>
            <Carousel maw={750} w="100%" withControls={true} withIndicators={false} slideGap={0}>
                {components.map((component) => (
                    <Carousel.Slide px={20} key={component.title}>
                        <Box className={`flex aic ${!mediumTablet ? "" : "fdc"}`} style={{ gap: !mediumTablet ? 20 : 0, }} >
                            <Image mx="auto" style={{ width: !mediumTablet ? "50%" : "80%", objectFit: "contain" }} src={`/images/landing_page/components/${component.image}`} />
                            <Box className='flex aic fdc'>
                                <Box mt={14} className='flex aic' style={{ gap: 8 }}>
                                    <ThemeIcon color={component.color} size={"md"} radius={8} variant='light'>
                                        {React.cloneElement(component.icon, { size: 20 })}
                                    </ThemeIcon>
                                    <Text lh={1} fz="xl" fw={600}>{component.title}</Text>
                                </Box>
                                <Text ta="center" mt={10} c="dimmed" fz="sm">{component.description}</Text>
                                <Button component={Link} href={`${component.href}`} mt={10} size="compact-sm" w="fit-content" variant='subtle' rightSection={<IconArrowRight size={18} />}>
                                    Learn More
                                </Button>
                            </Box>
                        </Box>
                    </Carousel.Slide>
                ))}
            </Carousel>

        </Box>
    )
}
