import Error from '@/components/Error'
import StaticSessionForm from '@/components/invite/StaticSessionForm'
import { Logo } from '@/components/Logo'
import api from '@/utils/api'
import { InviteContext } from '@/utils/context/Invite.context'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { SubdomainContext } from '@/utils/context/Subdomain.context'
import { firestore } from '@/utils/firebase'
import { Box, Loader } from '@mantine/core'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Invite, PublicClient } from '../../../types/global'

export default function InviteProvider({ children, id: parsedID }: PropsWithChildren & { id: string }) {
    const [id, setID] = useState(parsedID)
    const router = useRouter()
    const [invite, setInvite] = useState<Invite | undefined>(undefined)
    const [client, setClient] = useState<PublicClient | undefined>(undefined)
    const [error, setError] = useState("")
    const [subdomain, setSubdomain] = useState<string | undefined>(undefined)
    const [isPreview, setIsPreview] = useState<boolean | undefined>(undefined)
    const [staticSessionFormShown, setStaticSessionFormShown] = useState(false)

    useEffect(() => {
        if (router.query.preview) {
            setIsPreview(true)
        } else {
            setIsPreview(false)
        }
    }, [router])

    useEffect(() => {
        setID(parsedID)
    }, [parsedID])

    useEffect(() => {
        if (!invite) return;
        // @ts-ignore
        (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/jbtc4bxp'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
        // @ts-ignore
        window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "jbtc4bxp",
        });
    }, [invite])

    const getInvite = async () => {
        let id = parsedID
        console.log("Parsed ID is:", id)
        const url = window.location
        let subdomain: undefined | string = url.origin.split(".")[url.origin.split(".").length - 3]?.replace(url.protocol + "//", "")

        if (!subdomain || subdomain === "www") {
            subdomain = undefined
        }

        let targetAgencyIDFromSubdomain: string | null = null

        if (subdomain && process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
            try {
                await getDoc(doc(firestore, "subdomains", subdomain)).then((snap) => {
                    if (!snap.exists()) {
                        return setError("That subdomain does not represent any agency on our system")
                    }
                    targetAgencyIDFromSubdomain = snap.data().owner
                })
            } catch {
                return
            }
        }

        const isStatic = !!router.query.s // if the link is static, we'll pull the session ID from sessionStorage. If there is no session, we'll create one

        if (isStatic) {
            let recoveredSessionID;
            try {

                if (router.query.nocache) {
                    if (router.query.inviteID) {
                        recoveredSessionID = router.query.inviteID
                    } else {
                        return setStaticSessionFormShown(true)
                    }
                } else {
                    // @ts-ignore
                    const IDs = JSON.parse(window.localStorage.getItem("static_link_sessions"))
                    if (IDs[parsedID] && !router.query.nocache) { // parsedID will be the slug
                        recoveredSessionID = IDs[parsedID]
                    }
                }
            } finally {
                if (!recoveredSessionID) { // We'll show the session creation form
                    return setStaticSessionFormShown(true)
                }
                id = recoveredSessionID
                setID(recoveredSessionID)
            }
        }
        onSnapshot(doc(firestore, "invites", id), (snap) => {
            console.log("SNAP:", snap.data())
            if (!snap.exists()) {
                return setError("We couldn't find that invite on our system")
            }
            const inviteData = snap.data() as Invite
            if (subdomain && targetAgencyIDFromSubdomain !== inviteData.agency.id && process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
                return setError("The agency who owns this invite does not own this subdomain. Please contact them to resolve this issue.")
            }
            setInvite({ ...snap.data(), id: snap.id } as Invite)
        })
        setSubdomain(subdomain)
    }
    useEffect(() => {
        console.log("ID IS", id)
        if (!id || isPreview) return;
        getInvite()
    }, [id, isPreview])

    useEffect(() => {
        const isStatic = !!router.query.s
        if (isStatic && parsedID === id) return;
        if (!id || isPreview) return;
        if (!invite) return;
        if (invite.clientID === client?.id) return;

        api.get<PublicClient>("/invite/public/details/client", { inviteID: id }).then((res) => {
            if (res.error) return setError("Failed to fetch client data");
            setClient(res.data)
        })
    }, [invite, id])

    if (error) {
        return (
            <div className='flex aic fdc jcc' style={{ width: "100vw", height: "100vh", padding: "20px 0" }}>
                <Logo width={300} />
                <div style={{ maxWidth: 500 }}>
                    <Error>{error}</Error>
                </div>
            </div>
        )
    }
    if (isPreview) {
        return <>{children}</>
    }

    if (staticSessionFormShown) {
        return <StaticSessionForm slug={parsedID} />
    }

    if (!invite || !client || isPreview === undefined) {
        return <Box className='flex aic jcc' style={{ width: "100vw", height: "100vh" }}>
            <Loader size={"sm"} />
        </Box>
    }

    return (
        <InviteContext.Provider value={invite}>
            <InviteClientContext.Provider value={client}>
                <SubdomainContext.Provider value={subdomain}>
                    {children}
                </SubdomainContext.Provider>
            </InviteClientContext.Provider>
        </InviteContext.Provider>
    )
}
