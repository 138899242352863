import { Box, Button, Divider, Text, Image as MImage } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Link from 'next/link'
import React from 'react'
import { ArrowRight, Book, BrandFacebook, BrandLinkedin, BrandTwitter, BrandYoutube } from 'tabler-icons-react'
import { AnyService, GoogleServices, MetaServices } from '../../../types/global'
import { Logo } from './Logo'
import Image from 'next/image'
import { useScreen } from '@/hooks/useScreen'

const LinkCol = ({ title, links }: { title: string, links: Array<{ label: string, value: string, icon?: React.ReactElement }> }) => {
    return (
        <Box mb={20} miw={130} p="0" style={{ borderRadius: 15, flex: 1 }}>
            <Text fw={700} fz="md" c="#fff">{title}</Text>
            <Box className='flex fdc' style={{ gap: 5 }} mt={8}>
                {links.map(l => (
                    <Link href={l.value} className="flex aic" style={{ gap: 5 }}>
                        {l.icon
                            ? <>{React.cloneElement(l.icon, { size: 18, color: "var(--mantine-color-dimmed)" })}</>
                            : null
                        }
                        <Text lineClamp={1} fz="sm" fw={400} c="dimmed">{l.label}</Text>
                    </Link>
                ))}
            </Box>
        </Box>
    )
}

export default function Footer() {
    const mobile = useMediaQuery('(max-width: 500px)')
    const { tablet } = useScreen()
    return (
        <>
            <Box mt={40} pt={40} pb={30} bg={"linear-gradient(0deg, var(--mantine-color-blue-6) 0%, #0B1323 100%)"} p="lg" className='flex aic jcc fdc' style={{ position: "relative", overflowX: "hidden" }}>
                <Box maw={800} className='flex' style={{}}>
                    <Box style={{ flex: 1, zIndex: 400 }}>
                        <Text lh={1.3} fz={tablet ? 28 : 32} c="blue.9" fw={800}><span style={{
                            lineHeight: 1,
                            WebkitTextFillColor: "transparent",
                            // padding: 5,
                            borderRadius: 10,
                            textShadow: "0px 2px 20px rgba(255,255,255,.25)",
                            background: `linear-gradient(-75deg, var(--mantine-color-blue-3) 0%, #fff 100%)`,
                            backgroundClip: "text",
                            fontWeight: 10000
                        }}>
                            Add AgencyAccess to your onboarding flow and save hours every month.
                        </span></Text>
                        <MImage mt={12} alt='Rated 5 out of 5 (source Senja)' src={"/images/landing_page/senja/inline-dark.png"} style={{ height: 40, objectFit: "contain", width: "fit-content" }} />
                        {/* <iframe src="https://widget.senja.io/widget/6d32a22f-9782-4122-9ca4-4901fbd3e452" frameBorder={0}></iframe> */}
                        <Button component={Link} href={"/pricing"} mt={20} size='md' rightSection={<ArrowRight size={20} />}>Get Started Free</Button>
                        <div className={`flex aic`} style={{ flexWrap: 'wrap', gap: 25 }}>
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            <Image alt="Dagami logo" src={"/images/landing_page/companies/ad-lab.png"} width={60} height={60} style={{ objectFit: "contain", filter: "grayscale(100%) invert(0)" }} />
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            <Image alt="Dagami logo" src={"/images/landing_page/companies/ppc-mastery.webp"} width={80} height={40} style={{ objectFit: "contain", filter: "grayscale(100%) invert(0)", }} />
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            <Image alt="Taskforce Agency logo" src={"/images/landing_page/companies/endeavour.webp"} width={145} height={38} style={{ objectFit: "contain", filter: "grayscale(100%) invert(1)", }} />
                        </div>
                    </Box>
                    {/* <MImage src={`/images/landing_page/footer.png`} style={{ position: "absolute", right: -200, zIndex: 300, height: 400, top: -50, width: "fit-content", objectFit: "contain" }} /> */}
                    {/* <Box style={{ flex: .5 }}>

                    </Box> */}
                </Box>

            </Box>
            <Box component={"footer"} className='flex fdc' style={{ background: "#0B1323", width: "100%", padding: mobile ? 20 : 40 }}>
                <Box className='flex jcsb' style={{ flexWrap: "wrap", gap: 10 }}>
                    <LinkCol
                        title='Integrations'
                        links={(["Meta Ads", "Facebook Pages", "Facebook Product Catalog", "Google Ads", "Google Analytics", "Google Business Profile", "Google Merchant Center", "Google Search Console", "Google Tag Manager"] as Array<AnyService>).map((service) => ({
                            label: service,
                            value: `/service/${service}`
                        }))}
                    />
                    <LinkCol
                        title='Contact Us'
                        links={[
                            { label: "Chat with us", value: "/contact" },
                            { label: "Create a ticket", value: "/contact" },
                        ]}
                    />
                    <LinkCol
                        title='Legal'
                        links={[
                            { label: "Privacy Policy", value: "/legal/privacy" },
                            { label: "Terms of Service", value: "/legal/terms" },
                        ]}
                    />
                    <LinkCol
                        title='Docs'
                        links={[
                            { label: "Zapier", value: "/docs/zapier" }
                        ]}
                    />
                    <LinkCol
                        title='Socials'
                        links={[
                            { label: "YouTube", value: "https://www.youtube.com/channel/UC5OTe2___a1OgQsgJKjIqIQ", icon: <BrandYoutube /> },
                            { label: "LinkedIn", value: "https://www.linkedin.com/company/agencyaccess/", icon: <BrandLinkedin /> },
                            { label: "Our Blog", value: "/blog", icon: <Book /> }
                        ]}
                    />
                    {/* <LinkCol
                        title='Competitors'
                        links={[
                            { label: "Leadsie", value: "/competitors/leadsie" },
                            { label: "DigitalSero", value: "/competitors/digitalsero" },
                            { label: "LinkMyAgency", value: "/competitors/linkmyagency" },
                        ]}
                    /> */}
                </Box>
                <Divider my={35} pt={15} opacity={.5} />
                <Box className='flex aic jcsb' style={{ flexWrap: "wrap" }}>
                    <Logo dark width={200} />
                    <Text fz={"xs"} mt={20} ta="center" c="dimmed">© Copyright of Volpax {new Date().getFullYear()}
                        <br />
                    </Text>
                </Box>
            </Box>
        </>
    )
}
