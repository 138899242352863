import { AssetCreationGuide, assetCreationGuides, CustomAssetCreationGuide } from '@/static/assetCreationGuides'
import api from '@/utils/api'
import { InviteContext } from '@/utils/context/Invite.context'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { Box, Button, Text } from '@mantine/core'
import React, { useContext, useState } from 'react'
import general from '@/../public/translations/general.json'
import { AnyService } from '../../../../types/global'
import MetaAdsCreationGuide from './assetCreationGuides/MetaAds'
export default function AssetCreationGuideRenderer({
    guide: rawGuide,
    close,
    refreshAssets,
    service
}: {
    guide: AssetCreationGuide | CustomAssetCreationGuide,
    close: VoidFunction,
    refreshAssets: () => Promise<void>,
    service: AnyService
}) {
    const invite = useContext(InviteContext)
    const { lang } = useContext(InviteClientContext)
    const [loading, setLoading] = useState(false)

    if (invite.agency.email === "ethan@agencyaccess.co" && service === "Meta Ads") {
        return <MetaAdsCreationGuide close={close} refreshAssets={refreshAssets} inviteID={invite.id} />
    }

    if (typeof rawGuide === "function") {
        return <>{rawGuide({ inviteID: invite.id, close, refreshAssets })}</>
    }

    const guide = rawGuide as AssetCreationGuide

    return (
        <Box mt={8} className='flex fdc' style={{ gap: 10 }}>
            {guide.steps(invite).map((step, i) => {
                return (
                    <Box className='flex' style={{ gap: 12 }}>
                        <Box miw={30} w={30} h={30} className='flex aic jcc' style={{ borderRadius: 8 }} bg={"gray.1"}>
                            <Text fw={600} fz="sm">{i + 1}</Text>
                        </Box>
                        <Box>
                            {step.content[lang]}
                        </Box>
                    </Box>
                )
            })}
            <Box className='flex' mt={5} style={{ gap: 5 }}>
                <Button loading={loading} onClick={async () => {
                    setLoading(true)
                    await refreshAssets()
                    setLoading(false)
                    close()
                }}>{general.done[lang]}</Button>
                <Button variant='subtle' onClick={close} disabled={loading}>
                    {general.close[lang]}
                </Button>

            </Box>
        </Box>
    )
}
