import { Box, Button } from '@mantine/core'
import React, { PropsWithChildren, useState } from 'react'

export default function BaseAssetCreationGuide({
    children,
    loading,
    onSubmit,
    close,
    disabled
}: PropsWithChildren & {
    loading: boolean,
    onSubmit: VoidFunction,
    close: VoidFunction,
    disabled: boolean
}) {
    return (
        <Box>
            <Box className='flex fdc' style={{ gap: 5 }}>
                {children}
            </Box>
            <Box mt={10} className='flex aic' style={{ gap: 5 }}>
                <Button loading={loading} disabled={disabled} onClick={onSubmit}>Create Account</Button>
                <Button disabled={loading} variant='subtle' onClick={close}>Close</Button>
            </Box>
        </Box>
    )
}
