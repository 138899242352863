import Footer from '@/components/Footer'
import { DefaultHeader } from '@/components/Headers'
import { Logo } from '@/components/Logo'
import PlatformsProvider from '@/providers/Platforms.provider'
import { CurrencyContext, CurrencyControllerContext } from '@/utils/context/Currency.context'
import { auth } from '@/utils/firebase'
import { ActionIcon, Box, Button, Collapse, Divider, Paper, Select, Text } from '@mantine/core'
import { onAuthStateChanged } from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { ArrowRight, InfoCircle, Lock, X } from 'tabler-icons-react'
import { Currency } from '../../../types/global'


export default function DefaultLayout({ children }: { children: React.ReactNode }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const router = useRouter()
    useEffect(() => {
        const unsub = onAuthStateChanged(auth, (usr) => {
            if (usr) {
                setIsLoggedIn(true)
            } else {
                setIsLoggedIn(false)
            }
        })
        return () => unsub()
    }, [])

    useEffect(() => {
        // @ts-ignore
        (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/jbtc4bxp'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
        // @ts-ignore
        window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "jbtc4bxp",
        });
    }, [])

    return (
        <PlatformsProvider>
            <div>
                <DefaultHeader isLoggedIn={isLoggedIn} />
                <div style={{ marginTop: router.pathname === "/" || router.pathname.startsWith("/feature") ? 0 : 80 }}></div>
                <main style={{ position: "relative" }}>
                    {children}
                </main>
                {!router.pathname.startsWith("/auth")
                    ? <Footer />
                    : null
                }

            </div>
        </PlatformsProvider>
    )
}
