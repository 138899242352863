import { AnyService, RequestedServices } from "../../../../types/global"

export const includesManualGrants = (services: RequestedServices, manualGrants: AnyService[]) => {
    if (!services) return false;
    return !!Object.keys(services).filter((service) => manualGrants.includes(service as any)).length
}

export const includesAutomaticGrants = (services: RequestedServices, manualGrants: AnyService[]) => {
    if (!services) return false;
    return Object.keys(services).filter((service) => manualGrants.includes(service as any)).length < Object.keys(services).length
}