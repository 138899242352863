import { ThemeContext } from "@/utils/context/Theme.context";
import { Text } from "@mantine/core";
import React, { PropsWithChildren, useContext } from "react";
import { Checkbox, Click, SquareCheck, ToggleRight } from "tabler-icons-react";

export const ManualGuideButton = ({ children, icon }: PropsWithChildren & { icon?: React.ReactElement }) => {
    const theme = useContext(ThemeContext)
    return <Text span fz="sm" fw={600} px={5} py={2} style={{ borderRadius: 0, borderBottom: `2px solid var(--mantine-color-${theme.color || "blue"}-6)` }}><Click size={16} /> {children}</Text>
}

export const ManualGuideCheckbox = ({ children }: PropsWithChildren) => {
    const theme = useContext(ThemeContext)
    return (
        <Text span fz="sm" fw={600} px={5} py={2} style={{ borderBottom: `2px solid var(--mantine-color-${theme.color || "blue"}-6)`, }}><SquareCheck style={{ position: 'relative', top: 2 }} size={16} /> {children}</Text>
    )
}