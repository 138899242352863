import { CircleNumber1, CircleNumber2, CircleNumber3, Clock, Lock, Tag, Tie } from "tabler-icons-react"

export const mainSellingPoints = [
    { title: "Single-Link Account Access", body: "Save hours by requesting access to different accounts in just one link." },
    { title: "Seamless Client Onboarding", body: "Eliminate the need for password sharing and complicated explanations. Simplify client onboarding with a streamlined and efficient process." },
    { title: "Branded Onboarding Expierence", body: "Create a fully customized onboarding experience, showcasing your agency's logo and branding to impress clients and enhance professionalism." }
]

export const bonusSellingPoints = [
    {
        title: "Access is permanent",
        body: <>Even if you stop using AgencyAccess, <span style={{ color: "#000", fontWeight: "600" }}>you’ll still maintain access to all your clients' accounts</span>. No need to worry about disruptions—your connections stay intact.</>,
        image: "/images/misc/key.png"
    },
    {
        title: "Approved by Major Platforms",
        body: <>AgencyAccess is approved by <span style={{ color: "#000", fontWeight: "600" }}>Meta, TikTok, Google, and LinkedIn</span>, ensuring compliance and secure connections with these leading platforms.</>,
        image: "/images/misc/sheild.png"
    },
    {
        title: "Quick and Easy Setup",
        body: <>Get started in minutes with no complex setup. Simply send one link to your clients, and you’re ready to go—no technical expertise needed. <span style={{ color: "#000", fontWeight: "600" }}>Start with our free plan or explore paid plans with a 30-day free trial, no credit card required</span>.</>,
        image: "/images/misc/safe.png"
    },
]

export const pullFactors = [
    {
        title: "Create your own branded onboarding link",
        body: `With AgencyAccess, you can take control of your onboarding process. Create your personalized onboarding link, tailor it to your agency's needs, and experience a hassle-free way to access client accounts.`,
        icon: <CircleNumber1 />
    },
    {
        title: "Preview before sending to clients",
        body: `Don't leave anything to chance. Preview the onboarding experience your clients will have before sending the link. Ensure it aligns with your agency's branding and impresses clients from the start`,
        icon: <CircleNumber2 />
    },
    {
        title: "14 days to get your money back",
        body: `We're confident you'll love the efficiency and security AgencyAccess provides. Try it risk-free for 14 days, and if you're not completely satisfied, we'll refund your money, no questions asked`,
        icon: <CircleNumber3 />
    }
]

export const securityConcerns = [
    {
        title: "Unprofessional & Inefficient",
        body: "Email requests and manual setup processes waste time and don't leave a good impression on clients. AgencyAccess solves this by providing a secure and efficient solution, eliminating the need for such unprofessional practices"
    },
    {
        title: "Security Concerns",
        body: "Agencies using these methods expose themselves to inefficiencies and security risks. AgencyAccess uses cutting-edge OAuth technology to provide a secure and efficient solution. We never save sensitive data like user passwords, prioritizing your clients' data security"
    }
]