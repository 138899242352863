import lang from '@/../public/translations/intake.json'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { Box, Checkbox, MantineSize, Radio, Text, TextInput, Textarea } from '@mantine/core'
import { useContext } from 'react'
import { ClientLanguage, IntakeCardDetailsAnswer, IntakeFieldAnswer, IntakeFormAnswer, IntakeMultiSelectAnswer, IntakeQuestion } from '../../../../types/global'
import { IconCalendar, IconCreditCard, IconCreditCardRefund } from '@tabler/icons-react'
import cardValidator from 'card-validator';

export const IntakeFormQA = ({ question, i, answer, setAnswer, langOverwrite, size, disableNumbers, disableSpacing }: { question: IntakeQuestion, i: number, answer: IntakeFieldAnswer, setAnswer: (a: IntakeFieldAnswer) => void, langOverwrite?: ClientLanguage, size?: MantineSize, disableNumbers?: boolean, disableSpacing?: boolean }) => {
    const invite = useContext(InviteClientContext)
    const l = invite?.lang || langOverwrite || "English"

    console.log(question.type, answer)
    return (
        <Box>
            <Box className='flex' style={{ gap: 5 }}>
                {!disableNumbers
                    ? <Text span fw={500} c="dimmed">{i + 1}.</Text>
                    : null
                }

                <Box>
                    <Text fw={500}>{question.title} <Text span c={"red"} fw={600}>{question.required ? "*" : ""}</Text></Text>
                    {question.description
                        ? <Text fz="xs" c="dimmed">{question.description}</Text>
                        : null
                    }

                </Box>
            </Box>

            {question.type === "Input"
                ? <TextInput size={size} mt={disableSpacing ? 0 : 5} placeholder={question.placeholder || lang.placeholders.input[l]} onChange={(e) => setAnswer(e.target.value)} />
                : null
            }

            {question.type === "Textbox"
                ? <Textarea size={size} radius={10} minRows={2} autosize mt={disableSpacing ? 0 : 5} placeholder={question.placeholder || lang.placeholders.textbox[l]} onChange={(e) => setAnswer(e.target.value)} />
                : null
            }

            {question.type === "Multi-select"
                ? <Box ml={disableSpacing ? 0 : 10} className='flex fdc' style={{ gap: 10 }} mt={5}>
                    {question.options.map((o) => <Checkbox
                        label={o}
                        checked={(answer as IntakeMultiSelectAnswer)?.includes(o)}
                        onChange={(e) => {
                            if (e.target.checked) {

                                setAnswer(answer ? [...answer as IntakeMultiSelectAnswer, o] : [o])
                            } else {
                                const newAnswer = [...answer as IntakeMultiSelectAnswer]
                                newAnswer.splice((answer as IntakeMultiSelectAnswer).indexOf(o), 1)
                                setAnswer(newAnswer)
                            }
                        }}
                    />)}
                </Box>
                : null
            }

            {question.type === "Option"
                ? <Box ml={disableSpacing ? 0 : 10} className='flex fdc' style={{ gap: 10 }} mt={5}>
                    {question.options.map((o) => <Radio
                        label={o}
                        checked={answer === o}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setAnswer(o)
                            } else {
                                setAnswer("")
                            }
                        }}
                    />)}
                </Box>
                : null
            }

            {question.type === "Card Details"
                ? <Box mt={5} className='flex fdc' style={{ gap: 8 }}>
                    <TextInput
                        maxLength={19}
                        error={(!!(answer as IntakeCardDetailsAnswer)?.number?.length) && !cardValidator.number((answer as IntakeCardDetailsAnswer)?.number).isPotentiallyValid ? "Invalid card number" : undefined}
                        value={(answer as IntakeCardDetailsAnswer)?.number || ""}
                        // @ts-ignore
                        onChange={(e) => setAnswer({ ...(answer ? answer : {}), number: e.target.value })}
                        placeholder='Card Number'
                        leftSection={<IconCreditCard size={18} />}
                        w="100%"
                        size={size}
                    />
                    <Box className='flex aic' style={{ gap: 8 }}>
                        <TextInput
                            style={{ flex: 1 }}
                            leftSection={<IconCalendar size={18} />}
                            placeholder='Expiry Date'
                            value={(answer as IntakeCardDetailsAnswer)?.expires}
                            onChange={(e) => {
                                setAnswer({
                                    // @ts-ignore
                                    ...(answer ? answer : {}),
                                    expires: `${e.target.value}${e.target.value.replaceAll("/", "").length === 2 && !e.target.value.includes("/") && !(answer as IntakeCardDetailsAnswer).expires.includes("/") ? "/" : ""}`
                                })
                            }}
                            maxLength={5}
                            size={size}
                        />
                        <TextInput
                            value={(answer as IntakeCardDetailsAnswer)?.cvv || ""}
                            style={{ flex: 1 }}
                            leftSection={<IconCreditCardRefund size={18} />}
                            placeholder='CVV'
                            maxLength={4}
                            // @ts-ignore
                            onChange={(e) => setAnswer({ ...(answer ? answer : {}), cvv: e.target.value })}
                            size={size}
                        />
                    </Box>
                </Box>
                : null
            }

        </Box>
    )
}